import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import { CustomDataSource } from "../CustomDataSource";

export class MainSearching {
  view: any;
  dataSources: CustomDataSource[];
  type: string;
  executed: boolean;
  records: number;

  public constructor(view: any, type: string = "") {
    this.view = view;
    this.dataSources = [];
    this.type = type;
    this.executed = false;
    this.records = 0;
  }

  public setType(value: string) {
    this.type = value;
  }

  public setExecuted(value: boolean) {
    this.executed = value;
  }

  public setRecords(value: number) {
    this.records = value;
  }
}
