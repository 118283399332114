import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
  CalciteTabs,
  CalciteTab,
  CalciteTabTitle,
  CalciteButton,
  CalciteTabNav,
  CalciteTable,
  CalciteTableCell,
  CalciteTableRow,
  CalciteTableHeader,
  CalciteInput,
  CalciteTooltip,
} from "@esri/calcite-components-react";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import { CustomPopupHTML } from "../../components/CustomPopupHTML";
import Draw from "@arcgis/core/views/draw/Draw";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Graphic from "@arcgis/core/Graphic";
import configPreEvaluacion from "./ConfigPreEvaluacion.json";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import * as coordinateFormatter from "@arcgis/core/geometry/coordinateFormatter.js";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";
import { CustomFunctions } from "../../utils/CustomFunctions";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as query from "@arcgis/core/rest/query";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import * as projection from "@arcgis/core/geometry/projection";

import Color from "@arcgis/core/Color";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import Font from "@arcgis/core/symbols/Font.js";
import TextSymbol from "@arcgis/core/symbols/TextSymbol.js";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

// import PrintTask from "esri/tasks/PrintTask",
import * as print from "@arcgis/core/rest/print";
import PrintParameters from "@arcgis/core/rest/support/PrintParameters";
import PrintTemplate from "@arcgis/core/rest/support/PrintTemplate";
import { CustomDataSource } from "../CustomDataSource";
import { MainSearching } from "./MainSearching";
import { useAlert } from "../../core/providers/AlertProvider";
import { TableCoord } from "./TransformarCoordenadas";
import { ExcelExport } from "../../utils/ExcelExport";
import * as XLSX from "xlsx";
import MapView from "@arcgis/core/views/MapView";

export function PreEvaluacion(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showAlert = useAlert();

  let searching: MainSearching = props.data;
  let view = searching.view;

  const utils = new CustomFunctions();

  const [onPreevaluacion, setOnPreevaluacion] = useState(false);
  const [onDraw, setOnDraw] = useState(false);

  const [contenidoPreEvaluacionHTML, setContenidoPreEvaluacionHTML] =
    useState("");
  const [selectedOptionCuadrangulo, setselectedOptionCuadrangulo] =
    useState("");
  const [selectedDisableCuadrangulo, setselectedDisableCuadrangulo] =
    useState(true);

  const [txtEsteTransformarCoordenadas, setTxtEsteTransformarCoordenadas] =
    useState("0");
  const [txtNorteTransformarCoordenadas, setTxtNorteTransformarCoordenadas] =
    useState("0");
  const [tableCoords, setTableCoords] = useState<TableCoord[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedDatum, setSelectedDatum] = useState("32718");
  //const [selectedZona, setSelectedZona] = useState("17");
  //setSelectedDatum(e.target.value)

  const [uploadFile, setUploadFile] = useState(true);
  const [txtNotify, setTxtNotify] = useState("");
  const [file, setFile] = useState<File | null>(null);
  let arrCoords: TableCoord[] = [];

  const [arrDepartamentos, setArrDepartamentos] = useState([]);
  const [arrProvincias, setArrProvincias] = useState([]);
  const [arrDistritos, setArrDistritos] = useState([]);
  const [arrHojaPreEvaluacion, setArrHojaPreEvaluacion] = useState([]);

  const [arrDataVerticesData, setArrDataVerticesData] = useState([]);
  const [arrDataCatastroData, setArrDataCatastroData] = useState([]);
  const [arrDataDemarcacionData, setArrDataDemarcacionData] = useState([]);
  const [txtZonaData, setTxtZonaData] = useState("");

  const cuadrangulo = [{ id: "", name: "" }];
  const arrVacio = [{ id: "", name: "--Seleccione uno--" }];
  const alertTitle = "Pre-Evaluación";
  const exportTool = new ExcelExport();

  const departamentoUrlPreEvaluacion =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/0";
  const provinciaUrlPreEvaluacion =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/1";
  const distritoUrlPreEvaluacion =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/2";

  const cuadrangulosUrlPreEvaluacion =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/WGS84/SERV_ZONAS_GEOWGS84/MapServer/0";

  const querySymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0],
    style: "solid",
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 0, 0, 11],
      width: 1,
    },
  };

  const symbolLine = {
    color: [255, 0, 0, 11, 0.75],
    width: 1,
  };
  const symbolPoly = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: symbolLine,
  };

  const simplePoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [255, 0, 0],
      width: 1,
    },
  };

  const simplePointVertices = new SimpleMarkerSymbol({
    angle: 0,
    color: new Color([255, 0, 0, 1]),
    outline: new SimpleLineSymbol({
      cap: "round",
      color: new Color([255, 0, 0, 1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1,
    }),
    path: "undefined",
    size: 8,
    style: "circle",
    xoffset: 0,
    yoffset: 0,
  });

  // const urlServicePrint =
  //   "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task";
  // //"https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task";
  // //

  // const template = new PrintTemplate({
  //   // format: "pdf",
  //   layout: "letter-ansi-a-portrait",
  //   exportOptions: {
  //     dpi: 150,
  //   },

  //   // layoutOptions: {
  //   //   titleText: "Gillette Stadium",
  //   //   authorText: "Thomas B.",
  //   // },
  // });

  // const params = new PrintParameters({
  //   view: view as MapView,
  //   template: template,
  // });

  coordinateFormatter.load();
  projection.load();

  useEffect(() => {
    async function loadDapartamentos() {
      const queryUrl =
        "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/0";
      const queryWhere = "CD_DEPA <>'99'";

      executeQueryJSON
        .call(null, queryUrl, {
          where: queryWhere,
          outFields: ["CD_DEPA", "NM_DEPA"],
        })
        .then(
          function (results) {
            const data = results.features;
            const arrData: never[] = [];
            arrData.push(arrVacio[0] as never);
            data.forEach((e) => {
              arrData.push({
                id: e.attributes.CD_DEPA,
                name: e.attributes.NM_DEPA,
              } as never);
            });

            setArrDepartamentos(arrData as never);
            setArrProvincias(arrVacio as never);
            setArrDistritos(arrVacio as never);
          },
          function (error) {
            console.log(error);
          }
        );
    }

    const cmbDpto = document.getElementById("cmbDpto") as HTMLSelectElement;
    const cmbProv = document.getElementById("cmbProv") as HTMLSelectElement;
    const cmbDist = document.getElementById("cmbDist") as HTMLSelectElement;

    const cmbHojaPreEvaluacion = document.getElementById(
      "cmbHojaPreEvaluacion"
    ) as HTMLSelectElement;

    cmbDpto.addEventListener("calciteSelectChange", () => {
      loadProvincias();
      loadUbigeoPreEvaluacion(cmbDpto.value, "");
    });
    cmbProv.addEventListener("calciteSelectChange", () => {
      loadDistritos();
      loadUbigeoPreEvaluacion(cmbProv.value, "");
    });

    cmbDist.addEventListener("calciteSelectChange", () => {
      loadUbigeoPreEvaluacion(cmbDist.value, "");
      //loadCuadrangulos(cmbDistCatastroPorRegion.value);
    });

    cmbHojaPreEvaluacion.addEventListener("calciteSelectChange", () => {
      //console.log(cmbHojaCatastroPorRegion.value);
      loadUbigeoPreEvaluacion(cmbHojaPreEvaluacion.value, "HOJA");
    });

    async function loadProvincias() {
      const codDpto = cmbDpto.value;
      if (codDpto != "") {
        const queryUrl =
          "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/1";
        const queryWhere = "CD_DEPA = '" + codDpto + "'";

        executeQueryJSON
          .call(null, queryUrl, {
            where: queryWhere,
            outFields: ["CD_PROV", "NM_PROV"],
          })
          .then(
            function (results) {
              const data = results.features;
              const arrData: never[] = [];
              arrData.push(arrVacio[0] as never);
              data.forEach((e) => {
                arrData.push({
                  id: e.attributes.CD_PROV,
                  name: e.attributes.NM_PROV,
                } as never);
              });

              setArrProvincias(arrData as never);
              setArrDistritos(arrVacio as never);
            },
            function (error) {
              console.log(error);
            }
          );
      } else {
        setArrProvincias(arrVacio as never);
        setArrDistritos(arrVacio as never);
      }
    }

    async function loadDistritos() {
      const codProv = cmbProv.value;
      if (codProv != "") {
        const queryUrl =
          "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/2";
        const queryWhere = "CD_PROV = '" + codProv + "'";

        executeQueryJSON
          .call(null, queryUrl, {
            where: queryWhere,
            outFields: ["CD_DIST", "NM_DIST"],
          })
          .then(
            function (results) {
              const data = results.features;
              const arrData: never[] = [];
              arrData.push(arrVacio[0] as never);
              data.forEach((e) => {
                arrData.push({
                  id: e.attributes.CD_DIST,
                  name: e.attributes.NM_DIST,
                } as never);
              });

              setArrDistritos(arrData as never);
            },
            function (error) {
              console.log(error);
            }
          );
      } else {
        setArrDistritos(arrVacio as never);
      }
    }

    if (view != null) {
      //Cargar Capas de la Grilla para la Preevaluacion
      let layerGrid17 = new MapImageLayer({
        url: configPreEvaluacion.serv_WGS17,
        legendEnabled: false,
        title: "layerGrid17",
        listMode: "hide",
      });
      let layerGrid18 = new MapImageLayer({
        url: configPreEvaluacion.serv_WGS18,
        legendEnabled: false,
        title: "layerGrid18",
        listMode: "hide",
      });
      let layerGrid19 = new MapImageLayer({
        url: configPreEvaluacion.serv_WGS19,
        legendEnabled: false,
        title: "layerGrid19",
        listMode: "hide",
      });

      view.map.add(layerGrid17);
      view.map.add(layerGrid18);
      view.map.add(layerGrid19);
      //Cargar Datos para Departamento
      //
      //console.log("ketchPreEvaluacion");
      const graphicsLayerPreEvaluacion = new GraphicsLayer();
      const sketchPreEvaluacion = new Sketch({
        layer: graphicsLayerPreEvaluacion,
        creationMode: "single",
        defaultUpdateOptions: {
          tool: "move",
        },
      });
      sketchPreEvaluacion.visibleElements = {
        // createTools: {
        //   point: false,
        //   circle: false,
        // },
        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
        settingsMenu: false,
        undoRedoMenu: false,
      };
      sketchPreEvaluacion.view = view;
      sketchPreEvaluacion.container = "sketch-container-preevaluacion";
      sketchPreEvaluacion.on("create", function (event) {
        if (event.state === "complete") {
          graphicsLayerPreEvaluacion.remove(event.graphic);
          view.graphics.add(event.graphic);
          sketchPreEvaluacion.complete();
          //sketchPreEvaluacion
          fnCalcularArea(event.graphic.geometry);
        }
      });
    }

    loadDapartamentos();
  }, []);

  //al momento de actualizar combo datum y el array de coordenadas
  useEffect(() => {
    //function fnGraficaVertice(vertices: any[] = [], zona: string = "") {
    //Limpiar datasources
    dispatch(setRecords(0));
    searching.dataSources = [];
    searching.setExecuted(false);
    //tableCoords
    //setArrDataVerticesData(tableCoords as never);
    //const arrVertices = vertices.length > 0 ? vertices : arrDataVerticesData;
    //const arrVertices = vertices.length > 0 ? vertices : tableCoords;
    const arrVertices = tableCoords;
    //const curZona = selectedDatum != "" ? selectedDatum : "327" + txtZona;
    // console.log("VERTICES DENTRO DE LA FUNCION:", arrVertices);

    let points = [] as any;
    let numVertice = 0;
    view.graphics.removeAll();

    if (arrVertices.length > 0) {
      console.log(selectedDatum);
      arrVertices.forEach((fila: any) => {
        numVertice++;
        let PuntoBuffer = new Point();
        PuntoBuffer.longitude = Number(fila["este"]);
        PuntoBuffer.latitude = Number(fila["norte"]);
        PuntoBuffer.spatialReference = utils.getSpatialReference(
          Number(selectedDatum)
        );
        let PuntoBufferProy = projection.project(
          PuntoBuffer,
          utils.getSpatialReference(view.spatialReference.wkid)
        );
        const pointGraphic = new Graphic({
          geometry: PuntoBufferProy as Point,
          symbol: simplePointVertices,
        });
        points.push(PuntoBufferProy);
        const textSymbol = new TextSymbol({
          angle: 0,
          borderLineSize: undefined,
          color: new Color([255, 0, 0, 1]),
          font: new Font({
            decoration: "none",
            family: "Arial",
            size: 12,
            style: "normal",
            weight: "normal",
          }),

          haloColor: new Color([255, 255, 255, 1]),
          haloSize: 1,
          horizontalAlignment: "left",
          kerning: false,
          lineWidth: 192,
          rotated: false,
          text: "Vertice " + Number(fila["num"]),
          verticalAlignment: "baseline",
          xoffset: 2,
          yoffset: 4,
        });

        const pointGraphiclabel = new Graphic({
          geometry: PuntoBufferProy as Point,
          symbol: textSymbol,
        });

        view.graphics.add(pointGraphic);
        view.graphics.add(pointGraphiclabel);
        //etiqueta del vertice
      });

      const dataPoly = new Polygon();
      dataPoly.addRing(points);
      dataPoly.spatialReference = utils.getSpatialReference(
        view.spatialReference.wkid
      );

      consultarCapas(dataPoly); //capas complementarias
      const polyGraphic = new Graphic({
        geometry: dataPoly,
        symbol: symbolPoly,
      });
      view.graphics.add(polyGraphic);
      view.goTo(polyGraphic.geometry.extent.expand(1.5));
    } else {
      console.log("Error sin vertices");
    }
    //}
  }, [tableCoords, selectedDatum]);

  async function loadUbigeoPreEvaluacion(ubigeo: any, tipo: any) {
    let queryUrl = "";
    let queryWhere = "";
    let queryFields = ["*"];
    switch (ubigeo.length) {
      case 2:
        queryUrl = departamentoUrlPreEvaluacion;
        queryWhere = "CD_DEPA = '" + ubigeo + "'";
        break;
      case 4:
        queryUrl = provinciaUrlPreEvaluacion;
        queryWhere = "CD_PROV = '" + ubigeo + "'";
        break;

      case 6:
        queryUrl = distritoUrlPreEvaluacion;
        queryWhere = "CD_DIST = '" + ubigeo + "'";
        break;
    }

    if (tipo === "HOJA") {
      queryUrl = cuadrangulosUrlPreEvaluacion;
      queryWhere = "CD_HOJA = '" + ubigeo + "'";
    }

    executeQueryJSON
      .call(null, queryUrl, {
        where: queryWhere,
        outFields: queryFields,
        returnGeometry: true,
      })
      .then(
        function (results) {
          view.graphics.removeAll();
          const data = results.features;
          data.forEach((e) => {
            if (e.geometry != null) {
              const graphic = new Graphic({
                geometry: e.geometry,
                symbol: querySymbol,
              });
              if (tipo != "HOJA") {
                //Cuadrangulos
                executeQueryJSON
                  .call(null, cuadrangulosUrlPreEvaluacion, {
                    //where: queryWhere,
                    geometry: graphic.geometry,
                    spatialRelationship: "intersects",
                    outFields: ["CD_HOJA", "NM_HOJA"],
                  })
                  .then(
                    function (results) {
                      const data = results.features;
                      const arrData: never[] = [];
                      arrData.push(arrVacio[0] as never);
                      data.forEach((e) => {
                        arrData.push({
                          id: e.attributes.CD_HOJA,
                          name:
                            "(" +
                            e.attributes.CD_HOJA +
                            ") " +
                            e.attributes.NM_HOJA,
                        } as never);
                      });

                      setArrHojaPreEvaluacion(arrData as never);
                    },
                    function (error) {
                      console.log(error);
                    }
                  );
                //end cuadrangulos
              }
              view.graphics.add(graphic);
              view.goTo(graphic.geometry.extent).catch((error: any) => {
                console.error(error);
              });
            }
          });
        },
        function (error) {
          console.log(error);
        }
      );
  }

  //Graficar Geometrias
  const draw = new Draw({
    view: view,
  });

  function computeAngle(pointA: any, pointB: any) {
    let dLon = ((pointB.x - pointA.x) * Math.PI) / 180;
    let lat1 = (pointA.y * Math.PI) / 180;
    let lat2 = (pointB.y * Math.PI) / 180;
    let y = Math.sin(dLon) * Math.cos(lat2);
    let x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    //let bearing = 0;
    //bearing = ((Math.atan2(y, x) * 180) / Math.PI);
    let bearing = (((Math.atan2(y, x) * 180) / Math.PI + 360) % 360).toFixed(1); //Converting -ve to +ve (0-360)

    return bearing;
  }

  function consultarCapas(geometry: any) {
    showAlert({
      title: "Pre Evaluación",
      message:
        "Por favor espere unos minutos. Se está generando la información solicitada",
      type: "info",
      autoClose: true,
    });
    let arrCapas: any[] = configPreEvaluacion.Analisis;

    for (let l = 0; l < arrCapas.length; l++) {
      const reg = fnConsultarFeature(
        arrCapas[l]["capa"],
        arrCapas[l]["url"],
        arrCapas[l]["layout"],
        geometry
      );
    }

    //*******************consulta multicapa */
    console.log("LISTA DE CAPAS A MOSTRAR:", arrCapas);
    //let contador = 0;
    //const total = arrCapas.length;
    arrCapas.forEach((e: any) => {
      //contador++;
      let dataSource = new CustomDataSource(view, e.capa);
      dataSource.setFields(e.layout);
      dataSource.setUrlQuery(e.url);
      dataSource.setQueryGeom(geometry);
      //if (contador == total) {
      //dataSource.Search(true, true);
      //} else {
      dataSource.Search(false, false);
      //}
      searching.dataSources.push(dataSource);
    });

    console.log("DATASOURCES EJECUTOR:", searching.dataSources);
  }

  async function fnConsultarFeature(
    title: any,
    urlService: any,
    layout: any,
    geom: any
  ) {
    let queryUrl = urlService;
    executeQueryJSON
      .call(null, urlService, {
        geometry: geom,

        spatialRelationship: "intersects",
        returnGeometry: true,
        outFields: ["*"],
      })
      .then(
        function (results) {
          //console.log(title);
          //console.log(results.features);
          //console.log(results.features.length);
          if (title == "Catastro Minero") {
            const arrDataTemp: never[] = [];
            const data = results.features;
            let n = 0;
            data.forEach((e) => {
              n++;
              arrDataTemp.push({
                num: n,
                califica: e.attributes.CALIFICA,
                carta: e.attributes.CARTA,
                codigou: e.attributes.CODIGOU,
                concesion: e.attributes.CONCESION,
                demagis: e.attributes.DEMAGIS,
                depa: e.attributes.DEPA,
                desic_resol_ext: e.attributes.DESIC_RESOL_EXT,
                direccion: e.attributes.DIRECCION,
                distri: e.attributes.DISTRI,
                d_estado: e.attributes.D_ESTADO,
                estado: e.attributes.ESTADO,
                estado_minem: e.attributes.ESTADO_MINEM,
                fecha_resol_exti: utils.toDate(e.attributes.FECHA_RESOL_EXTI),
                fec_denu: utils.toDate(e.attributes.FEC_DENU),
                hasdatum: e.attributes.HASDATUM,
                hectagis: e.attributes.HECTAGIS,
                hor_denu: e.attributes.HOR_DENU,
                leyenda: e.attributes.LEYENDA,
                objectid: e.attributes.OBJECTID,
                observ: e.attributes.OBSERV,
                provi: e.attributes.PROVI,
                resol_exti: e.attributes.RESOL_EXTI,
                sustancia: e.attributes.SUSTANCIA,
                tit_conces: e.attributes.TIT_CONCES,
                ubica_exp: e.attributes.UBICA_EXP,
                zona: e.attributes.ZONA,
              } as never);
            });

            setArrDataCatastroData(arrDataTemp);
          }
          if (title == "Demarcación Política") {
            //console.log(results.features);
            const arrDataTemp: never[] = [];
            const data = results.features;
            let txtDepartamento = "";
            let txtProvincia = "";
            let txtDistrito = "";
            data.forEach((e) => {
              txtDepartamento = txtDepartamento + ", " + e.attributes.NM_DEPA;
              txtProvincia = txtProvincia + ", " + e.attributes.NM_PROV;
              txtDistrito = txtDistrito + ", " + e.attributes.NM_DIST;
            });
            arrDataTemp.push({
              num: 1,
              nm_depa: txtDepartamento.substring(2),
              nm_prov: txtProvincia.substring(2),
              nm_dist: txtDistrito.substring(2),
              cuadrangulo: "",
            } as never);

            setArrDataDemarcacionData(arrDataTemp);
          }
          if (title == "Hoja") {
            const arrDataTemp: never[] = [];
            const data = results.features;
            let txtCuadrangulo = "";

            data.forEach((e) => {
              txtCuadrangulo =
                txtCuadrangulo +
                ", " +
                e.attributes.CD_HOJA +
                " - " +
                e.attributes.NM_HOJA;
            });
            arrDataTemp.push({
              cuadrangulo: txtCuadrangulo.substring(2),
            } as never);
            setArrHojaPreEvaluacion(arrDataTemp);
          }
        },
        function (error) {
          console.log(error);
        }
      );
  }
  let txtZona = "";
  function fnCalcularArea(geometry: any) {
    let x = 0;
    let y = 0;

    switch (geometry.type) {
      case "point":
        x = geometry.x;
        y = geometry.y;

        break;

      case "polyline":
        x = geometry.paths[0][0][0];
        y = geometry.paths[0][0][1];
        break;
      case "polygon":
        x = geometry.rings[0][0][0];
        y = geometry.rings[0][0][1];

        break;
    }

    let pointDraw = new Point();
    pointDraw.x = x;
    pointDraw.y = y;
    pointDraw.spatialReference = new SpatialReference({
      wkid: view.spatialReference.wkid,
    });

    const spOut = utils.getSpatialReference(4326);
    let centro = webMercatorUtils.project(pointDraw, spOut);

    let loTxtPunto = coordinateFormatter.toUtm(
      centro as Point,
      "north-south-indicators",
      true
    );
    let loTxtPuntoArr = loTxtPunto.split(" ");
    txtZona = loTxtPuntoArr[0].replace(/[^0-9]+/g, "");
    setTxtZonaData(txtZona);
    const spOutQuery = utils.getSpatialReference(Number("327" + txtZona));

    let urlCuadricula = "";
    switch (txtZona) {
      case "17":
        urlCuadricula = configPreEvaluacion.layer_WGS17;
        break;
      case "18":
        urlCuadricula = configPreEvaluacion.layer_WGS18;
        break;

      case "19":
        urlCuadricula = configPreEvaluacion.layer_WGS19;
        break;
    }

    //Proyectar a la zona correspondiente

    let geomQueryTemp = projection.project(geometry, spOutQuery);
    let geomQuery;
    switch (geometry.type) {
      case "point":
        geomQuery = geomQueryTemp as Point;
        break;

      case "polyline":
        geomQuery = geomQueryTemp as Polyline;
        break;
      case "polygon":
        geomQuery = geomQueryTemp as Polygon;
        break;
    }

    executeQueryJSON
      .call(null, urlCuadricula, {
        geometry: geomQuery,

        spatialRelationship: "intersects",
        returnGeometry: true,
        outFields: ["*"],
      })
      .then(
        (results) => {
          if (results.features.length <= 10) {
            view.graphics.removeAll();

            const arrDataVertices_0: never[] = [];
            const data = results.features;
            const arrGeomTemp: any[] = [];
            data.forEach((e) => {
              const dataPoly = e.geometry as Polygon;
              arrGeomTemp.push(dataPoly);
            });
            const response = geometryEngine.union(arrGeomTemp);
            const dataPoly = response as Polygon;
            let PolyBufferProy = projection.project(
              dataPoly,
              utils.getSpatialReference(view.spatialReference.wkid)
            );
            const polyGraphic = new Graphic({
              geometry: PolyBufferProy as Polygon,
              symbol: symbolPoly,
            });
            view.graphics.add(polyGraphic);
            //punto * vertices
            const dataPolyRings = dataPoly.rings[0];
            let n = 0;
            dataPolyRings.forEach((e) => {
              arrDataVertices_0.push({
                este: e[0],
                norte: e[1],
              } as never);
            });

            let arrDataVertices: any[] = [];
            for (let l = 1; l < arrDataVertices_0.length; l++) {
              arrDataVertices.push({
                num: l,
                este: arrDataVertices_0[l]["este"],
                norte: arrDataVertices_0[l]["norte"],
              } as never);
            }

            // Variables Maximos y Minimos
            var yMin = Number(arrDataVertices[0]["norte"]);
            var yMax = Number(arrDataVertices[0]["norte"]);
            var xMin = Number(arrDataVertices[0]["este"]);
            var xMax = Number(arrDataVertices[0]["este"]);

            for (var r = 0; r < arrDataVertices.length; r++) {
              if (Number(arrDataVertices[r]["este"]) > xMax)
                xMax = Number(arrDataVertices[r]["este"]);
              if (Number(arrDataVertices[r]["este"]) < xMin)
                xMin = Number(arrDataVertices[r]["este"]);

              if (Number(arrDataVertices[r]["norte"]) > yMax)
                yMax = Number(arrDataVertices[r]["norte"]);
              if (Number(arrDataVertices[r]["norte"]) < yMin)
                yMin = Number(arrDataVertices[r]["norte"]);
            }

            //hallar Primer punto
            var xMaxPrimero = 0;
            var yMaxPrimero = 0;
            var xNum = 0;

            for (r = 0; r < arrDataVertices.length; r++) {
              if (Number(arrDataVertices[r]["este"]) == xMax) {
                xMaxPrimero = Number(arrDataVertices[r]["este"]);
                if (Number(arrDataVertices[r]["este"]) > yMaxPrimero)
                  yMaxPrimero = Number(arrDataVertices[r]["norte"]);
              }
            }
            for (r = 0; r < arrDataVertices.length; r++) {
              if (
                Number(arrDataVertices[r]["este"]) == xMaxPrimero &&
                Number(arrDataVertices[r]["norte"]) == yMaxPrimero
              ) {
                xNum = r;
                break;
              }
            }

            // reNumerar
            n = 1;
            const _itemsTemp: any[] = [];

            for (y = xNum; y < arrDataVertices.length; y++) {
              //arrDataVertices[y]["NUM"] = n
              _itemsTemp.push({
                num: n,
                este: arrDataVertices[y]["este"],
                norte: arrDataVertices[y]["norte"],
                NUM_NEW: "",
              });
              n++;
            }
            for (y = 0; y < xNum; y++) {
              //arrDataVertices[y].NUM[0] = n
              _itemsTemp.push({
                num: n,
                este: arrDataVertices[y]["este"],
                norte: arrDataVertices[y]["norte"],
                NUM_NEW: "",
              });
              n++;
            }
            //_items = [];
            //_items = _itemsTemp;

            arrDataVertices = _itemsTemp;
            /*********Calcular angulo de los puntos ******/
            let r0 = 0;
            let r1 = 0;
            let r2 = 0;

            for (var rr = 0; rr < arrDataVertices.length; rr++) {
              if (rr == 0) {
                r0 = arrDataVertices.length - 1;
                r1 = rr;
                r2 = rr + 1;
              } else if (rr == arrDataVertices.length - 1) {
                r0 = rr - 1;
                r1 = rr;
                r2 = 0;
              } else {
                r0 = rr - 1;
                r1 = rr;
                r2 = rr + 1;
              }

              let pointA = new Point();
              pointA.longitude = Number(arrDataVertices[r0].este);
              pointA.latitude = Number(arrDataVertices[r0].norte);
              pointA.spatialReference = utils.getSpatialReference(
                Number("327" + txtZona)
              );
              let pointB = new Point();
              pointB.longitude = Number(arrDataVertices[r1].este);
              pointB.latitude = Number(arrDataVertices[r1].norte);
              pointB.spatialReference = utils.getSpatialReference(
                Number("327" + txtZona)
              );

              let pointC = new Point();
              pointC.longitude = Number(arrDataVertices[r2].este);
              pointC.latitude = Number(arrDataVertices[r2].norte);
              pointC.spatialReference = utils.getSpatialReference(
                Number("327" + txtZona)
              );

              let angulo = computeAngle(pointA, pointB);
              let angulo1 = computeAngle(pointB, pointC);

              arrDataVertices[r1].NUM_NEW =
                r1.toString() +
                " -- " +
                angulo.toString() +
                " -- " +
                angulo1.toString();
              if (angulo == angulo1) {
                arrDataVertices[r1].NUM_NEW =
                  "x -- " +
                  r1.toString() +
                  " -- " +
                  angulo.toString() +
                  " -- " +
                  angulo1.toString();
              }
            }

            let vertices = arrDataVertices.filter(
              (vertice) => vertice["NUM_NEW"].indexOf("x") == -1
            );
            for (let y = 0; y < vertices.length; y++) {
              vertices[y]["num"] = y + 1;
            }
            // console.log(vertices);
            arrDataVertices = vertices;

            const arrDataTemp: any[] = [];
            vertices.forEach((e) => {
              arrDataTemp.push({
                num: e.num,
                este: e.este,
                norte: e.norte,
              } as never);
            });

            //console.log(arrDataTemp);
            setArrDataVerticesData(arrDataTemp as never);
            setSelectedDatum("327" + txtZona);
            console.log("327" + txtZona);
            setTableCoords(arrDataTemp as never);

            // console.log("VERTICES DEL QUERY:", arrDataTemp);

            //GRAFICAR VERTICES
            //fnGraficaVertice(arrDataTemp, txtZona);
          } else {
            console.log(
              "Número de cuadrículas no permitido para la Pre-Evaluación"
            );
          }
        },
        function (error) {
          console.log(error);
        }
      );
  }

  function onGraficarBtn() {
    setOnDraw(false); // onDraw = false;
    onGraficar();
  }
  function onGraficar() {
    if (onPreevaluacion) {
      setOnPreevaluacion(false); //onPreevaluacion = false;//
      onPreevaluar();
    } else {
      //_itemsArray = [];
      if (onDraw) {
        fnPreevaluar();
      } else {
        //divPreevaluacion.show();
      }
    }
  }
  function fnPreevaluar() {
    //onDraw = false;
    setOnPreevaluacion(true); //onPreevaluacion = true;
    //_graphicsLayerTemp.clear();
    onPreevaluar();
  }
  function onPreevaluar() {
    //fnGraficaVertice();
  }

  function fnPrintPreEvaluacion() {
    showAlert({
      title: "Pre Evaluación",
      message:
        "Por favor espere unos minutos. Se está generando la información solicitada",
      type: "info",
      autoClose: true,
    });

    //imagen del reporte
    view
      .takeScreenshot()
      .then(function (screenshot: any) {
        // You can use the screenshot data here
        //console.log(screenshot.dataUrl);

        let contenidoHTML = "<div id='divPrintPreEval'><!doctype html>";
        contenidoHTML += "<html>";
        contenidoHTML += "<head>";
        contenidoHTML += "<meta charset='utf-8'>";
        contenidoHTML += "<title>Pre-Evaluacion</title>";
        contenidoHTML += "<style type='text/css'>";
        contenidoHTML += ".tituloPE{ font-size: 14px; font-weight: bold; }";
        contenidoHTML +=
          "table.TablaPE { border: 1px solid #000000; background-color: #EEEEEE; width: 100%; text-align: left; border-collapse: collapse; }";
        contenidoHTML +=
          "table.TablaPE td, table.TablaPE th { border: 1px solid #AAAAAA; padding: 3px 2px;}";
        contenidoHTML += "table.TablaPE tbody td { font-size: 13px; }";
        contenidoHTML +=
          "table.TablaPE tr:nth-child(even) { background: #F4F4F4; }";
        contenidoHTML +=
          "table.TablaPE thead { background: #444444; border-bottom: 2px solid #444444; }";
        contenidoHTML +=
          "table.TablaPE thead th { font-size: 12px; font-weight: bold; color: #FFFFFF; text-align: center; border-left: 2px solid #D0E4F5; }";
        contenidoHTML +=
          "table.TablaPE thead th:first-child { border-left: none; }";
        contenidoHTML +=
          "table.TablaPE tfoot { font-size: 14px; font-weight: bold; color: #FFFFFF; background: #D0E4F5; background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%); background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%); background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%); border-top: 2px solid #444444; }";
        contenidoHTML += "table.TablaPE tfoot td { font-size: 14px; }";
        contenidoHTML += "table.TablaPE tfoot .links { text-align: right; }";
        contenidoHTML +=
          "table.TablaPE tfoot .links a{ display: inline-block; background: #1C6EA4; color: #FFFFFF; padding: 2px 8px; border-radius: 5px; }";

        contenidoHTML +=
          " <style type='text/css'> font-family: Arial; </style>";
        contenidoHTML += "</head>";
        contenidoHTML += "<body>";
        contenidoHTML +=
          "<div><center><img style='display:block; width:700px;' id='base64image' src='" +
          screenshot.dataUrl +
          "' />";
        contenidoHTML +=
          " <img src='https://geocatmin.ingemmet.gob.pe/geocatmin/img/leyenda_dm.png' style='margin-top: 5px;'" +
          "><p></p></center></div>";

        contenidoHTML +=
          "<div><p class='tituloPE'>Vértices en WGS 84 Zona " +
          txtZonaData +
          " de las cuadriculas intersectadas</p>";
        contenidoHTML += '<table class="TablaPE">';
        contenidoHTML += "<tbody>";
        contenidoHTML += "<tr>";
        contenidoHTML += "<th >Vértice</th>";
        contenidoHTML += "<th >Este</th>";
        contenidoHTML += "<th >Norte</th>";
        contenidoHTML += "</tr>";

        arrDataVerticesData.forEach((item) => {
          contenidoHTML += "<tr>";
          contenidoHTML +=
            "<td style='text-align: center;'>" + item["num"] + "</td>";
          contenidoHTML +=
            "<td style='text-align: end;'>" + item["este"] + "</td>";
          contenidoHTML +=
            "<td style='text-align: end;'>" + item["norte"] + "</td>";
          contenidoHTML += "</tr>";
        });

        contenidoHTML += "</tbody>";
        contenidoHTML += "</table></div>";
        contenidoHTML += "<p>&nbsp;</p>";
        contenidoHTML += "<div><p class='tituloPE'>Ubicación</p>";
        contenidoHTML += '<table class="TablaPE">';
        contenidoHTML += "<tbody>";
        contenidoHTML += "<tr>";
        contenidoHTML += "<th >Departamento</th>";
        contenidoHTML += "<th >Provincia</th>";
        contenidoHTML += "<th >Distrito</th>";
        contenidoHTML += "<th >Cuadrángulo</th>";
        contenidoHTML += "</tr>";

        //arrHojaPreEvaluacion[0].cuadrangulo

        arrDataDemarcacionData.forEach((item) => {
          contenidoHTML += "<tr>";
          contenidoHTML += "<td>" + item["nm_depa"] + "</td>";
          contenidoHTML += "<td>" + item["nm_prov"] + "</td>";
          contenidoHTML += "<td>" + item["nm_dist"] + "</td>";
          contenidoHTML +=
            "<td>" + arrHojaPreEvaluacion[0]["cuadrangulo"] + "</td>";
          contenidoHTML += "</tr>";
        });

        contenidoHTML += "</tbody>";
        contenidoHTML += "</table></div>";
        contenidoHTML += "<p>&nbsp;</p>";
        contenidoHTML +=
          "<div><p class='tituloPE'>Derechos Mineros intersectados </p>";
        contenidoHTML += '<table class="TablaPE">';
        contenidoHTML += "<tbody>";
        contenidoHTML += "<tr>";
        contenidoHTML += "<th >N°</th>";
        contenidoHTML += "<th >Código</th>";
        contenidoHTML += "<th >Nombre</th>";
        contenidoHTML += "<th >Titular</th>";
        contenidoHTML += "<th >Has.</th>";
        contenidoHTML += "<th >Estado</th>";
        contenidoHTML += "<th >Fech.Form.</th>";
        contenidoHTML += "</tr>";
        console.log(arrDataCatastroData);
        arrDataCatastroData.forEach((item) => {
          contenidoHTML += "<tr>";

          contenidoHTML += "<td>" + item["num"] + "</td>";
          contenidoHTML += "<td>" + item["codigou"] + "</td>";
          contenidoHTML += "<td>" + item["concesion"] + "</td>";
          contenidoHTML += "<td>" + item["tit_conces"] + "</td>";
          contenidoHTML += "<td>" + item["hectagis"] + "</td>";
          contenidoHTML += "<td>" + item["d_estado"] + "</td>";
          contenidoHTML += "<td>" + item["fec_denu"] + "</td>";

          contenidoHTML += "</tr>";
        });

        contenidoHTML += "</tbody>";
        contenidoHTML += "</table><div>";
        contenidoHTML += "<p>&nbsp;</p>";
        contenidoHTML += "	<footer> ";
        contenidoHTML +=
          "<center><p>Reporte Generado por el GEOCATMIN </p></center>";
        contenidoHTML += "</footer>";
        contenidoHTML +=
          '<div id="divBoton"><button type=\'button\' onClick=\'var ficha = document.getElementById("divPrintPreEval"); var ventimp = window.open(" ", "popimpr"); ventimp.document.write( ficha.innerHTML ); ventimp.document.close(); ventimp.print( ); ventimp.close(); \' > Imprimir </button></div>';
        //"<button type='button' onClick='console.log(\"imprimir\");let printContents = document.getElementById(\"divPrintPreEval\").innerHTML; let originalContents = document.body.innerHTML; document.body.innerHTML = printContents; window.print(); document.body.innerHTML = originalContents;  ' > Imprimir </button>";
        contenidoHTML += "</body>";
        contenidoHTML += "</html></div>";

        setContenidoPreEvaluacionHTML(contenidoHTML);
        console.log(contenidoHTML);
        const modal: any = document.getElementById("preEvaluacioHTML");
        modal.open = true;
      })
      .catch(function (error: any) {
        console.error("Error taking screenshot:", error);
      });

    // print
    //   .execute(urlServicePrint, params)
    //   .then(function (result) {
    //     var pdfUrl = result.url;

    //     window.open(pdfUrl, "_blank");
    //   })
    //   .catch(function (err) {
    //     console.log(err);
    //     showAlert({
    //       title: "Pre Evaluación",
    //       message: err.message,
    //       type: "danger",
    //       autoClose: true,
    //     });
    //   });
  }
  const printClick = () => {
    console.log("print");
  };
  const AddCoord = () => {
    if (txtEsteTransformarCoordenadas == "") {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorcx"),
        type: "danger",
        autoClose: true,
      });
      return false;
    }
    if (txtNorteTransformarCoordenadas == "") {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorcy"),
        type: "danger",
        autoClose: true,
      });
      return false;
    }
    if (isNaN(Number(txtEsteTransformarCoordenadas))) {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errornx"),
        type: "danger",
        autoClose: true,
      });
      return false;
    } else {
      if (parseFloat(txtEsteTransformarCoordenadas) == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errorn0"),
          type: "danger",
          autoClose: true,
        });
        return false;
      }
    }
    if (isNaN(Number(txtNorteTransformarCoordenadas))) {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorny"),
        type: "danger",
        autoClose: true,
      });
      return false;
    } else {
      if (parseFloat(txtNorteTransformarCoordenadas) == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errorn0"),
          type: "danger",
          autoClose: true,
        });
        return false;
      }
    }

    let coordX = parseFloat(txtEsteTransformarCoordenadas);
    let coordY = parseFloat(txtNorteTransformarCoordenadas);
    ValidateCoord(coordX, coordY);
  };

  function ValidateCoord(
    coordX: number,
    coordY: number,
    rowStart: number = 1,
    rowEnd: number = 1
  ) {
    //VALIDACIONES DE COORDENADAS
    if (coordX < 100000 || coordX > 999999) {
      showAlert({
        title: alertTitle,
        message: "La coordenada Este es incorrecta",
        type: "danger",
        autoClose: true,
      });
      return false;
    }
    if (coordY < 1000000 || coordY > 9999999) {
      showAlert({
        title: alertTitle,
        message: "La coordenada Norte es incorrecta",
        type: "danger",
        autoClose: true,
      });
      return false;
    }

    const item: TableCoord = {
      id: 0,
      este: coordX,
      norte: coordY,
      estetrans: 0,
      nortetrans: 0,
      mensaje: "",
    };
    if (rowStart == 1 && rowEnd == 1) {
      setTableCoords([...tableCoords, item]);
      //setArrDataVerticesData([...tableCoords, item]);
      setTxtEsteTransformarCoordenadas("0");
      setTxtNorteTransformarCoordenadas("0");
    } else {
      arrCoords.push(item);
      if (rowStart == rowEnd) {
        setTableCoords(arrCoords);
        setTxtEsteTransformarCoordenadas("0");
        setTxtNorteTransformarCoordenadas("0");
      }
    }
  }

  const RemoveCoord = () => {
    if (tableCoords.length == 0) {
      showAlert({
        title: alertTitle,
        message: "No existen registros por eliminar",
        type: "warning",
        autoClose: true,
      });
      return;
    }

    if (selectedIndex < 0) {
      showAlert({
        title: alertTitle,
        message: "Seleccione un registro",
        type: "danger",
        autoClose: true,
      });
      return;
    }

    let data = [...tableCoords];
    data.splice(selectedIndex, 1);
    setTableCoords(data);
  };

  const ExportXs = () => {
    let exportData: TableCoord[] = [];
    tableCoords.forEach((e, index) => {
      let fila: any = {};
      fila["ID"] = index + 1;
      fila["Este"] = e.este;
      fila["Norte"] = e.norte;
      fila["Este Transf."] = e.estetrans;
      fila["Norte Transf."] = e.nortetrans;
      fila["Mensaje"] = e.mensaje;
      exportData.push(fila);
    });

    const timestamp = Date.now();
    exportTool.exportToExcel(exportData, timestamp.toString());
  };

  const ShowUploadFile = () => {
    const status = !uploadFile;
    setUploadFile(status);
  };

  const ClearCords = () => {
    view.graphics.removeAll();
    const data: TableCoord[] = [];
    setTableCoords(data);
    setUploadFile(true);
  };

  const handleFileChange = (target: any) => {
    if (target.files) {
      setFile(target.files[0]);
    } else {
      setTxtNotify("Seleccione un archivo!");
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      setTxtNotify("Seleccione un archivo!");
      return;
    }

    const fileName = file.name.toLocaleLowerCase();

    if (fileName.indexOf(".xlsx") !== -1) {
      getTemplateCoords(fileName);
    } else {
      setTxtNotify("Subir un archivo en formato .XLSX");
    }
  };

  const getTemplateCoords = (fileName: string) => {
    let name: any = fileName.split(".");
    name = name[0].replace("c:\\fakepath\\", "");
    setTxtNotify("Cargando " + name + "...");

    //READING FILE
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //const data = XLSX.utils.sheet_to_csv(ws,  {header: 1, defval: ""});
      let data = XLSX.utils.sheet_to_csv(ws);
      /* Update state */
      ImportTemplateData(data);
      setTxtNotify("Archivo cargado");
      setFile(null);
      setUploadFile(true);

      const myForm = document.getElementById(
        "uploadFormXls"
      ) as HTMLFormElement;
      myForm.reset();
    };
    reader.readAsBinaryString(file as Blob);
  };

  function ImportTemplateData(data: string) {
    arrCoords = [];
    const lines = data.split(/\r?\n/);
    lines.forEach((row, index) => {
      if (index > 0) {
        const line = row.split(",");
        if (line.length < 3) {
          showAlert({
            title: alertTitle,
            message: "El formato cargado es incorrecto",
            type: "warning",
            autoClose: true,
          });
          return false;
        }
        const id = line[0];
        const x = line[1];
        const y = line[2];

        if (x == "" || y == "") {
          showAlert({
            title: alertTitle,
            message:
              "La coordenada de la fila " +
              index.toString() +
              " está incompleta",
            type: "warning",
            autoClose: true,
          });
          return false;
        }
        if (isNaN(Number(x)) || isNaN(Number(y))) {
          showAlert({
            title: alertTitle,
            message:
              "La coordenada de la fila " + index.toString() + " es incorrecta",
            type: "warning",
            autoClose: true,
          });
          return false;
        }

        ValidateCoord(parseFloat(x), parseFloat(y), index, lines.length - 1);
      }
    });
  }

  return (
    <div id="divPreEvaluacion">
      <CalciteTabs>
        <CalciteTabNav
          slot="title-group"
          //onChange={(e) => fnChangeTab(e.target)}
        >
          <CalciteTabTitle>
            {t("screens.widgets.preevaluacion.porubucacion")}
          </CalciteTabTitle>
          <CalciteTabTitle>
            {t("screens.widgets.preevaluacion.porcoordenadas")}
          </CalciteTabTitle>
        </CalciteTabNav>

        <CalciteTab>
          <div style={{ overflowX: "hidden" }}>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.preevaluacion.departamento")}
                <CalciteSelect
                  id="cmbDpto"
                  label={t("screens.widgets.preevaluacion.departamento")}
                >
                  {arrDepartamentos.map((option: any, idx: number) => {
                    return (
                      <CalciteOption key={option.id} value={option.id}>
                        {option.name}
                      </CalciteOption>
                    );
                  })}
                </CalciteSelect>
              </CalciteLabel>
            </div>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.preevaluacion.provincia")}
                <CalciteSelect
                  label={t("screens.widgets.preevaluacion.provincia")}
                  id="cmbProv"
                >
                  {arrProvincias.map((option: any, idx: number) => {
                    return (
                      <CalciteOption key={idx} value={option.id}>
                        {option.name}
                      </CalciteOption>
                    );
                  })}
                </CalciteSelect>
              </CalciteLabel>
            </div>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.preevaluacion.distrito")}
                <CalciteSelect
                  label={t("screens.widgets.preevaluacion.distrito")}
                  id="cmbDist"
                >
                  {arrDistritos.map((option: any, idx: number) => {
                    return (
                      <CalciteOption key={idx} value={option.id}>
                        {option.name}
                      </CalciteOption>
                    );
                  })}
                </CalciteSelect>
              </CalciteLabel>
            </div>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.preevaluacion.cuadrangulo")}
                <CalciteSelect
                  label={t("screens.widgets.preevaluacion.cuadrangulo")}
                  id="cmbHojaPreEvaluacion"
                  //value={selectselectedOptionCuadrangulo}
                  //disabled={selectedDisableCuadrangulo}
                >
                  {arrHojaPreEvaluacion.map((option: any, idx: number) => {
                    return (
                      <CalciteOption key={idx} value={option.id}>
                        {option.name}
                      </CalciteOption>
                    );
                  })}
                </CalciteSelect>
              </CalciteLabel>
            </div>
            <div id="sketch-container-preevaluacion"></div>
          </div>
        </CalciteTab>

        <CalciteTab>
          <div style={{ overflowX: "hidden" }}>
            <div className="row py-2">
              <div className="col-12 col-md d-flex">
                <CalciteLabel>
                  {t("screens.widgets.TransformarCoordenadas.datum")}
                  <CalciteSelect
                    label={t("screens.widgets.TransformarCoordenadas.datum")}
                    id="lstDatumTransformarCoordenadas"
                    onCalciteSelectChange={(e) =>
                      setSelectedDatum(e.target.value)
                    }
                  >
                    <CalciteOption value="32717">
                      WGS_1984_UTM_Zone_17S
                    </CalciteOption>
                    <CalciteOption value="32718">
                      WGS_1984_UTM_Zone_18S
                    </CalciteOption>
                    <CalciteOption value="32719">
                      WGS_1984_UTM_Zone_19S
                    </CalciteOption>
                  </CalciteSelect>
                </CalciteLabel>
              </div>
              {/* <div className="col-6 col-md d-flex">
                <CalciteLabel>
                  {t("screens.widgets.TransformarCoordenadas.zona")}

                  <CalciteSelect
                    label={t("screens.widgets.TransformarCoordenadas.zona")}
                    id="lstZonaTransformarCoordenadas"
                    onCalciteSelectChange={(e) =>
                      setSelectedZona(e.target.value)
                    }
                  >
                    <CalciteOption value="17">17</CalciteOption>
                    <CalciteOption value="18">18</CalciteOption>
                    <CalciteOption value="19">19</CalciteOption>
                  </CalciteSelect>
                </CalciteLabel>
              </div> */}
            </div>
            <div className="row py-2">
              <div className="col-4 col-md d-flex">
                <CalciteLabel>
                  {t("screens.widgets.TransformarCoordenadas.este")}
                  <CalciteInput
                    placeholder={t(
                      "screens.widgets.TransformarCoordenadas.este"
                    )}
                    type="text"
                    value={txtEsteTransformarCoordenadas}
                    onCalciteInputChange={(e) =>
                      setTxtEsteTransformarCoordenadas(e.target.value)
                    }
                  ></CalciteInput>
                </CalciteLabel>
              </div>
              <div className="col-4 col-md d-flex">
                <CalciteLabel>
                  {t("screens.widgets.TransformarCoordenadas.norte")}
                  <CalciteInput
                    placeholder={t(
                      "screens.widgets.TransformarCoordenadas.norte"
                    )}
                    type="text"
                    value={txtNorteTransformarCoordenadas}
                    onCalciteInputChange={(e) =>
                      setTxtNorteTransformarCoordenadas(e.target.value)
                    }
                  ></CalciteInput>
                </CalciteLabel>
              </div>
              <div
                className="col-4 col-md d-flex"
                style={{ paddingTop: "22px" }}
              >
                <CalciteButton
                  iconStart="plus"
                  className="ms-2"
                  id="btnAdicionaVertice"
                  style={{ height: "24px" }}
                  onClick={(e) => AddCoord()}
                ></CalciteButton>
                <CalciteTooltip reference-element="btnAdicionaVertice">
                  Adiciona un vertice
                </CalciteTooltip>
                <CalciteButton
                  iconStart="trash"
                  className="ms-2"
                  id="btnEliminaVertice"
                  style={{ height: "24px" }}
                  onClick={(e) => RemoveCoord()}
                ></CalciteButton>
                <CalciteTooltip reference-element="btnEliminaVertice">
                  Elimina un vertice
                </CalciteTooltip>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                overflow: "auto",
                marginBottom: "5px",
                marginTop: "4px",
                height: "200px",
              }}
            >
              <CalciteTable
                caption="Simple table"
                scale="s"
                selectionMode="single"
                layout="fixed"
              >
                <CalciteTableRow slot="table-header">
                  <CalciteTableHeader heading="Nro"></CalciteTableHeader>
                  <CalciteTableHeader heading="Este"></CalciteTableHeader>
                  <CalciteTableHeader heading="Norte"></CalciteTableHeader>
                </CalciteTableRow>
                {tableCoords.length > 0 ? (
                  tableCoords.map((item, index) => {
                    return (
                      <CalciteTableRow
                        key={index}
                        onCalciteTableRowSelect={(e) => setSelectedIndex(index)}
                      >
                        <CalciteTableCell
                          alignment="end"
                          key={"c1" + index.toString()}
                        >
                          {index + 1}
                        </CalciteTableCell>
                        <CalciteTableCell
                          alignment="end"
                          key={"c2" + index.toString()}
                        >
                          {item.este}
                        </CalciteTableCell>
                        <CalciteTableCell
                          alignment="end"
                          key={"c3" + index.toString()}
                        >
                          {item.norte}
                        </CalciteTableCell>
                      </CalciteTableRow>
                    );
                  })
                ) : (
                  <CalciteTableRow>
                    <CalciteTableCell colSpan={3}>
                      No existen registros
                    </CalciteTableCell>
                  </CalciteTableRow>
                )}
              </CalciteTable>
            </div>
            <div>
              <CalciteButton
                iconStart="upload"
                id="btnUpload"
                className="ms-2"
                onClick={(e) => ShowUploadFile()}
              ></CalciteButton>
              <CalciteTooltip reference-element="btnUpload">
                Importar coordenadas
              </CalciteTooltip>
              {tableCoords.length > 0 ? (
                <CalciteButton
                  iconStart="download"
                  id="btnDownload"
                  className="ms-2"
                  onClick={(e) => ExportXs()}
                ></CalciteButton>
              ) : (
                <CalciteButton
                  iconStart="download"
                  id="btnDownload"
                  className="ms-2"
                  disabled
                ></CalciteButton>
              )}
              <CalciteTooltip reference-element="btnDownload">
                Descargar grilla
              </CalciteTooltip>
              <a
                href={`${process.env.PUBLIC_URL}/plantilla/CoordenadasPlantilla.xlsx`}
                target="_blank"
              >
                <CalciteButton
                  iconStart="file-excel"
                  id="btnTemplate"
                  className="ms-2"
                ></CalciteButton>
                <CalciteTooltip reference-element="btnTemplate">
                  Descargar plantilla
                </CalciteTooltip>
              </a>

              <CalciteButton
                iconStart="refresh"
                id="btnRefresh"
                className="ms-2"
                onClick={(e) => ClearCords()}
              ></CalciteButton>
              <CalciteTooltip reference-element="btnRefresh">
                Limpiar todo
              </CalciteTooltip>
            </div>
            <div style={{ padding: "6px" }} hidden={uploadFile}>
              <form
                encType="multipart/form-data"
                method="POST"
                id="uploadFormXls"
              >
                <CalciteLabel
                  style={{
                    padding: "5px",
                    borderStyle: "dashed solid",
                    borderWidth: "1px",
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => handleFileChange(e.target)}
                    id="inFile"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </CalciteLabel>
                <div className="text-center" style={{ marginTop: "8px" }}>
                  <CalciteButton
                    iconStart="upload-to"
                    onClick={() => handleUploadClick()}
                  >
                    Cargar archivo
                  </CalciteButton>
                  <span
                    className="file-upload-status"
                    style={{ opacity: "1" }}
                    id="upload-status"
                  >
                    <p style={{ color: "red", padding: "4px" }}>{txtNotify}</p>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </CalciteTab>
      </CalciteTabs>

      <div style={{ display: "inline" }}>
        <CalciteButton
          iconStart="pin-tear"
          label={t("screens.widgets.preevaluacion.btnGraficar")}
          style={{ padding: "3px" }}
          onClick={() => fnPreevaluar()}
          // onClick={() => clearProyectosMineros()}
        >
          {t("screens.widgets.preevaluacion.btnGraficar")}{" "}
        </CalciteButton>
        <CalciteButton
          iconStart="print"
          label={t("screens.widgets.preevaluacion.btnImprimir")}
          style={{ padding: "3px" }}
          onClick={() => fnPrintPreEvaluacion()}
        >
          {t("screens.widgets.preevaluacion.btnImprimir")}
        </CalciteButton>
        <CustomPopupHTML
          title={t("screens.widgets.preevaluacion.tituloReporte")}
          content={contenidoPreEvaluacionHTML}
          id="preEvaluacioHTML"
        ></CustomPopupHTML>
      </div>
    </div>
  );
}
