import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomFunctions } from "../../utils/CustomFunctions";
import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalcitePanel,
  CalciteSlider,
  CalciteList,
  CalciteListItem,
  CalciteAction,
  CalciteSelect,
  CalciteOption,
  CalciteChip,
} from "@esri/calcite-components-react";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import configAnalisisGeologico from "./ConfigAnalisisRiesgoGeologico.json";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { MainSearching } from "./MainSearching";
import { CustomDataSource } from "../CustomDataSource";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import { useAlert } from "../../core/providers/AlertProvider";

export function AnalisisRiesgoGeologico(props: any) {
  const { t } = useTranslation();
  const showAlert = useAlert();

  let searching: MainSearching = props.data;
  const view = searching.view;
  const dispatch = useAppDispatch();
  const [arrResultados, setArrResultados] = useState([]);
  const utils = new CustomFunctions();

  const [arrCapasAnalizar, setarrCapasAnalizar] = useState([]);
  const [bufferAnalisisRiesgoGeologico, setBufferAnalisisRiesgoGeologico] =
    useState(10);
  let arrCapas: any[];
  let arrCampos: any[];
  const arrData: never[] = [];

  const symbolPoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [255, 0, 0],
      width: 1,
    },
  };

  const symbolLine = {
    color: [255, 0, 0, 11, 0.75],
    width: 1,
  };
  const symbolPoly = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.5],
    style: "solid",
    outline: symbolLine,
  };
  const symbolLineBufffer = {
    color: [255, 0, 0, 0],
    width: 1,
  };
  const symbolPolyBuffer = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: symbolLineBufffer,
  };
  let urlCapaConsulta = ""; //  "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer/0";

  useEffect(() => {
    // const cmbcapaAnalisisRiesgoGeologico = document.getElementById(
    //   "cmbcapaAnalisisRiesgoGeologico"
    // ) as HTMLSelectElement;

    // cmbcapaAnalisisRiesgoGeologico.addEventListener(
    //   "calciteSelectChange",
    //   () => {
    //     try {
    //       const evenNumbers = arrCapas.filter(
    //         (capa) => capa.label === cmbcapaAnalisisRiesgoGeologico.value
    //       );
    //       urlCapaConsulta = evenNumbers[0].advConfig.url;
    //       arrCampos = evenNumbers[0].advConfig.fields;
    //       fnConsultar(geomQuery);
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }
    // );
    function loadInitAnalisisRiesgoGeologico(view: any) {
      //arrCapas.push(configAnalisisGeologico.tabs as never);
      arrCapas = configAnalisisGeologico.tabs;
      setarrCapasAnalizar(arrCapas as never);
      const graphicsLayerAnalisisRiesgoGeologico = new GraphicsLayer();
      const sketchAnalisisRiesgoGeologico = new Sketch({
        layer: graphicsLayerAnalisisRiesgoGeologico,
        creationMode: "single",
        defaultUpdateOptions: {
          tool: "move",
        },
      });

      sketchAnalisisRiesgoGeologico.visibleElements = {
        // createTools: {
        //   point: false,
        //   circle: false,
        // },

        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
        settingsMenu: false,
        undoRedoMenu: false,
      };

      sketchAnalisisRiesgoGeologico.view = view;
      sketchAnalisisRiesgoGeologico.container =
        "sketch-container-AnalisisRiesgoGeologico";
      sketchAnalisisRiesgoGeologico.on("create", function (event) {
        if (event.state === "complete") {
          graphicsLayerAnalisisRiesgoGeologico.remove(event.graphic);

          //selectFeatures(event.graphic.geometry);

          fnGraficarBuffer(event.graphic.geometry);
          sketchAnalisisRiesgoGeologico.complete();
          //fnCalcularArea(event.graphic.geometry);
        }
      });
    }

    function fnGraficarBuffer(geometry: any) {
      //console.log(slBuffer);
      //console.log(graphic);
      //view?.view.graphics.add(geometry);
      dispatch(setRecords(0));
      searching.dataSources = [];
      searching.setExecuted(false);

      //ALERTA de procesamiento
      showAlert({
        title: "Analisis de Riesgo Geológico",
        message: "Por favor espere. Se está generando la información solicitada...",
        type: "info",
        autoClose: true
      });

      switch (geometry.type) {
        case "point":
          fnGraficar(geometry as Point, symbolPoint);
          break;
        case "polyline":
          fnGraficar(geometry as Polyline, symbolLine);

          break;
        case "polygon":
          fnGraficar(geometry as Polygon, symbolPoly);

          break;
      }
    }

    function fnGraficar(geomQuery: any, symbol: any) {
      view.graphics.removeAll();
      const slBuffer = document.getElementById("slBuffer") as HTMLSelectElement;

      const geomQueryIn = new Graphic({
        geometry: geomQuery,
        symbol: symbol,
      });
      //console.log(buffer);
      const bufferGeom = geometryEngine.geodesicBuffer(
        geomQuery,
        //bufferAnalisisRiesgoGeologico,
        Number(slBuffer.value),
        "kilometers"
      );
      const geomQueryOut = new Graphic({
        geometry: bufferGeom as Polygon,
        symbol: symbolPolyBuffer,
      });

      view.graphics.add(geomQueryIn);
      view.graphics.add(geomQueryOut);
      //setGeomQuery(geomQueryOut.geometry);
      geomQuery = geomQueryOut.geometry as Polygon;
      fnConsultar(geomQueryOut.geometry);
    }

    function fnConsultar(geomQuery: any) {
      console.log(geomQuery);

      console.log("Consultar----");
      console.log(arrCapasAnalizar);
      let contador = 0;
      //const total = arrCapasAnalizar.length;
      //setarrCapasAnalizar(arrCapas as never);
      const arrCapas: any[] = configAnalisisGeologico.tabs as never;

      console.log(arrCapas);
      arrCapas.forEach((e: any) => {
        contador++;
        let dataSource = new CustomDataSource(view, e.title);
        dataSource.setFields(e.fields);
        dataSource.setUrlQuery(e.url);
        dataSource.setQueryGeom(geomQuery);
        if (contador == 1) {
          dataSource.Search(true, true);
        } else {
          dataSource.Search(false, false);
        }

        searching.dataSources.push(dataSource);
      });
      // const simplePointVertices = new SimpleMarkerSymbol({
      //   angle: 0,
      //   color: new Color([59, 143, 196, 0.8]),
      //   outline: new SimpleLineSymbol({
      //     cap: "round",
      //     color: new Color([59, 143, 196, 0.9]),
      //     join: "round",
      //     miterLimit: 1,
      //     style: "solid",
      //     width: 1,
      //   }),
      //   path: "undefined",
      //   size: 18,
      //   style: "circle",
      //   xoffset: 0,
      //   yoffset: 0,
      // });

      // executeQueryJSON
      //   .call(null, urlCapaConsulta, {
      //     geometry: geomQuery,
      //     spatialRelationship: "intersects",
      //     outFields: ["*"],
      //     returnGeometry: true,
      //     returnCentroid: true,
      //   })
      //   .then(
      //     function (results) {
      //       //console.log(results);

      //       const data = results.features;

      //       let n = 0;
      //       data.forEach((e) => {
      //         n++;

      //         let descripcion = n + ": ";

      //         arrCampos.forEach((f) => {
      //           descripcion = descripcion + e.attributes[f.label] + " - ";
      //         });
      //         //            console.log(descripcion);
      //         arrData.push({
      //           valor: e.attributes["OBJECTID"],
      //           description: descripcion,
      //         } as never);

      //         let PuntoBuffer = new Point();

      //         switch (e.geometry.type) {
      //           case "polyline":
      //             PuntoBuffer = e.geometry.extent.center as Point;
      //             break;
      //           case "polygon":
      //             const poly = e.geometry as Polygon;
      //             PuntoBuffer = poly.centroid as Point;
      //             break;
      //           case "multipoint":
      //           case "point":
      //             PuntoBuffer = e.geometry as Point;
      //             break;
      //         }

      //         const pointGraphic = new Graphic({
      //           geometry: PuntoBuffer,
      //           symbol: simplePointVertices,
      //         });

      //         const textSymbol = new TextSymbol({
      //           angle: 0,
      //           borderLineSize: undefined,
      //           color: new Color([255, 255, 255, 0.5]),
      //           font: new Font({
      //             decoration: "none",
      //             family: "Arial",
      //             size: 10,
      //             style: "normal",
      //             weight: "normal",
      //           }),

      //           haloColor: new Color([255, 255, 255, 1]),
      //           haloSize: 0,
      //           horizontalAlignment: "center",
      //           verticalAlignment: "middle",
      //           kerning: false,
      //           lineWidth: 192,
      //           rotated: false,
      //           text: n.toString(),
      //           xoffset: 0,
      //           yoffset: 0,
      //         });

      //         const pointGraphiclabel = new Graphic({
      //           geometry: PuntoBuffer as Point,
      //           symbol: textSymbol,
      //         });

      //         view.graphics.add(pointGraphic);
      //         view.graphics.add(pointGraphiclabel);
      //       });

      //       setArrResultados(arrData as never);
      //       console.log(arrData);
      //     },
      //     function (error) {
      //       console.log(error);
      //     }
      //   );
    }
    loadInitAnalisisRiesgoGeologico(view);
  }, []);

  let geomQuery = new Polygon();

  // function fnSelectItem(e: any) {
  //   console.log(e.target.selected);
  //   console.log(e.target.value);

  //   executeQueryJSON
  //     .call(null, urlCapaConsulta, {
  //       where: "OBJECTID = " + e.target.value,
  //       outFields: ["+"],
  //     })
  //     .then(
  //       function (results) {
  //         console.log(results);
  //       },
  //       function (error) {
  //         console.log(error);
  //       }
  //     );
  // }
  // function fnChangeSliderAnalisisRiesgoGeologico(e: any) {
  //   console.log(e.target.value);
  //   setBufferAnalisisRiesgoGeologico(e.target.value);
  // }

  return (
    <div>
      <CalciteLabel>
        {t("screens.widgets.analisisriesgogeologico.localizar")}
        <div id="sketch-container-AnalisisRiesgoGeologico"></div>
      </CalciteLabel>

      <CalciteLabel>
        {t("screens.widgets.analisisriesgogeologico.distancia")}
        <CalciteSlider
          id="slBuffer"
          value={bufferAnalisisRiesgoGeologico}
          label-handles
          label-ticks
          min-label="0"
          max-label="100 km"
          ticks={100}
        ></CalciteSlider>
      </CalciteLabel>
    </div>
  );
}
