import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

export class CustomMap {
  view: MapView | SceneView;
  mapView: MapView;
  sceneView: SceneView;
  divContainer;
  widgets;

  public constructor(
    params: any,
    divContainer: HTMLDivElement,
    widgets: any[]
  ) {
    this.mapView = new MapView(params);
    this.sceneView = new SceneView(params);
    this.view = this.mapView;
    this.divContainer = divContainer;
    this.view.container = this.divContainer;
    this.widgets = widgets;
    this.view.ui.padding = { top: 70, left: 0, right: 5, bottom: 20 };
  }

  public getView() {
    console.log(this.view);
    return this.view;
  }

  public switchView() {
    //debugger;
    const is3D = this.view.type === "3d";
    const activeViewpoint = this.view.viewpoint.clone();

    // remove the reference to the container for the previous view
    this.view.set({
      container: null,
    });

    if (is3D) {
      // if the input view is a SceneView, set the viewpoint on the
      // mapView instance. Set the container on the mapView and flag
      // it as the active view
      this.mapView.set({
        container: this.divContainer,
        viewpoint: activeViewpoint,
      });

      this.view = this.mapView;
    } else {
      this.sceneView.set({
        container: this.divContainer,
        viewpoint: activeViewpoint,
      });
      this.view = this.sceneView;

      this.view.ui.padding = { top: 80, left: 0, right: 20, bottom: 20 };
      this.view.ui.empty("top-left");
      this.view.ui.add(["zoom", "navigation-toggle", "compass"], "top-right");
    }

    //console.log(this.view);
    // let graphicsLayerResultado = new GraphicsLayer();
    // graphicsLayerResultado.id = "graphicsLayerResultado";
    // graphicsLayerResultado.title = "graphicsLayerResultado";
    // this.view.map.add(graphicsLayerResultado);
    //ASIGNANDO VISTA A WIDGETS
    this.widgets.forEach((w: any) => {
      w.view = this.view;
    });
  }
}
