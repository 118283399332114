import React, { useEffect, useState } from "react";
import {
  CalciteLabel,
  CalciteSwitch,
  CalciteSelect,
  CalciteOption,
  CalciteCheckbox,
  CalciteIcon,
  CalciteButton,
} from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import configPotencialMinero from "./configPotencialMinero.json";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import { MainSearching } from "./MainSearching";
import { CustomDataSource } from "../CustomDataSource";
import { useAlert } from "../../core/providers/AlertProvider";

export function PotencialMinero(props: any) {
  let searching: MainSearching = props.data;
  const view = searching.view;

  const dispatch = useAppDispatch();
  const showAlert = useAlert();
  const [arrCapas, setArrCapas] = useState(configPotencialMinero.Analisis);
  const [drawGraphic, setDrawGraphic] = useState<any>(null);

  const { t } = useTranslation();
  const [arrDepartamentosPotencialMinero, setArrDepartamentosPotencialMinero] =
    useState([]);
  const [arrProvinciasPotencialMinero, setArrProvinciasPotencialMinero] =
    useState([]);
  const [arrDistritosPotencialMinero, setArrDistritosPotencialMinero] =
    useState([]);

  const arrVacio = [{ id: "", name: "--Seleccione uno--" }];
  const alertTitle = "Potencial Minero";

  const departamentoUrlPotencialMinero =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/0";
  const provinciaUrlPotencialMinero =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/1";
  const distritoUrlPotencialMinero =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/2";

  const [hideProvincia, setHideProvincia] = useState(true);
  const [hideDistrito, setHideDistrito] = useState(true);
  const [chkMetalico, setMetalico] = useState(true);
  const [chkNoMetalico, setNoMetalico] = useState(false);

  const querySymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0],
    style: "solid",
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 0, 0, 11],
      width: 1,
    },
  };

  const graphicsLayerPotencialMinero = new GraphicsLayer();
  const sketchPotencialMinero = new Sketch({
    layer: graphicsLayerPotencialMinero,
    creationMode: "single",
    defaultUpdateOptions: {
      tool: "move",
    },
  });

  sketchPotencialMinero.visibleElements = {
    selectionTools: {
      "lasso-selection": false,
      "rectangle-selection": false,
    },
    settingsMenu: false,
    undoRedoMenu: false,
  };

  useEffect(() => {
    searching.setType("M");

    async function loadDapartamentosPotencialMinero() {
      const queryUrl =
        "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/0";
      const queryWhere = "CD_DEPA <>'99'";

      executeQueryJSON
        .call(null, queryUrl, {
          where: queryWhere,
          outFields: ["CD_DEPA", "NM_DEPA"],
        })
        .then(
          function (results) {
            const data = results.features;
            const arrData: never[] = [];
            arrData.push(arrVacio[0] as never);
            data.forEach((e) => {
              arrData.push({
                id: e.attributes.CD_DEPA,
                name: e.attributes.NM_DEPA,
              } as never);
            });

            setArrDepartamentosPotencialMinero(arrData as never);
            setArrProvinciasPotencialMinero(arrVacio as never);
            setArrDistritosPotencialMinero(arrVacio as never);
          },
          function (error) {
            console.log(error);
          }
        );
    }

    const lstDepartamentoPotencialMinero = document.getElementById(
      "lstDepartamentoPotencialMinero"
    ) as HTMLSelectElement;
    const lstProvinciaPotencialMinero = document.getElementById(
      "lstProvinciaPotencialMinero"
    ) as HTMLSelectElement;
    const lstDistritoPotencialMinero = document.getElementById(
      "lstDistritoPotencialMinero"
    ) as HTMLSelectElement;

    lstDepartamentoPotencialMinero.addEventListener(
      "calciteSelectChange",
      () => {
        loadProvinciasPotencialMinero();
        loadUbigeoPotencialMinero(lstDepartamentoPotencialMinero.value, "");
      }
    );

    lstProvinciaPotencialMinero.addEventListener("calciteSelectChange", () => {
      loadDistritosPotencialMinero();
      loadUbigeoPotencialMinero(lstProvinciaPotencialMinero.value, "");
    });

    lstDistritoPotencialMinero.addEventListener("calciteSelectChange", () => {
      loadUbigeoPotencialMinero(lstDistritoPotencialMinero.value, "");
    });

    async function loadProvinciasPotencialMinero() {
      const codDpto = lstDepartamentoPotencialMinero.value;
      if (codDpto != "") {
        const queryUrl =
          "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/1";
        const queryWhere = "CD_DEPA = '" + codDpto + "'";

        executeQueryJSON
          .call(null, queryUrl, {
            where: queryWhere,
            outFields: ["CD_PROV", "NM_PROV"],
          })
          .then(
            function (results) {
              const data = results.features;
              const arrData: never[] = [];
              arrData.push(arrVacio[0] as never);
              data.forEach((e) => {
                arrData.push({
                  id: e.attributes.CD_PROV,
                  name: e.attributes.NM_PROV,
                } as never);
              });
              setHideProvincia(false);
              setHideDistrito(true);

              setArrProvinciasPotencialMinero(arrData as never);
              setArrDistritosPotencialMinero(arrVacio as never);
            },
            function (error) {
              console.log(error);
            }
          );
      } else {
        setArrProvinciasPotencialMinero(arrVacio as never);
        setArrDistritosPotencialMinero(arrVacio as never);
      }
    }

    async function loadDistritosPotencialMinero() {
      const codProv = lstProvinciaPotencialMinero.value;
      if (codProv != "") {
        const queryUrl =
          "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/2";
        const queryWhere = "CD_PROV = '" + codProv + "'";

        executeQueryJSON
          .call(null, queryUrl, {
            where: queryWhere,
            outFields: ["CD_DIST", "NM_DIST"],
          })
          .then(
            function (results) {
              const data = results.features;
              const arrData: never[] = [];
              arrData.push(arrVacio[0] as never);
              data.forEach((e) => {
                arrData.push({
                  id: e.attributes.CD_DIST,
                  name: e.attributes.NM_DIST,
                } as never);
              });
              setHideDistrito(false);
              setArrDistritosPotencialMinero(arrData as never);
            },
            function (error) {
              console.log(error);
            }
          );
      } else {
        setArrDistritosPotencialMinero(arrVacio as never);
      }
    }

    sketchPotencialMinero.view = view;
    sketchPotencialMinero.container = "sketch-container-potencialminero";
    sketchPotencialMinero.on("create", (event) => {
      if (event.state === "complete") {
        view.graphics.removeAll();
        const graphic = event.graphic;
        graphic.attributes = { id: "sketchedObject" };
        setDrawGraphic(graphic);
        view.graphics.add(graphic);
        sketchPotencialMinero.complete();
      }
    });

    loadDapartamentosPotencialMinero();
  }, []);

  async function loadUbigeoPotencialMinero(ubigeo: any, tipo: any) {
    let queryUrl = "";
    let queryWhere = "";
    let queryFields = ["*"];
    switch (ubigeo.length) {
      case 2:
        queryUrl = departamentoUrlPotencialMinero;
        queryWhere = "CD_DEPA = '" + ubigeo + "'";
        break;
      case 4:
        queryUrl = provinciaUrlPotencialMinero;
        queryWhere = "CD_PROV = '" + ubigeo + "'";
        break;

      case 6:
        queryUrl = distritoUrlPotencialMinero;
        queryWhere = "CD_DIST = '" + ubigeo + "'";
        break;
    }

    executeQueryJSON
      .call(null, queryUrl, {
        where: queryWhere,
        outFields: queryFields,
        returnGeometry: true,
      })
      .then(
        function (results) {
          view?.graphics.removeAll();
          const data = results.features;
          data.forEach((e) => {
            if (e.geometry != null) {
              const graphic = new Graphic({
                geometry: e.geometry,
                symbol: querySymbol,
              });

              view.graphics.add(graphic);
              view.goTo(graphic.geometry.extent).catch((error: any) => {
                console.error(error);
              });
            }
          });
        },
        function (error) {
          console.log(error);
        }
      );
  }

  function fnConsultarCapas(geom: any) {
    const tipoPotencialMinero = searching.type ?? "M";
    let elementos = arrCapas.filter(
      (element) => element["tipo"].indexOf(tipoPotencialMinero) > -1
    );

    console.log(tipoPotencialMinero);
    let contador = 0;
    const total = elementos.length;
    elementos.forEach((e: any) => {
      contador++;
      let dataSource = new CustomDataSource(view, e.capa);
      dataSource.setFields(e.layout);
      dataSource.setUrlQuery(e.url);
      dataSource.setQueryGeom(geom);
      if (contador == 1) {
        dataSource.Search(true, true);
      } else {
        dataSource.Search(false, false);
      }

      searching.dataSources.push(dataSource);
    });
  }

  function fnChangeTipo(value: any) {
    let tipo = "M";
    switch (value.id) {
      case "chkMetalico":
        tipo = "M";
        setMetalico(false);
        setNoMetalico(true);
        break;

      case "unChkMetalico":
        tipo = "N";
        setMetalico(true);
        setNoMetalico(false);
        break;
      case "chkNoMetalico":
        tipo = "M";
        setMetalico(true);
        setNoMetalico(false);
        break;

      case "unChkNoMetalico":
        tipo = "N";
        setMetalico(false);
        setNoMetalico(true);
        break;
    }

    searching.setType(tipo);
  }

  const GenerarData = () => {
    if(drawGraphic == null){
      showAlert({title: alertTitle,  message: "Por favor, realice un dibujo en el mapa", type: "danger", autoClose: true});
      return false;
    }

    dispatch(setRecords(0));
    searching.dataSources = [];
    searching.setExecuted(false);

    //ALERTA de procesamiento
    showAlert({title: alertTitle,
        message: "Por favor espere unos minutos. Se está generando la información solicitada",
        type: "info",
        autoClose: true
    });

    fnConsultarCapas(drawGraphic?.geometry);
  }

  const LimpiarData = () => {
    setDrawGraphic(null);
    dispatch(setRecords(0));
    view.graphics.removeAll();
  }

  return (
    <>
      <div id="divPotencialMinero">
        <div className="row pt-2">
          <CalciteLabel layout="inline">
            {t("screens.widgets.PotencialMinero.tipo")} :
            <CalciteLabel layout="inline">
              <CalciteIcon
                icon="check-square"
                id="chkMetalico"
                onClick={(e) => fnChangeTipo(e.target)}
                hidden={!chkMetalico}
              ></CalciteIcon>
              <CalciteIcon
                icon="square"
                id="unChkMetalico"
                onClick={(e) => fnChangeTipo(e.target)}
                hidden={chkMetalico}
              ></CalciteIcon>
              {t("screens.widgets.PotencialMinero.metalico")}
            </CalciteLabel>
            <CalciteLabel layout="inline">
              <CalciteIcon
                icon="check-square"
                id="chkNoMetalico"
                onClick={(e) => fnChangeTipo(e.target)}
                hidden={!chkNoMetalico}
              ></CalciteIcon>
              <CalciteIcon
                icon="square"
                id="unChkNoMetalico"
                onClick={(e) => fnChangeTipo(e.target)}
                hidden={chkNoMetalico}
              ></CalciteIcon>
              {t("screens.widgets.PotencialMinero.no_metalico")}
            </CalciteLabel>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.PotencialMinero.departamento")}
            <CalciteSelect
              label={t("screens.widgets.PotencialMinero.departamento")}
              id="lstDepartamentoPotencialMinero"
              placeholder={t("screens.widgets.seleccione")}
              // onCalciteSelectChange={(e) => fnSelectCapas(e.target.value)}
            >
              {arrDepartamentosPotencialMinero.map(
                (option: any, idx: number) => {
                  return (
                    <CalciteOption key={option.id} value={option.id}>
                      {option.name}
                    </CalciteOption>
                  );
                }
              )}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel hidden={hideProvincia}>
            {t("screens.widgets.PotencialMinero.provincia")}
            <CalciteSelect
              label={t("screens.widgets.PotencialMinero.provincia")}
              id="lstProvinciaPotencialMinero"
              placeholder={t("screens.widgets.seleccione")}
              // onCalciteSelectChange={(e) => fnSelectCapas(e.target.value)}
            >
              {arrProvinciasPotencialMinero.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={option.id} value={option.id}>
                    {option.name}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel hidden={hideDistrito}>
            {t("screens.widgets.PotencialMinero.distrito")}
            <CalciteSelect
              label={t("screens.widgets.PotencialMinero.distrito")}
              id="lstDistritoPotencialMinero"
              placeholder={t("screens.widgets.seleccione")}
              // onCalciteSelectChange={(e) => fnSelectCapas(e.target.value)}
            >
              {arrDistritosPotencialMinero.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={option.id} value={option.id}>
                    {option.name}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.PotencialMinero.area")}
            <div id="sketch-container-potencialminero"></div>
          </CalciteLabel>
        </div>
        <div style={{ display: "inline" }}>
        <CalciteButton
          iconStart="tables"
          label={t("screens.widgets.PotencialMinero.generar")}
          style={{ padding: "3px" }}
          onClick={() => GenerarData()}
        >
          {t("screens.widgets.PotencialMinero.generar")}{" "}
        </CalciteButton>
        <CalciteButton
          iconStart="refresh"
          label={t("screens.widgets.PotencialMinero.limpiar")}
          style={{ padding: "3px" }}
          onClick={() => LimpiarData()}
        >
          {t("screens.widgets.PotencialMinero.limpiar")}
        </CalciteButton>
      </div>
      </div>
    </>
  );
}
