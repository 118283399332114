import React, { useEffect, useState, useRef } from "react";
import {
  CalciteSelect,
  CalciteButton,
  CalciteOption,
  CalciteInput,
  CalciteCheckbox,
  CalciteLabel,
  CalciteInputNumber,
} from "@esri/calcite-components-react";

import { useTranslation } from "react-i18next";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { CatastroMinero } from "./CatastroMinero";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import { QueryField } from "../../core/interfaces/QueryField";
import configConsultaGeologica from "./configConsultaGeologica.json";
import esriRequest from "@arcgis/core/request";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import * as geoprocessor from "@arcgis/core/rest/geoprocessor.js";
import JobInfo from "@arcgis/core/rest/support/JobInfo";
import ParameterValue from "@arcgis/core/rest/support/ParameterValue";

// import { ShowCustomAlert } from "../ShowCustomAlert";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { useAlert } from "../../core/providers/AlertProvider";

export function ConsultaGeologica(props: any) {
  const { t } = useTranslation();
  let view = props.view;
  let catastro: CatastroMinero = props.catastro;

  let arrFields: QueryField[] = [];

  const [dataDescarga, setDataDescarga] = useState<any[]>([]);
  let arrDataDescarga: never[] = [];
  const [txtNotifyCG, setTxtNotifyCG] = useState("");
  const [txtDistancia, setTxtDistancia] = useState(0);
  const [areaQuery, setAreaQuery] = useState(0);
  const showAlert = useAlert();
  const [hideCamposConsultaGeologica, setHideCamposConsultaGeologica] =
    useState(true);
  const [
    hideCamposValorConsultaGeologica,
    setHideCamposValorConsultaGeologica,
  ] = useState(true);
  const [
    hideHerramientasConsultaGeologica,
    setHideHerramientasConsultaGeologica,
  ] = useState(true);
  const [hideDistanciaConsultaGeologica, setHideDistanciaConsultaGeologica] =
    useState(true);

  const [hideBuscarConsultaGeologica, setHideBuscarConsultaGeologica] =
    useState(true);

  const arrVacio = [{ id: "", name: "-- Seleccione --" }];
  const [arrCapasConsultaGeologica, setArrCapasConsultaGeologica] = useState(
    []
  );

  const [disabledEjecutar, setDisabledEjecutar] = useState(true);

  const [arrCamposConsultaGeologica, setArrarrCamposConsultaGeologica] =
    useState([]);
  const [arrValoresConsultaGeologica, setArrValoresConsultaGeologica] =
    useState([]);

  const arrCapasCG: never[] = [];
  let arrCapasCG_T: any[];
  let arrCamposCG: any[];

  //const mapRef = useRef(null);
  const featureLayerRef = useRef(null);

  const symbolPoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [255, 0, 0],
      width: 1,
    },
  };

  const symbolLine = {
    color: [255, 0, 0, 11, 0.75],
    width: 1,
  };
  const symbolPoly = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.5],
    style: "solid",
    outline: symbolLine,
  };
  const symbolLineBufffer = {
    color: [255, 0, 0, 0],
    width: 1,
  };
  const symbolPolyBuffer = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: symbolLineBufffer,
  };
  useEffect(() => {
    let json_graphic: any;
    function loadInitConsultaGeologica() {
      const arrCapasCG_T = configConsultaGeologica.layer;

      arrCapasCG.push(arrVacio[0] as never);

      arrCapasCG_T.forEach((e) => {
        arrCapasCG.push({
          id: e.id,
          name: e.name,
        } as never);
      });

      setArrCapasConsultaGeologica(arrCapasCG as never);
      /***************************** */
      const graphicsLayerConsultaGeologica = new GraphicsLayer();
      const sketchConsultaGeologica = new Sketch({
        layer: graphicsLayerConsultaGeologica,
        creationMode: "single",
        defaultUpdateOptions: {
          tool: "move",
        },
      });
      sketchConsultaGeologica.visibleElements = {
        createTools: {
          point: false,
          polyline: false,
          //   circle: false,
        },
        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
        settingsMenu: false,
        undoRedoMenu: false,
      };
      sketchConsultaGeologica.view = view;
      sketchConsultaGeologica.container = "sketch-container-ConsultaGeologica";
      sketchConsultaGeologica.on("create", function (event) {
        if (event.state === "complete") {
          graphicsLayerConsultaGeologica.remove(event.graphic);

          //selectFeatures(event.graphic.geometry);

          view.graphics.add(event.graphic);
          sketchConsultaGeologica.complete();

          fnGraficarBufferConsultaGeologica(event.graphic.geometry);
        }
      });
    }
    let geomQuery = new Polygon();
    function fnGraficarBufferConsultaGeologica(geometry: any) {
      switch (geometry.type) {
        case "point":
          fnGraficarConsultaGeologica(geometry as Point, symbolPoint);
          break;
        case "polyline":
          fnGraficarConsultaGeologica(geometry as Polyline, symbolLine);

          break;
        case "polygon":
          fnGraficarConsultaGeologica(geometry as Polygon, symbolPoly);

          break;
      }

      /******************************************** */
    }
    function fnGraficarConsultaGeologica(geomQuery: any, symbol: any) {
      view.graphics.removeAll();
      btnDescargarConsultaGeologica.disabled = true;
      const txtDistanciaConsultaGeologica = document.getElementById(
        "txtDistanciaConsultaGeologica"
      ) as HTMLSelectElement;

      const geomQueryIn = new Graphic({
        geometry: geomQuery,
        symbol: symbol,
      });
      //console.log(buffer);
      //const cmbBufferTemp=;
      const bufferGeom = geometryEngine.geodesicBuffer(
        geomQuery,
        Number(txtDistanciaConsultaGeologica.value),
        cmbBuffer.value as any //          "kilometers"
      );
      const geomQueryOut = new Graphic({
        geometry: bufferGeom as Polygon,
        symbol: symbolPolyBuffer,
      });

      view.graphics.add(geomQueryIn);
      view.graphics.add(geomQueryOut);
      //setGeomQuery(geomQueryOut.geometry);
      //geomQuery = geomQueryOut.geometry as Polygon;
      json_graphic = geomQueryOut;
      fnDescargarConsultaGeologica(geomQueryOut.geometry);
    }
    const calculateGeodesicArea = async () => {
      let result: any;
      try {
        result = await geometryEngineAsync.geodesicArea(
          json_graphic.geometry,
          "hectares"
        );
        setAreaQuery(result);
        console.log(result);
      } catch (error: any) {
        //console.error("Error calculating geodesic area:", error);
        setTxtNotifyCG(t("screens.menu.searching.consultageologica"));
        // showAlert({
        //   title: t("screens.menu.searching.consultageologica"),
        //   message: error.message,
        //   type: "danger",
        //   autoClose: true,
        // });
      }
      if (result <= 1000000) {
        const gpServiceUrl = configConsultaGeologica.serviceUrl; //fnGetUrl();

        const params = {
          input_jsonService: json_graphic.geometry.toJSON(), //,inputjson,
          input_queryEntity: lstCapasConsultaGeologica.value, //queryentity,
          input_query:
            lstCamposConsultaGeologica.value +
            "= '" +
            lstCamposValorConsultaGeologica.value +
            "'",
        };

        try {
          // const jobInfo: JobInfo = await geoprocessor.submitJob(
          //   gpServiceUrl,
          //   params
          // );

          const jobInfo = geoprocessor
            .execute(gpServiceUrl, params)
            .then(drawResultData);

          // await jobInfo.waitForJobCompletion({
          //   statusCallback: checkJobStatus,
          // });

          // const result: ParameterValue = await jobInfo.fetchResultData(
          //   "output_file"
          // );

          //console.log(result.value.toJSON().url);
          console.log(result);
        } catch (error: any) {
          setTxtNotifyCG(error.message);
          // showAlert({
          //   title: t("screens.menu.searching.consultageologica"),
          //   message: error.message,
          //   type: "info",
          //   autoClose: true,
          // });
        }
      } else {
        setTxtNotifyCG(t("screens.widgets.ConsultaGeologica.superaarea"));
        // showAlert({
        //   title: t("screens.menu.searching.consultageologica"),
        //   message: t("screens.widgets.ConsultaGeologica.superaarea"),
        //   type: "danger",
        //   autoClose: true,
        // });
        /*MostrarAlerta(
          t("screens.menu.searching.consultageologica"),
          t("screens.widgets.ConsultaGeologica.superaarea"),
          "danger",
          true
        );*/
        // console.log("El gráfico realizado supera el valor de área permitido");
        // setMessageAlert(
        //   "El gráfico realizado supera el valor de área permitido"
        // );
        // setTypeAlert("warning");
        // setShowAlert(true);
      }
    };
    function drawResultData(result: any) {
      setTxtNotifyCG(t("screens.mensajes.descargaArchivo"));

      arrDataDescarga.push({
        archivo: result.results[1].value.url,
      } as never);

      setDataDescarga(arrDataDescarga);
      //console.log(result.results[1].value.url);

      // showAlert({
      //   title: t("screens.menu.searching.consultageologica"),
      //   message: t("screens.mensajes.descargaArchivo"),
      //   type: "info",
      //   autoClose: true,
      // });
      window.open(result.results[1].value.url);
    }
    function checkJobStatus(jobInfo: JobInfo): void {
      console.log(jobInfo);
    }
    /********************************** */

    function fnDescargarConsultaGeologica(geomQuery: any) {
      setTxtNotifyCG(t("screens.mensajes.procesando"));
      // showAlert({
      //   title: t("screens.menu.searching.consultageologica"),
      //   message: t("screens.mensajes.procesando"),
      //   type: "info",
      //   autoClose: true,
      // });

      setDisabledEjecutar(false);
      calculateGeodesicArea();
      // if (areaQuery <= 10000) {
      //   console.log(areaQuery);
      //   const serviceUrl = fnGetUrl();
      //   var params = {
      //     input_jsonService: inputjson,
      //     input_queryEntity: queryentity,
      //     input_query: sql,
      //   };
      //   Geoprocessor.submitJob(serviceUrl, params).then((jobInfo) => {
      //     const options = {
      //       statusCallback: (jobInfo1) => {
      //         progTest(jobInfo1);
      //       },
      //     };
      //     // once the job completes, add resulting layer to map
      //     jobInfo.waitForJobCompletion(options).then((jobInfo2) => {
      //       drawResultData(jobInfo2);
      //     });
      //   });
      // }
    }

    const lstCapasConsultaGeologica = document.getElementById(
      "lstCapasConsultaGeologica"
    ) as HTMLSelectElement;

    const lstCamposConsultaGeologica = document.getElementById(
      "lstCamposConsultaGeologica"
    ) as HTMLSelectElement;
    const lstCamposValorConsultaGeologica = document.getElementById(
      "lstCamposValorConsultaGeologica"
    ) as HTMLSelectElement;
    const btnBuscarGeologia = document.getElementById(
      "btnBuscarGeologia"
    ) as HTMLSelectElement;
    const btnDescargarConsultaGeologica = document.getElementById(
      "btnDescargarConsultaGeologica"
    ) as HTMLSelectElement;

    const chkDistanciaConsultaGeologica = document.getElementById(
      "chkDistanciaConsultaGeologica"
    ) as HTMLSelectElement;

    const cmbBuffer = document.getElementById("cmbBuffer") as HTMLSelectElement;

    // const MostrarPopup1 = () => {
    //   const modal: any = document.getElementById("userCustomModalHTML");
    //   modal.open = true;
    // };
    // chkDistanciaConsultaGeologica.addEventListener(
    //   "calciteCheckboxChange",
    //   () => {
    //     fnCheckbox();
    //   }
    // );

    btnBuscarGeologia.addEventListener("click", () => {
      fnMostrarCapas();
    });
    btnDescargarConsultaGeologica.addEventListener("click", () => {
      console.log("fnDescargar");
      fnDescargarConsultaGeologica(geomQuery);
    });
    lstCapasConsultaGeologica.addEventListener("calciteSelectChange", () => {
      fnSelectCapas();
    });
    lstCamposConsultaGeologica.addEventListener("calciteSelectChange", () => {
      fnSelectCamposConsultaGeologica();
    });
    lstCamposValorConsultaGeologica.addEventListener(
      "calciteSelectChange",
      () => {
        //console.log(lstCamposValorConsultaGeologica.value);

        fnMostrarCapas();
      }
    );

    function fnMostrarCapas() {
      setHideHerramientasConsultaGeologica(false);
      setHideBuscarConsultaGeologica(false);

      if (featureLayerRef.current) {
        view.map.remove(featureLayerRef.current);
      }
      const featureLayerUrl = fnGetUrl();
      const queryWhere =
        lstCamposConsultaGeologica.value +
        "= '" +
        lstCamposValorConsultaGeologica.value +
        "'";
      // const featureLayerUrl =
      //   "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_GEOLOGIA_100K_INTEGRADA/MapServer/";

      // Create a FeatureLayer and add it to the WebMap.
      const featureLayer = new FeatureLayer({
        url: featureLayerUrl,
      });

      featureLayer.title =
        "Consulta Geológica_" + lstCapasConsultaGeologica.value;
      featureLayer.visible = true;
      featureLayer.maxScale = 0;
      featureLayer.minScale = 0;

      featureLayer.definitionExpression = queryWhere;
      featureLayerRef.current = featureLayer as any;
      view.map.add(featureLayer);

      catastro.setVisible(false);
      catastro.setUrlQuery(featureLayerUrl);
      catastro.SetQueryWhere(queryWhere);
      catastro.setFields(arrFields);
      catastro.BuscarCatastro();
    }
    function fnGetUrl() {
      const arrCapasCG_T = configConsultaGeologica.layer;
      const arrCapasFilter = arrCapasCG_T.filter(
        (capa) => capa.id == lstCapasConsultaGeologica.value
      );
      const serviceUrl = arrCapasFilter[0].url;
      return serviceUrl;
    }

    async function fnSelectCapas() {
      console.log("fnSelectCapas");
      setArrarrCamposConsultaGeologica([]);
      setArrValoresConsultaGeologica([]);

      const serviceUrl = fnGetUrl();

      const queryParams = {
        where: "1=1", // Modify the where clause as needed
        outFields: "*",
        f: "json", // You can change the output format if needed
      };

      const requestOptions = {
        query: queryParams,
        // headers: customHeaders
      };

      // Send the query request
      esriRequest(serviceUrl, requestOptions)
        .then((response) => {
          // Handle the response data here
          setHideCamposConsultaGeologica(false);
          setHideCamposValorConsultaGeologica(true);
          setHideHerramientasConsultaGeologica(true);
          setHideBuscarConsultaGeologica(true);

          let cadena = "OBJECTID GLOBALID SHAPE SHAPE.LEN SHAPE.AREA";
          arrCamposCG = response.data.fields;
          const camposFilter = arrCamposCG.filter(
            (field) => cadena.indexOf(field.name) == -1
          );
          const arrData: never[] = [];
          arrData.push(arrVacio[0] as never);
          camposFilter.forEach((e) => {
            arrData.push({
              id: e.name,
              name: e.name,
            } as never);
          });

          setArrarrCamposConsultaGeologica(arrData as never);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Query Error:", error);
        });
    }
    const titulo = t("screens.menu.searching.consultageologica");
    const cargando = t("screens.home.loading");

    function fnSelectCamposConsultaGeologica() {
      //mensaje

      console.log(titulo);

      //ShowCustomAlert();
      //MostrarAlerta("titulo as string", "cargando as string", "info", true);
      const queryUrl = fnGetUrl();
      const queryWhere = "1=1";
      const queryFields = lstCamposConsultaGeologica.value as string;

      executeQueryJSON
        .call(null, queryUrl, {
          where: queryWhere,
          outFields: [queryFields],
          returnGeometry: false,
          returnDistinctValues: true,
          orderByFields: [queryFields],
        })
        .then(
          function (results) {
            setHideCamposValorConsultaGeologica(false);
            setHideHerramientasConsultaGeologica(true);
            setHideBuscarConsultaGeologica(true);

            const data = results.features;
            const arrData: never[] = [];
            arrData.push(arrVacio[0] as never);
            data.forEach((e) => {
              arrData.push({
                id: e.attributes[queryFields],
                name: e.attributes[queryFields],
              } as never);
            });

            setArrValoresConsultaGeologica(arrData as never);
          },
          function (error) {
            console.log(error);
          }
        );
    }

    function fnBuscarGeologia() {
      console.log("fnBuscarGeologia");
      fnMostrarCapas();
    }

    loadInitConsultaGeologica();
  }, []);

  function fnVerManual() {
    const modal: any = document.getElementById("modalManualConsultaGeologica");
    modal.open = true;
  }

  // function fnSelectCapas(value: any) {

  // }

  function fnSelectCampos(value: any) {
    console.log("fnSelectCampos");
  }

  function fnSelectValorCampo(value: any) {
    console.log("fnSelectValorCampo");
  }

  function fnCheckboxChange(value: any) {
    setHideDistanciaConsultaGeologica(!value);
  }

  function fnAplicarBuffer() {
    console.log("fnAplicarBuffer");
  }

  return (
    <>
      <div id="divConsultaGeologica">
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.ConsultaGeologica.permite")}
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.ConsultaGeologica.capas_consulta")}

            <CalciteSelect
              label={t("screens.widgets.ConsultaGeologica.capas_consulta")}
              id="lstCapasConsultaGeologica"
            >
              {arrCapasConsultaGeologica.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={idx} value={option.id}>
                    {option.name}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel hidden={hideCamposConsultaGeologica}>
            {t("screens.widgets.ConsultaGeologica.campo_consulta")}
            <div className="row py-2">
              <div className="col-12 col-md d-flex">
                <CalciteSelect
                  label={t("screens.widgets.ConsultaGeologica.campo_consulta")}
                  id="lstCamposConsultaGeologica"
                  // onCalciteSelectChange={(e) => fnSelectCampos(e.target.value)}
                >
                  {arrCamposConsultaGeologica.map(
                    (option: any, idx: number) => {
                      return (
                        <CalciteOption key={idx} value={option.name}>
                          {option.name}
                        </CalciteOption>
                      );
                    }
                  )}
                </CalciteSelect>
              </div>
              <div className="col-12 col-md d-flex">
                <CalciteSelect
                  hidden={hideCamposValorConsultaGeologica}
                  label={t("screens.widgets.ConsultaGeologica.campo_consulta")}
                  id="lstCamposValorConsultaGeologica"
                  // onCalciteSelectChange={(e) =>fnSelectValorCampo(e.target.value)}
                >
                  {/* <CalciteOption value="--" selected>
                  {t("screens.widgets.seleccione")}
                </CalciteOption> */}
                  {arrValoresConsultaGeologica.map(
                    (option: any, idx: number) => {
                      return (
                        <CalciteOption key={idx} value={option.id}>
                          {option.name}
                        </CalciteOption>
                      );
                    }
                  )}
                </CalciteSelect>
              </div>
            </div>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <div className="col-12 col-md d-flex">
            {/* <CalciteButton
              iconStart="open-book"
              label={t("screens.widgets.ConsultaGeologica.ver_manual")}
              onClick={() => fnVerManual()}
              className="ms-2"
            >
              {t("screens.widgets.ConsultaGeologica.ver_manual")}
              <CustomPopupPDF
                      title="Modal de prueba"
                      url="https://hal.science/hal-03913837v1/preview/ChatGPT.pdf"
                      id="modalManualConsultaGeologica"
                  ></CustomPopupPDF>
            </CalciteButton> */}
          </div>
          <div className="col-12 col-md d-flex">
            <CalciteButton
              iconStart="search"
              label={t("screens.widgets.searchdm.btnsearch")}
              id="btnBuscarGeologia"
              hidden={hideBuscarConsultaGeologica}
              // onClick={() => fnBuscarGeologia()}
              width="full"
              className="ms-2"
            >
              {t("screens.widgets.searchdm.btnsearch")}
            </CalciteButton>
          </div>
        </div>

        <CalciteLabel hidden={hideHerramientasConsultaGeologica}>
          <div className="row py-2">
            <CalciteLabel>
              {t("screens.widgets.ConsultaGeologica.seleccione_herramienta")}
              <div id="sketch-container-ConsultaGeologica"></div>
            </CalciteLabel>
          </div>
          <div className="row py-2">
            {/* check */}
            <CalciteLabel layout="inline">
              <CalciteCheckbox
                id="chkDistanciaConsultaGeologica"
                scale="m"
                onCalciteCheckboxChange={(e) =>
                  fnCheckboxChange(e.target.checked)
                }
              ></CalciteCheckbox>
              {t("screens.widgets.ConsultaGeologica.aplicar_distancia")}
            </CalciteLabel>
          </div>
          {/* controles */}
          <CalciteLabel hidden={hideDistanciaConsultaGeologica}>
            <div className="row py-2">
              <div className="col-8 col-md d-flex">
                <CalciteInputNumber
                  // placeholder={t("screens.widgets.searchdm.codeph")}
                  alignment="end"
                  value="0"
                  id="txtDistanciaConsultaGeologica"
                ></CalciteInputNumber>
              </div>
              <div className="col-12 col-md d-flex">
                <CalciteSelect id="cmbBuffer" label="Buffer" width="full">
                  <CalciteOption value="kilometers" selected>
                    Kilómetros
                  </CalciteOption>
                  <CalciteOption value="meters">Metros</CalciteOption>
                  <CalciteOption value="feet">Pies</CalciteOption>
                  <CalciteOption value="miles">Millas</CalciteOption>
                  <CalciteOption value="yards">Yardas</CalciteOption>
                </CalciteSelect>
              </div>
              <div className="col-4 col-md d-flex">
                <CalciteButton
                  iconStart="rings-largest"
                  onClick={() => fnAplicarBuffer()}
                  className="ms-2"
                ></CalciteButton>
              </div>
            </div>
          </CalciteLabel>
          <CalciteButton
            iconStart="map-level-settings"
            id="btnDescargarConsultaGeologica"
            disabled={disabledEjecutar}
            // onClick={() => fnDescargar()}
            label={t("screens.widgets.ConsultaGeologica.ejecutar")}
          >
            Ejecutar
          </CalciteButton>
        </CalciteLabel>
        {
          <span
            className="file-upload-status"
            style={{ opacity: "1" }}
            id="consultaGeologica-status"
          >
            <p style={{ color: "red", paddingTop: "8px" }}>{txtNotifyCG}</p>

            <ul>
              {dataDescarga?.map((item: any, indexrow: number) => (
                <li>
                  <a href={item.archivo} target="blank">
                    {indexrow + 1}. descargar{" "}
                  </a>
                </li>
              ))}
            </ul>
          </span>
        }
      </div>
    </>
  );
}
