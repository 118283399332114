import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalciteOption,
  CalciteSelect,
} from "@esri/calcite-components-react";
import { CustomFunctions } from "../../utils/CustomFunctions";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import { useAlert } from "../../core/providers/AlertProvider";

export function LocationCoords(view: any) {
  const utils = new CustomFunctions();
  const { t } = useTranslation();
  const showAlert = useAlert();
  
  const [cmbWkid, setCmmbWkid] = useState("4326");
  const [txtCoordX, setTxtCoordX] = useState("0.00");
  const [txtCoordY, setTxtCoordY] = useState("0.00");

  const clearForm = () => {
    setCmmbWkid("");
    setTxtCoordX("0.00");
    setTxtCoordY("0.00");
    view?.view?.graphics.removeAll();
  };


  const alertTitle = "Ubicación de coordenadas";
  const submitForm = () => {
    if (txtCoordX == "") {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorcx"),
        type: "danger",
        autoClose: true
      });
      return false;
    }
    if (txtCoordY == "") {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorcy"),
        type: "danger",
        autoClose: true
      });
      return false;
    }
    if (isNaN(Number(txtCoordX))) {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errornx"),
        type: "danger",
        autoClose: true
      });
      return false;
    } else {
      if (parseFloat(txtCoordX) == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errorn0"),
          type: "danger",
          autoClose: true
        });
        return false;
      }
    }
    if (isNaN(Number(txtCoordY))) {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorny"),
        type: "danger",
        autoClose: true
      });
      return false;
    } else {
      if (parseFloat(txtCoordY) == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errorn0"),
          type: "danger",
          autoClose: true
        });
        return false;
      }
    }

    let coordX = parseFloat(txtCoordX);
    let coordY = parseFloat(txtCoordY);
    const selWkid = parseInt(cmbWkid);
    if (selWkid != 4326) {
      var convert = utils.ConvertCoord(coordX, coordY, selWkid);
      if (convert.length == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errornc"),
          type: "danger",
          autoClose: true
        });
        return false;
      } else {
        coordX = convert[0];
        coordY = convert[1];
      }
    }

    const markerSymbol = {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      color: [226, 119, 40],
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: [255, 255, 255],
        width: 2,
      },
    };

    const locPoint = new Point({
      x: coordX,
      y: coordY,
      spatialReference: { wkid: 4326 },
    });
    const graphic = new Graphic({
      geometry: locPoint,
      symbol: markerSymbol,
    });
    view?.view?.graphics.add(graphic);
    view?.view
      .goTo({
        center: [coordX, coordY],
        zoom: 13,
      })
      .catch(function (error: any) {
        if (error.name != "AbortError") {
          console.error(error);
        }
      });
  };

  return (
    <>
    <div className="row pt-2">
      <CalciteLabel>
        {t("screens.widgets.location.coordinates")}
        <CalciteSelect
          label={t("screens.widgets.location.coordinates")}
          onCalciteSelectChange={(e) => setCmmbWkid(e.target.value)}
        >
          <CalciteOption value="4326" selected={true}>
            Geographic Lat/Lon WGS84
          </CalciteOption>
          <CalciteOption value="32717">WGS84 UTM Zone 17S</CalciteOption>
          <CalciteOption value="32718">WGS84 UTM Zone 18S</CalciteOption>
          <CalciteOption value="32719">WGS84 UTM Zone 19S</CalciteOption>
          <CalciteOption value="24877">PSAD UTM Zone 17S</CalciteOption>
          <CalciteOption value="24878">PSAD UTM Zone 18S</CalciteOption>
          <CalciteOption value="24879">PSAD UTM Zone 19S</CalciteOption>
        </CalciteSelect>
      </CalciteLabel>
    </div>
    <div className="row pt-2">
      <CalciteLabel>
        {t("screens.widgets.location.coordx")}
        <CalciteInput
          type="number"
          placeholder="0.0"
          value={txtCoordX}
          onCalciteInputChange={(e) => setTxtCoordX(e.target.value)}
        ></CalciteInput>
      </CalciteLabel>
    </div>
    <div className="row pt-2">
      <CalciteLabel>
        {t("screens.widgets.location.coordy")}
        <CalciteInput
          type="number"
          placeholder="0.0"
          value={txtCoordY}
          onCalciteInputChange={(e) => setTxtCoordY(e.target.value)}
        ></CalciteInput>
      </CalciteLabel>
      </div>
      <div className="row py-2">
        <div className="col-12 col-md d-flex">
          <CalciteButton
            iconStart="reset"
            label={t("screens.widgets.location.btnclean")}
            onClick={() => clearForm()}
            appearance="transparent"
          >
            {t("screens.widgets.location.btnclean")}
          </CalciteButton>
        </div>
        <div className="col-12 col-md d-flex">
          <CalciteButton
            iconStart="pin-tear"
            label={t("screens.widgets.location.btnsubmit")}
            onClick={() => submitForm()}
          >
            {t("screens.widgets.location.btnsubmit")}
          </CalciteButton>
        </div>
      </div>
    </>
  );
}
