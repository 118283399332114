import {
  CalciteTable,
  CalciteTableCell,
  CalciteTableHeader,
  CalciteTableRow,
} from "@esri/calcite-components-react";
import { useEffect, useState } from "react";
import { QueryField } from "../core/interfaces/QueryField";
import { CustomDataSource } from "./CustomDataSource";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import Extent from "@arcgis/core/geometry/Extent";
import Graphic from "@arcgis/core/Graphic";
import { useTranslation } from "react-i18next";
import { useUserActionsSelector } from "../redux/hooks/userActionsHook";

export function CustomDataTable(props: any) {
  const { t } = useTranslation();
  let view = props.view;
  let dataSource: CustomDataSource = props.dataSource;
  const { records } = useUserActionsSelector();

  const [fields, setFields] = useState<QueryField[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [cols, setCols] = useState<number>(0);
  const [rows, setRows] = useState<number>(0);
  const [coords, setCoords] = useState<any[]>([]);

  let cusFields: QueryField[] = [];

  const symbolLine = {
    color: [0, 255, 255, 11, 0.75],
    width: 1,
  };

  const symbolPoly = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [0, 255, 255, 0.25],
    style: "solid",
    outline: symbolLine,
  };

  const simplePoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [0, 255, 255],
      width: 1,
    },
  };

  useEffect(() => {
    if (dataSource != null) {
      if (dataSource.fields.length > 0) {
        //&& dataSource.executed
        //Solo campos visibles
        cusFields = [];
        dataSource.fields.forEach((e) => {
          if (e.visible) {
            cusFields.push(e);
          }
        });

        setFields(cusFields);
        setData(dataSource.result);
        setCols(cusFields.length);
        setRows(dataSource.result.length);
        setCoords(dataSource.coords);
        //dataSource.setExecuted(false);
      }
    }
  }, [props, records]);

  function GotoMap(objectid: number) {
    let querySymbol: any;
    executeQueryJSON
      .call(null, dataSource.urlQuery, {
        where: "OBJECTID = " + objectid,
        outFields: ["*"],
        returnGeometry: true,
      })
      .then(
        function (results) {
          let extent = new Extent();

          //INICIO - Eliminando graficos seleccionados anteriormente
          const selectedObjs: any[] = [];
          view.graphics.items.forEach((e: any) => {
            if (e.attributes != null) {
              if (e.attributes["id"] != null) {
                if (e.attributes.id == "selectedObject") {
                  selectedObjs.push(e);
                }
              }
            }
          });
          selectedObjs.forEach((e: any) => {
            view.graphics.remove(e);
          });
          //FIN - Eliminando graficos seleccionados anteriormente

          const data = results.features;
          if (data[0].geometry != null) {
            switch (data[0].geometry.type) {
              case "point":
                querySymbol = simplePoint;
                break;
              case "polyline":
                querySymbol = symbolPoly;
                break;
              case "polygon":
                querySymbol = symbolPoly;
                break;
            }
          }

          data.forEach((e) => {
            const graphic = new Graphic({
              geometry: e.geometry,
              symbol: querySymbol,
              attributes: { id: "selectedObject" },
            });
            let extentTemp;
            if (e.geometry.type === "point") {
              extentTemp = dataSource.ExtentPoint(e.geometry);
            } else {
              extentTemp = e.geometry.extent;
            }

            view.graphics.add(graphic);
            extent = extentTemp;
          });

          view.goTo(extent.expand(1.5));
        },
        function (error) {
          console.log(error);
        }
      );
  }

  const toDate = (timestamp: number) => {
    if (timestamp == null || timestamp == 0) {
      return "";
    }
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
  };

  function getCellContent(type: string, value: any, title: string, url: any) {
    if (type == "date") {
      return toDate(value);
    }
    if (type == "link") {
      if (url != undefined && url != null) {
        const link = url + value;
        return (
          <a href={link} target="_blank">
            {title}
          </a>
        );
      } else {
        return (
          <a href={value} target="_blank">
            Ir
          </a>
        );
      }
    } else {
      return value;
    }
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="esri-widget custom-widget-table">
        <thead>
          <tr>
            <th className="custom-widget-table-header">N°</th>
            {cols > 0 &&
              fields?.map((item: any, index: number) => (
                <th
                  key={index}
                  className="custom-widget-table-header"
                  style={{ width: `${item.width}` }}
                >
                  {item.title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="custom-widget-table-row">
          {rows > 0 && cols > 0 ? (
            data?.map((item: any, indexrow: number) => (
              <tr
                key={indexrow}
                onClick={() => GotoMap(coords[indexrow].OBJECTID)}
              >
                <td
                  className="custom-widget-table-cell"
                  style={{ textAlign: "center" }}
                >
                  {indexrow + 1}
                </td>
                {fields.map((cell: QueryField, indexcell: number) => (
                  <td
                    className="custom-widget-table-cell"
                    key={indexcell}
                    style={{ textAlign: `${cell.align}` }}
                  >
                    {cell.nowrap ? (
                      <div style={{ whiteSpace: "nowrap" }}>
                        {getCellContent(
                          cell.datatype,
                          item[cell.field],
                          cell.title,
                          cell.url
                        )}
                      </div>
                    ) : (
                      <div>
                        {getCellContent(
                          cell.datatype,
                          item[cell.field],
                          cell.title,
                          cell.url
                        )}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className="custom-widget-table-cell" colSpan={cols + 1}>
                {t("screens.widgets.tableresult.notfound")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
