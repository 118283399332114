import React, { useEffect, useState } from "react";
import SpatialReference from "@arcgis/core/geometry/SpatialReference.js";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import Color from "@arcgis/core/Color.js";
import Point from "@arcgis/core/geometry/Point";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import { CustomMap } from "../../components/CustomMap";
import { CustomMap } from "../components/CustomMap";

export class CustomFunctions {
  simpleMarkerSymbol() {
    return new SimpleMarkerSymbol({
      angle: 0,
      color: new Color([255, 0, 0, 1]),
      outline: new SimpleLineSymbol({
        cap: "round",
        color: new Color([255, 0, 0, 1]),
        join: "round",
        miterLimit: 1,
        style: "solid",
        width: 1,
      }),
      path: "undefined",
      size: 12,
      style: "x",
      xoffset: 0,
      yoffset: 0,
    });
  }

  ConvertCoord(pCoordX: number, pCoordY: number, pOutSR: number) {
    var outpoint: number[] = [];
    switch (pOutSR) {
      case 32717:
        outpoint = this.ReturnLong_LatDD("WGS84", 17, "S", pCoordX, pCoordY);
        break;
      case 32718:
        outpoint = this.ReturnLong_LatDD("WGS84", 18, "S", pCoordX, pCoordY);
        break;
      case 32719:
        outpoint = this.ReturnLong_LatDD("WGS84", 19, "S", pCoordX, pCoordY);
        break;
      case 24877:
        outpoint = this.ReturnLong_LatDD("PSAD56", 17, "S", pCoordX, pCoordY);
        break;
      case 24878:
        outpoint = this.ReturnLong_LatDD("PSAD56", 18, "S", pCoordX, pCoordY);
        break;
      case 24879:
        outpoint = this.ReturnLong_LatDD("PSAD56", 19, "S", pCoordX, pCoordY);
        break;
      case 4326:
        var coord = [];
        coord.push(pCoordX);
        coord.push(pCoordY);
        outpoint = coord;
        break;
      default:
        outpoint = [];
    }

    return outpoint;
  }

  private ReturnLong_LatDD(
    DATUM: string,
    ZONE_UTM: number,
    hem_utm: string,
    E_UTM: number,
    N_UTM: number
  ) {
    var coord: number[] = [];
    /*CONVERT UTM EAST/NORTH (METER) COORDINATES TO LONGITUDE/LATITUDE DECIMAL DEGREES*/
    var XM, YM, X, Y, LONGDD, LATDD;
    var ZONE, CM;
    var A, b, MO;
    var KO, N1, D;
    var T1, EP2, E;
    var M, N;
    var E1, MU, C1, R1;
    var NO, PIEVAR;
    var phi0;

    var LAT1, LAT2, LAT3, LAT4, LAT5, LAT6;
    var LAT7, LAT8, LAT9;
    var LONG1, LONG2;

    /*DEFINE EAST/NORTHING VARABLES FROM FIELD EARLIER FILLED IN*/
    XM = E_UTM;
    YM = N_UTM;
    ZONE = ZONE_UTM;

    PIEVAR = 180 / 3.14159265358979;

    if (DATUM === "GKA") {
      /*Gauss Kruger Argentina 3degree Transverse Mercader*/
      A = 63783888;
      b = 6356911.94612795;
      CM = -75 + 3 * ZONE;
      X = XM - (ZONE * 1000000 + 500000);
      Y = YM;
      KO = 1;

      /*MO TEST*/
      E = Math.pow(1 - Math.pow(b, 2) / Math.pow(A, 2), 0.5);
      phi0 = -90 / PIEVAR;
      MO =
        (1 -
          Math.pow(E, 2) / 4 -
          (3 * Math.pow(E, 4)) / 64 -
          (5 * Math.pow(E, 6)) / 256) *
        phi0;
      MO =
        MO -
        ((3 * Math.pow(E, 2)) / 8 +
          (3 * Math.pow(E, 4)) / 32 +
          (45 * Math.pow(E, 6)) / 1024) *
          Math.sin(2 * phi0);
      MO =
        MO +
        ((15 * Math.pow(E, 4)) / 256 + (45 * Math.pow(E, 6)) / 1024) *
          Math.sin(4 * phi0);
      MO = MO - ((35 * Math.pow(E, 6)) / 3072) * Math.sin(6 * phi0);
      MO = MO * A;
    } else {
      /*UTM PROJECTIONS*/
      //DEFINE VARABLES
      CM = -183 + 6 * ZONE;
      X = XM - 500000;
      if (hem_utm === "S") {
        Y = YM - 10000000;
      } else {
        Y = YM;
      }

      KO = 0.9996;

      /*CHECK THE DATUM TO ASSIGN THE CORRECT SPHERIOD*/

      if (DATUM === "PSAD56") {
        /*PROVOVISIONAL SOUTH AMERICA 1956 INTERNATIONAL 1959*/
        A = 6378388;
        b = 6356911.94612795;
      } else if (DATUM === "WGS84") {
        A = 6378137;
        b = 6356752.3;
      } else if (DATUM === "GRS67") {
        /*GRS_67 (IN SOME INSTANCES GRS_67 IS THE SAD69 ELLIPSOID)*/
        A = 6378160;
        b = 6356774.51609071;
      } else if (DATUM === "SAD69") {
        /*SAD69 ELLIPSOID OR INTERNATIONAL 67*/
        A = 6378160;
        b = 6356774.71919531;
      } else if (DATUM === "COALEG") {
        /* CORREGO ALEGRE SYSTEM*/
        A = 6378388;
        b = 6356911.94612795;
      } else {
        alert("Ingrese un DATUM: WGS84, PSAD56, GRS_67, SAD69 o COALEG!");
        return [];
      }

      MO = 0;
    }

    /*DO CALCULATIONS*/
    E = Math.pow(1 - Math.pow(b, 2) / Math.pow(A, 2), 0.5);
    E1 =
      (1 - Math.pow(1 - Math.pow(E, 2), 0.5)) /
      (1 + Math.pow(1 - Math.pow(E, 2), 0.5));
    EP2 = Math.pow(E, 2) / (1 - Math.pow(E, 2));
    M = MO + Y / KO;
    MU =
      M /
      (A *
        (1 -
          Math.pow(E, 2) / 4 -
          (3 * Math.pow(E, 4)) / 64 -
          (5 * Math.pow(E, 6)) / 256));

    N =
      MU +
      ((3 * E1) / 2 - (27 * Math.pow(E1, 3)) / 32) * Math.sin(2 * MU) +
      ((21 * Math.pow(E1, 2)) / 16 - (55 * Math.pow(E1, 4)) / 32) *
        Math.sin(4 * MU) +
      ((151 * Math.pow(E1, 3)) / 96) * Math.sin(6 * MU) +
      ((1097 * Math.pow(E1, 4)) / 512) * Math.sin(8 * MU);
    C1 = EP2 * Math.pow(Math.cos(N), 2);

    N1 = A / Math.pow(1 - Math.pow(E, 2) * Math.pow(Math.sin(N), 2), 0.5);
    R1 =
      (A * (1 - Math.pow(E, 2))) /
      Math.pow(1 - Math.pow(E, 2) * Math.pow(Math.sin(N), 2), 1.5);
    D = X / (N1 * KO);
    T1 = Math.pow(Math.tan(N), 2);

    NO = N * PIEVAR;
    LAT1 = (N1 * Math.tan(N)) / R1;
    LAT2 = Math.pow(D, 2) / 2;
    LAT3 = 5 + 3 * T1 + 10 * C1 - 4 * Math.pow(C1, 2) - 9 * EP2;
    LAT4 = Math.pow(D, 4) / 24;
    LAT5 =
      61 +
      90 * T1 +
      298 * C1 +
      45 * Math.pow(T1, 2) -
      252 * EP2 -
      3 * Math.pow(C1, 2);
    LAT6 = Math.pow(D, 6) / 720;
    LAT7 = LAT1 * (LAT2 - LAT3 * LAT4 + LAT5 * LAT6);
    LAT8 = LAT7 * PIEVAR;
    LATDD = NO - LAT8;

    LONG1 =
      (D -
        ((1 + 2 * T1 + C1) * Math.pow(D, 3)) / 6 +
        ((5 -
          2 * C1 +
          28 * T1 -
          3 * Math.pow(C1, 2) +
          8 * Math.pow(EP2, 2) +
          24 * Math.pow(T1, 2)) *
          Math.pow(D, 5)) /
          120) /
      Math.cos(N);
    LONG2 = LONG1 * PIEVAR;
    LONGDD = CM + LONG2;

    coord.push(LONGDD);
    coord.push(LATDD);
    return coord;
  }

  getSpatialReference(wkid: number) {
    return new SpatialReference({
      wkid: wkid,
    });
  }

  randomNumberInRange(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  loadLayer(view: any, capa: any, subCapa: any, queryWhere: string) {
    /***** Ubica layer Catastro *****/
    let layerEncontrado = new FeatureLayer();
    try {
      const foundLayer = view.map.allLayers.find(function (layer: any) {
        //console.log(layer.title);
        try {
          if (layer.title.toUpperCase() === capa.toUpperCase()) {
            layer.sublayers.forEach((e: any) => {
              if (e.title.toUpperCase() == subCapa.toUpperCase()) {
                layerEncontrado = e;
                if (queryWhere.length > 0) {
                  layerEncontrado.definitionExpression = queryWhere;
                }

                return;
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
      //loadLayer();
    }
  }

  toDate(timestamp: number) {
    if (timestamp == null || timestamp == 0) {
      return "";
    }
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
  }
  goto(view: any, pointGraphic: any) {
    console.log(view.type);

    view
      .goTo({
        target: pointGraphic,
        zoom: 12,
        tilt: 0,
        heading: 0,
      })
      .catch(function (error: any) {
        console.error(error);
      });
  }
  goToLocation(view: any, x: number, y: number, z: number) {
    view.goTo({
      target: new Point({
        x: x,
        y: y,
        z: z,
      }),
      tilt: 0,
      heading: 0,
      zoom: 15,
    });
  }
  async loadconfig(archivo: string) {
    return fetch(`${process.env.PUBLIC_URL}/config/` + archivo + `.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => console.error(error));
  }
}
