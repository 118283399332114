import React from "react";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainNavigator from "./navigation/MainNavigator";
import IntroScreen from "./screens/intro/IntroScreen";
import MainPage from "./screens/home/MainPage";
import { AlertProvider } from "./core/providers/AlertProvider";

function App() {
  return (
    <Provider store={store}>
      <AlertProvider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<MainNavigator />}>
                <Route index element={<MainPage />} /> */}
              <Route path="/geocatmin" element={<MainNavigator />}>
                <Route index element={<IntroScreen />} />
                <Route path="main" element={<MainPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </AlertProvider>
    </Provider>
  );
}

export default App;
