import React, { useEffect, useState, useRef } from "react";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
} from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";

import configConsultaAvanzada from "./configConsultasAvanzadas.json";
import { MainSearching } from "./MainSearching";
import { CustomDataSource } from "../CustomDataSource";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import { useAlert } from "../../core/providers/AlertProvider";

export function ConsultasAvanzadas(props: any) {
  const { t } = useTranslation();
  const showAlert = useAlert();
  
  let searching: MainSearching = props.data;
  const view = searching.view;
  const dispatch = useAppDispatch();

  let arrCapasCA: any[];
  const [arrCapasConsultaAvanzada, setArrCapasConsultaAvanzada] = useState([]);
  const [arrOpcionConsultaAvanzada_01, setArrOpcionConsultaAvanzada_01] =
    useState([]);
  const [arrOpcionConsultaAvanzada_02, setArrOpcionConsultaAvanzada_02] =
    useState([]);
  const [arrOpcionConsultaAvanzada_03, setArrOpcionConsultaAvanzada_03] =
    useState([]);

  const arrVacio = [{ valor: "0", label: "-- Seleccione --" }];

  const [hideCombo01, setHideCombo01] = useState(true);
  const [hideCombo02, setHideCombo02] = useState(true);
  const [hideCombo03, setHideCombo03] = useState(true);

  const [lblCombo01, setLblCombo01] = useState(true);
  const [lblCombo02, setLblCombo02] = useState(true);
  const [lblCombo03, setLblCombo03] = useState(true);

  useEffect(() => {
    async function loadInitConsultasAvanzadas() {
      arrCapasCA = configConsultaAvanzada.queries;
      //console.log(arrCapasCA);

      const arrDataTemp: never[] = [];
      arrDataTemp.push(arrVacio[0] as never);
      arrCapasCA.forEach((e) => {
        arrDataTemp.push({
          valor: e.name,
          label: e.name,
        } as never);
      });
      setArrCapasConsultaAvanzada(arrDataTemp as never);
    }

    const cmbCapasCA = document.getElementById(
      "cmbCapasCA"
    ) as HTMLSelectElement;
    const lstOpcion01 = document.getElementById(
      "lstOpcion01"
    ) as HTMLSelectElement;
    const lstOpcion02 = document.getElementById(
      "lstOpcion02"
    ) as HTMLSelectElement;
    const lstOpcion03 = document.getElementById(
      "lstOpcion03"
    ) as HTMLSelectElement;

    cmbCapasCA.addEventListener("calciteSelectChange", () => {
      // console.log(cmbCapasCA.value);
      // console.log(arrCapasCA);
      setHideCombo01(true);
      setHideCombo02(true);
      setHideCombo03(true);
      if (cmbCapasCA.value.length > 1) {
        setHideCombo01(true);
        setHideCombo02(true);
        setHideCombo03(true);
        const arrDataFilter = arrCapasCA.filter(
          (capa) => capa.name === cmbCapasCA.value
        );
        //console.log(arrDataFilter[0].url);
        //console.log(arrDataFilter[0].filter[0]);
        setLblCombo01(arrDataFilter[0].filter[0].label);
        fnBuscarDatos(
          arrDataFilter[0].url,
          "1=1" + arrDataFilter[0].whereAdicional,
          arrDataFilter[0].filter[0].name,
          "lstOpcion01",
          arrDataFilter[0].fields,
          arrDataFilter[0].name
        );
      }
    });

    lstOpcion01.addEventListener("calciteSelectChange", () => {
      setHideCombo02(true);
      setHideCombo03(true);
      const arrDataFilter = arrCapasCA.filter(
        (capa) => capa.name === cmbCapasCA.value
      );
      fnMostarDatos(
        arrDataFilter[0].name,
        arrDataFilter[0].fields,
        arrDataFilter[0].url,
        arrDataFilter[0].filter[0].name +
          " = '" +
          lstOpcion01.value +
          "'" +
          arrDataFilter[0].whereAdicional
      );
      if (lstOpcion01.value.length > 1) {
        //console.log(arrDataFilter[0].url);
        //console.log(arrDataFilter[0].filter[0]);
        if (arrDataFilter[0].filter.length > 1) {
          setLblCombo02(arrDataFilter[0].filter[1].label);
          fnBuscarDatos(
            arrDataFilter[0].url,
            arrDataFilter[0].filter[0].name +
              " = '" +
              lstOpcion01.value +
              "'" +
              arrDataFilter[0].whereAdicional,
            arrDataFilter[0].filter[1].name,
            "lstOpcion02",
            arrDataFilter[0].fields,
            arrDataFilter[0].name
          );
        }
      }
    });
    lstOpcion02.addEventListener("calciteSelectChange", () => {
      console.log("lstOpcion02");
      setHideCombo03(true);
      const arrDataFilter = arrCapasCA.filter(
        (capa) => capa.name === cmbCapasCA.value
      );
      fnMostarDatos(
        arrDataFilter[0].name,
        arrDataFilter[0].fields,
        arrDataFilter[0].url,
        arrDataFilter[0].filter[0].name +
          " = '" +
          lstOpcion01.value +
          "' and " +
          arrDataFilter[0].filter[1].name +
          " = '" +
          lstOpcion02.value +
          "'" +
          arrDataFilter[0].whereAdicional
      );
      if (lstOpcion02.value.length > 1) {
        if (arrDataFilter[0].filter.length > 2) {
          setLblCombo03(arrDataFilter[0].filter[2].label);
          fnBuscarDatos(
            arrDataFilter[0].url,
            arrDataFilter[0].filter[0].name +
              " = '" +
              lstOpcion01.value +
              "' and " +
              arrDataFilter[0].filter[1].name +
              " = '" +
              lstOpcion02.value +
              "'" +
              arrDataFilter[0].whereAdicional,
            arrDataFilter[0].filter[2].name,
            "lstOpcion03",
            arrDataFilter[0].fields,
            arrDataFilter[0].name
          );
        }
      }
    });
    lstOpcion03.addEventListener("calciteSelectChange", () => {
      console.log("lstOpcion03");
      fnLimpiarBusqueda();
      const arrDataFilter = arrCapasCA.filter(
        (capa) => capa.name === cmbCapasCA.value
      );
      fnMostarDatos(
        arrDataFilter[0].name,
        arrDataFilter[0].fields,
        arrDataFilter[0].url,
        arrDataFilter[0].filter[0].name +
          " = '" +
          lstOpcion01.value +
          "' and " +
          arrDataFilter[0].filter[1].name +
          " = '" +
          lstOpcion02.value +
          "' and " +
          arrDataFilter[0].filter[2].name +
          " = '" +
          lstOpcion03.value +
          "'" +
          arrDataFilter[0].whereAdicional
      );
    });

    function fnBuscarDatos(
      queryUrl: any,
      queryWhere: any,
      queryFields: any,
      combo: string,
      campos: any,
      capa: any
    ) {
      fnLimpiarBusqueda();

      executeQueryJSON
        .call(null, queryUrl, {
          //geometry: this.queryGeom,
          where: queryWhere,
          spatialRelationship: "intersects",
          outFields: [queryFields],
          returnDistinctValues: true,
          returnGeometry: false,
          orderByFields: queryFields,
          //returnCentroid: true,
        })
        .then(
          (results) => {
            //console.log(results);
            const data = results.features;
            const arrData: never[] = [];
            arrData.push(arrVacio[0] as never);
            data.forEach((e) => {
              arrData.push({
                valor: e.attributes[queryFields],
                label: e.attributes[queryFields],
              } as never);
            });

            switch (combo) {
              case "lstOpcion01":
                setArrOpcionConsultaAvanzada_01(arrData);
                setHideCombo01(false);
                break;
              case "lstOpcion02":
                setArrOpcionConsultaAvanzada_02(arrData);
                setHideCombo02(false);
                break;
              case "lstOpcion03":
                setArrOpcionConsultaAvanzada_03(arrData);
                setHideCombo03(false);
                break;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    function fnLimpiarBusqueda() {
      dispatch(setRecords(0));
      searching.dataSources = [];
      searching.setExecuted(false);
    }
    function fnMostarDatos(capa: any, campos: any, url: any, queryWhere: any) {
      console.log(capa);
      console.log(campos);
      console.log(url);
      console.log(queryWhere);
      let dataSource = new CustomDataSource(view, capa);
      dataSource.setFields(campos);
      dataSource.setUrlQuery(url);
      dataSource.setQueryWhere(queryWhere);
      //dataSource.SetQueryGeom(geom);

      dataSource.Search(true, true);

      searching.dataSources.push(dataSource);
    }

    loadInitConsultasAvanzadas();
  }, []);

  return (
    <>
      <div id="divConsultasAvanzadas">
        <div className="row py-2">
          <CalciteLabel>
            {t("screens.widgets.mapacalor.capas")}
            <CalciteSelect
              label={t("screens.widgets.mapacalor.capas")}
              id="cmbCapasCA"
            >
              {arrCapasConsultaAvanzada.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={idx} value={option.valor}>
                    {option.label}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <CalciteLabel hidden={hideCombo01}>
            {lblCombo01}
            <CalciteSelect label="Opcion_1" id="lstOpcion01">
              {arrOpcionConsultaAvanzada_01.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={idx} value={option.valor}>
                    {option.label}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <CalciteLabel hidden={hideCombo02}>
            {lblCombo02}
            <CalciteSelect label="Opcion_2" id="lstOpcion02">
              {arrOpcionConsultaAvanzada_02.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={idx} value={option.valor}>
                    {option.label}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <CalciteLabel hidden={hideCombo03}>
            {lblCombo03}
            <CalciteSelect label="Opcion_3" id="lstOpcion03">
              {arrOpcionConsultaAvanzada_03.map((option: any, idx: number) => {
                return (
                  <CalciteOption key={idx} value={option.valor}>
                    {option.label}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
      </div>
    </>
  );
}
