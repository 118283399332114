import { useEffect, useState } from "react";
import Measurement from "@arcgis/core/widgets/Measurement";
import { useTranslation } from "react-i18next";

export function Measure(view: any) {
  const { t } = useTranslation();
  const [btnLineActive, setBtnLineActive] = useState("");
  const [btnAreaActive, setBtnAreaActive] = useState("");

  const measurement = new Measurement();
  measurement.view = view?.view;
  view?.view?.ui.add(measurement, "bottom-left");

  const clearMeasurements = () => {
    setBtnLineActive("");
    setBtnAreaActive("");

    //ELIMINANDO LAYERS DE MEDICION
    const deleteLayers: any[] = [];
    view?.view.map.layers.forEach((item: any) => {
      if (item.internal) {
        deleteLayers.push(item);
      }
    });
    deleteLayers.forEach((e) => {
      view?.view.map.remove(e);
    });
    view?.view.ui.empty("bottom-left");
    measurement.clear();
    measurement.set({ activeTool: "" });
  };

  const distanceMeasurement = () => {
    measurement.activeTool =
      view?.view?.type.toUpperCase() === "2D" ? "distance" : "direct-line";
    setBtnLineActive("active");
    setBtnAreaActive("");
  };

  const areaMeasurement = () => {
    measurement.activeTool = "area";
    setBtnLineActive("");
    setBtnAreaActive("active");
  };

  return (
    <div
      style={{
        margin: "10px",
        cursor: "default",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
      }}
    >
      <button
        id="btnMeaDistance"
        className={`esri-widget--button esri-interactive esri-icon-measure-line ${btnLineActive}`}
        title={t("screens.widgets.measure.distance")}
        onClick={() => distanceMeasurement()}
      ></button>
      <button
        id="btnMeaArea"
        className={`esri-widget--button esri-interactive esri-icon-measure-area ${btnAreaActive}`}
        title={t("screens.widgets.measure.area")}
        onClick={() => areaMeasurement()}
      ></button>
      <button
        id="btnMeaClear"
        className={`esri-widget--button esri-interactive esri-icon-trash`}
        onClick={() => clearMeasurements()}
        title={t("screens.widgets.measure.clear")}
      ></button>
    </div>
  );
}
