import { CalciteButton, CalciteIcon,
    CalciteInput, CalciteLabel,
    CalciteModal, CalciteOption,
    CalciteSelect, CalciteSwitch } from "@esri/calcite-components-react";
import { MainSearching } from "./widgets/MainSearching";
import React, { useEffect, useState } from "react";
import { useUserActionsSelector } from "../redux/hooks/userActionsHook";
import { QueryField } from "../core/interfaces/QueryField";
import { QueryFilter } from "../core/interfaces/QueryFilter";
import { CustomDataSource } from "./CustomDataSource";
import { useAlert } from "../core/providers/AlertProvider";

export function CustomPopupFilter(props: any) {
    const id = props.id;
    let searching: MainSearching = props.data;
    const { tableName } = useUserActionsSelector();
    const showAlert = useAlert();

    const [filterExtent, setFilterExtent] = useState<boolean>(false);
    const [inputFields, setInputFields] = useState<QueryFilter[]>([]);
    const [fields, setFields] = useState<QueryField[]>([]);
    let cusFields: QueryField[] = [];

    const AplicarFiltros = (e: any) => {
        const popup: any = document.getElementById(id);
        popup.open = false;
        let selectedIndex: number = -1;

        searching.dataSources?.map((item: CustomDataSource, index:number) => {
            if(item.name == tableName){
                selectedIndex = index;
            }
        });

        e.preventDefault();
        //RECORRIENDO LOS FILTROS
        let queryFilter = "";
        inputFields.forEach((e, idx) => {
            if(e.field !== "" && e.operator !== "" && e.value !== ""){
                let query = "";
                switch(e.operator){
                    case "equals":
                        query = "UPPER(" + e.field + ") = UPPER('" + e.value + "')";
                        break;
                    case "different":
                        query = "UPPER(" + e.field + ") <> UPPER('" + e.value + "')";
                        break;
                    case "startwith":
                        query = "UPPER(" + e.field + ") LIKE UPPER('" + e.value + "%')";
                        break;
                    case "endwith":
                        query = "UPPER(" + e.field + ") LIKE UPPER('%" + e.value + "')";
                        break;
                    case "contains":
                        query = "UPPER(" + e.field + ") LIKE UPPER('%" + e.value + "%')";
                        break;
                    case "nocontains":
                        query = "UPPER(" + e.field + ") NOT LIKE UPPER('%" + e.value + "%')";
                        break;
                    case "major":
                        query = e.field + " > " + e.value;
                        break;
                    case "minor":
                        query = e.field + " < " + e.value;
                        break;
                    case "majorequals":
                        query = e.field + " >= " + e.value;
                        break;
                    case "minorequals":
                        query = e.field + " <= " + e.value;
                        break;
                    default:
                        query = ""
                        break;
                }
                if(query !== ""){
                    if(idx > 0){
                        queryFilter = queryFilter + " AND " + query;
                    }
                    else{
                        queryFilter = query;
                    }
                }
            }
        });

        if(selectedIndex >= 0){
            //ASIGNANDO FILTRO A CAPA
            if(queryFilter !== ""){
                searching.dataSources[selectedIndex].setQueryFilter(queryFilter);
                searching.dataSources[selectedIndex].Search(true, true);
                searching.setExecuted(false);
            }

            if(filterExtent){
                searching.dataSources[selectedIndex].setFilterExtent(true);
            }else{
                searching.dataSources[selectedIndex].setFilterExtent(false);
            }   
        }
    }

    const CerrarPopup = () => {
        const popup: any = document.getElementById(id);
        popup.open = false;
    }

    const AddFilter = () => {
        let newfield = { field: '', operator: '', value: '' };
        setInputFields([...inputFields, newfield]);
    }

    const RemoveFilter = (index: number) => {
        if(inputFields.length == 1){
            showAlert({title: "Eliminar filtro", message: "No se puede eliminar el único filtro", type: "danger", autoClose: true});
            return;
        }

        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    }

    const setFilterField = (index: number, value: string) => {
        let data = [...inputFields];
        data[index]["field"] = value;
        setInputFields(data);
     }

     const setFilterOperator = (index: number, value: string) => {
        let data = [...inputFields];
        data[index]["operator"] = value;
        setInputFields(data);
     }

     const setFilterValue = (index: number, value: string) => {
        let data = [...inputFields];
        data[index]["value"] = value;
        setInputFields(data);
     }

     const ChangeFilterExtent = (value: boolean) =>{
        if(value){
            setFilterExtent(true);
            setInputFields([{ field: '', operator: '', value: '' }]);
        }else{
            setFilterExtent(false);
        }
     }

    useEffect(() => {
        setInputFields([{ field: '', operator: '', value: '' }]);

        searching.dataSources?.map((item: any, index:number) => {
            if(item.name == tableName){
                cusFields = [];
                item.fields.forEach((e: any) => {
                    if (e.visible) {
                    cusFields.push(e);
                    }
                });
                setFields(cusFields);
                setFilterExtent(item.filterExtent);
            }
        });

    }, [props]);

    return (
        <CalciteModal
          aria-labelledby="modal-title"
          id={id}
          kind="success"
          scale="s"
          width="m"
        >
          <div slot="header" id="modal-title">
            Filtro
          </div>
          <div slot="content">
            <CalciteLabel layout="inline">
                Aplicar filtro por extensión
                { filterExtent ?
                    <CalciteSwitch onCalciteSwitchChange={(e) => ChangeFilterExtent(e.target.checked)} checked></CalciteSwitch>
                    :
                    <CalciteSwitch onCalciteSwitchChange={(e) => ChangeFilterExtent(e.target.checked)}></CalciteSwitch>
                }
            </CalciteLabel>
            <form onSubmit={AplicarFiltros}>
                {inputFields.map((item, index) => {
                    return(
                        <div className="row pt-3" key={index} id={"filtergroup-" + index.toString()}>
                            <div className="col-12 col-md-3 pe-md-1 mb-3">
                            {filterExtent ?
                                <CalciteSelect disabled label="Elija un campo" name={"field" + index.toString()} id={"field" + index.toString()} value={item.field}>
                                        <CalciteOption value="" key="0">--Seleccione uno--</CalciteOption>
                                </CalciteSelect>
                                :
                                <CalciteSelect key={index} label="Elija un campo" name={"field" + index.toString()} id={"field" + index.toString()} value={item.field}
                                    onCalciteSelectChange={(e)=> setFilterField(index, e.target.value)}
                                    >
                                        <CalciteOption value="" key="0" selected>--Seleccione uno--</CalciteOption>
                                    { fields.map((field: QueryField, idx) => { return (
                                        <CalciteOption value={field.field} key={idx}>{field.field}</CalciteOption>
                                    )}) }
                                </CalciteSelect>
                            }
                            </div>
                            <div className="col-12 col-md-3 px-md-1 mb-3">
                            {filterExtent ?
                                <CalciteSelect disabled label="Elija un operador" name={"operator" + index.toString()} id={"operator" + index.toString()} value={item.operator}>
                                    <CalciteOption value="">--Seleccione uno--</CalciteOption>
                                </CalciteSelect>
                                :
                                <CalciteSelect key={index} label="Elija un operador" name={"operator" + index.toString()} id={"operator" + index.toString()} value={item.operator}
                                    onCalciteSelectChange={(e)=> setFilterOperator(index, e.target.value)}>
                                    <CalciteOption value="" selected>--Seleccione uno--</CalciteOption>
                                    <CalciteOption value="equals">es</CalciteOption>
                                    <CalciteOption value="different">no es</CalciteOption>
                                    <CalciteOption value="startwith">comienza por</CalciteOption>
                                    <CalciteOption value="endwith">termina en</CalciteOption>
                                    <CalciteOption value="contains">contiene</CalciteOption>
                                    <CalciteOption value="nocontains">no contiene</CalciteOption>
                                    <CalciteOption value="major">es mayor que</CalciteOption>
                                    <CalciteOption value="majorequals">es mayor o igual que</CalciteOption>
                                    <CalciteOption value="minor">es menor que</CalciteOption>
                                    <CalciteOption value="minorequals">es menor o igual que</CalciteOption>
                                </CalciteSelect>
                            }
                            </div>
                            <div className="col-12 col-md-4 ps-md-1 mb-3">
                                <CalciteInput key={index} disabled={filterExtent} name={"value" + index.toString()} value={item.value} onCalciteInputChange={(e)=> setFilterValue(index, e.target.value)}></CalciteInput>
                            </div>
                            <div className="col-12 col-md-2 ps-md-1 mb-3">   
                                <CalciteLabel layout="inline">
                                    <CalciteButton iconStart="plus" kind="neutral" disabled={filterExtent} onClick={(e) => AddFilter()}/>
                                    <CalciteButton iconStart="x" kind="neutral" disabled={filterExtent} onClick={(e) => RemoveFilter(index)}/>
                                </CalciteLabel>
                            </div>
                        </div>
                    )
                })}
                <div className="row py-2">
                    <div className="col-12 d-flex justify-content-end">
                        <CalciteLabel layout="inline">
                            <CalciteButton kind="neutral" onClick={AplicarFiltros}>Aceptar</CalciteButton>
                            <CalciteButton kind="neutral" onClick={(e) => CerrarPopup()}>Cancelar</CalciteButton>
                        </CalciteLabel>
                    </div>
                </div>
            </form>
          </div>
        </CalciteModal>
      );
}
