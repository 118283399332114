import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
  CalciteInput,
  CalciteTab,
  CalciteTabTitle,
  CalciteButton,
  CalciteTabNav,
} from "@esri/calcite-components-react";
import { CustomFunctions } from "../../utils/CustomFunctions";
import { CatastroMinero } from "./CatastroMinero";
import configProyectosMineros from "./ConfigProyectosMineros.json";
import { MainSearching } from "./MainSearching";
import { CustomDataSource } from "../CustomDataSource";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";

export function ProyectosMineros(props: any) {
  const { t } = useTranslation();
  const utils = new CustomFunctions();
  let searching: MainSearching = props.data;
  const view = searching.view;
  const dispatch = useAppDispatch();

  // const [urlCapaPM, setUrlCapaPM] = useState("");
  //const [arrFields, setArrCampos] = useState([]);
  const [txtCriterio, setTxtCriterio] = useState("");
  const [txtCampo, setTxtCampo] = useState("NOM_PROY");

  useEffect(() => {
    const arrFields = configProyectosMineros.layout;
    const urlCapaPM = configProyectosMineros.url;
    const capa = configProyectosMineros.capa;
    const subCapa = configProyectosMineros.subcapa;
    let dataSource = new CustomDataSource(view, capa);

    const cmbCampoPM = document.getElementById(
      "cmbCampoPM"
    ) as HTMLSelectElement;

    const txtCriterio = document.getElementById(
      "txtCriterio"
    ) as HTMLSelectElement;
    const btnBuscarProyectosMineros = document.getElementById(
      "btnBuscarProyectosMineros"
    ) as HTMLSelectElement;
    btnBuscarProyectosMineros.addEventListener("click", (e) => {
      fnLimpiarBusqueda();
      if (txtCriterio == null) {
        searchProyectosMineros("1=1");
      } else {
        searchProyectosMineros("");
      }
    });
    function fnLimpiarBusqueda() {
      dispatch(setRecords(0));

      searching.dataSources = [];
      searching.setExecuted(false);
      dataSource.Clear();
    }
    cmbCampoPM.addEventListener("calciteSelectChange", (e) => {
      console.log("cmbCampoPM");
      fnChangeCampos(cmbCampoPM.value);
    });
    function fnChangeCampos(value: string) {
      fnLimpiarBusqueda();
      setTxtCampo(value);
      searchProyectosMineros("1=1");
      console.log(value);
    }
    function searchProyectosMineros(queryWhere: string) {
      fnLimpiarBusqueda();
      //console.log("Busqueda de Proyectos");

      if (queryWhere.length == 0) {
        queryWhere =
          "upper(" +
          cmbCampoPM.value +
          ") like upper('%" +
          txtCriterio.value +
          "%')";
      }

      dataSource.setFields(arrFields);
      dataSource.setUrlQuery(urlCapaPM);
      dataSource.setQueryWhere(queryWhere);
      //dataSource.SetQueryGeom(geom);
      dataSource.Search(true, true);
      searching.dataSources.push(dataSource);

      /*
      resultadoPM = utils.doQuery(
        view,
        urlCapaPM,
        "upper(" + txtCampo + ") like upper('%" + txtCriterio + "%')",
        ["*"]
      );*/

      //console.log(resultadoPM);
    }
    async function onOpen() {
      // setArrCampos(configProyectosMineros.layout as any);
      // setUrlCapaPM(configProyectosMineros.ulr);
      //searchProyectosMineros("1=1");
      //console.log("Proyectos Mineros");
      //console.log(cmbCampoPM.value);
      searchProyectosMineros("1=1");
    }

    onOpen();
  });

  let resultadoPM: any;
  const clearProyectosMineros = () => {
    setTxtCriterio("");

    view.graphics.removeAll();
    //resultadoPM = utils.doQuery(view, urlCapaPM, "1=1", ["*"]);
  };

  // function fnKeyEnterProyectosMineros(event: any) {
  //   //console.log(event);
  //   if (event.keyCode == 13) {
  //     searchProyectosMineros("");
  //   }
  // }

  return (
    <div id="ProyectosMineros">
      <>
        <CalciteLabel>
          {t("screens.widgets.proyectosmineros.campos")}
          <CalciteSelect
            id="cmbCampoPM"
            label={t("screens.widgets.proyectosmineros.campos")}
            // onCalciteSelectChange={(e) => fnChangeCampos(e.target.value)}
          >
            <CalciteOption value="NOM_PROY">Nombre</CalciteOption>
            <CalciteOption value="EMP_LOCAL">Empresa</CalciteOption>
            <CalciteOption value="M_PRED">Mineral</CalciteOption>
          </CalciteSelect>
        </CalciteLabel>

        <CalciteLabel>
          {t("screens.widgets.proyectosmineros.criterio")}
          <CalciteInput
            placeholder={t("screens.widgets.proyectosmineros.criterio")}
            type="text"
            id="txtCriterio"
            //value={txtCriterio}
            // onKeyUp={(e) => fnKeyEnterProyectosMineros(e)}
            onCalciteInputChange={(e) => setTxtCriterio(e.target.value)}
          ></CalciteInput>
        </CalciteLabel>

        <div style={{ display: "inline" }}>
          <CalciteButton
            iconStart="reset"
            label={t("screens.widgets.searchdm.btnclean")}
            style={{ padding: "3px" }}
            onClick={() => clearProyectosMineros()}
          >
            {t("screens.widgets.searchdm.btnclean")}{" "}
          </CalciteButton>
          <CalciteButton
            id="btnBuscarProyectosMineros"
            iconStart="search"
            label={t("screens.widgets.searchdm.btnsearch")}
            style={{ padding: "3px" }}
            // onClick={() => searchProyectosMineros("")}
          >
            {t("screens.widgets.searchdm.btnsearch")}
          </CalciteButton>
        </div>
      </>
    </div>
  );
}
