import { createSlice } from "@reduxjs/toolkit";
import { userActions } from "../types/userActions";

const initialState: userActions = {
    showTable: false,
    records: 0,
    tableName: "",
    startIni: true,
  };

  export const userActionsSlice = createSlice({
    name: "userActions",
    initialState,
    reducers: {
        setShowTable: (state, param) => {
            const { payload } = param;
            state.showTable = payload;
        },
        setRecords: (state, param) => {
            const { payload } = param;
            state.records = payload;
        },
        setSelectedTable: (state, param) => {
          const { payload } = param;
          state.tableName = payload;
        },
        setStartIni: (state, param) => {
          const { payload } = param;
          state.startIni = payload;
        }
    },
  });
  
  const { actions, reducer } = userActionsSlice
export const { setRecords, setShowTable, setSelectedTable, setStartIni } = actions;
  export default reducer;