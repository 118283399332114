import { CalciteLabel, CalciteModal } from "@esri/calcite-components-react";

export function CustomPopupPDF(props: any) {
  const title = props.title;
  const url = props.url;
  const id = props.id;

  return (
    <CalciteModal
      aria-labelledby="modal-title"
      id={id}
      kind="success"
      scale="s"
      width="m"
    >
      <div slot="header" id="modal-title">
        {title}
      </div>
      <div slot="content">
        <div>
          <iframe src={url} width="100%" height="500px" />
        </div>
      </div>
    </CalciteModal>
  );
}
