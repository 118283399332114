import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export class ExcelExport {
    fileType: string;
    fileExtension: string;

    public constructor(){
        this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        this.fileExtension = '.xlsx';
    }

    async exportToExcel(excelData: any, fileName: string) {
        const ws = XLSX.utils.json_to_sheet(excelData);

        //Estilo de fila cabecera
        for(const i in ws){
            if (typeof(ws[i]) != "object") continue;
            let cell = XLSX.utils.decode_cell(i);
            if(cell.r == 0){
                ws[i].s = {
                    font:{
                        name: 'Arial',
                        sz: 11,
                        bold: true,
                        color: { rgb: "FFFFFF00"}
                    },
                    fill:{
                        patternType: "solid",
                        bgColor: { rgb: "0000FF00"}
                    },
                    alignment:{
                        vertical: "center",
                        horizontal: "center"
                    }
                };
            }else{
                ws[i].s = {
                    font:{
                        name: 'Arial',
                        sz: 11
                    }
                };
            }
        }

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }
}
