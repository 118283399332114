import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import configMapaCalor from "./ConfigMapaCalor.json";
import {
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
} from "@esri/calcite-components-react";
import HeatmapRenderer from "@arcgis/core/renderers/HeatmapRenderer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

import Renderer from "@arcgis/core/renderers/Renderer";

export function MapaCalor(props: any) {
  const { t } = useTranslation();

  let view = props.view;
  let activo = props.activo;
  let widget = props.widget;

  const featureLayerRef = useRef(null);

  //let arrCapasMapaCalor: any[];
  const [arrCapasMapaCalor, setArrCapasMapaCalor] = useState([]);
  const [arrCamposMapaCalor, setArrCamposMapaCalor] = useState([]);

  const [selectedCapa, setselectedCapa] = useState("");
  const [selectedCampo, setselectedCampo] = useState("");
  const [hideCampo, setHideCampo] = useState(true);

  //const [urlCapaMapaCalor, setUrlCapaMapaCalor] = useState("");
  const [whereCapaMapaCalor, setWhereCapaMapaCalor] = useState("");
  const [campoMapaCalor, setCampoMapaCalor] = useState("");
  const [tituloMapaCalor, setTituloMapaCalor] = useState("");
  let urlCapaMapaCalor = "";
  let arrCapasMC: any[];

  let arrCampoMapaCalor: any[];
  const arrVacioMapaCalor = [{ id: "", name: "-- Seleccione --" }];

  useEffect(() => {
    const cmbCapasMC = document.getElementById(
      "cmbCapasMC"
    ) as HTMLSelectElement;
    cmbCapasMC.addEventListener("calciteSelectChange", () => {
      setselectedCapa(cmbCapasMC.value);
      setTituloMapaCalor(cmbCapasMC.value);
      const evenNumbers = arrCapasMC.filter(
        (capa) => capa.label === cmbCapasMC.value
      );
      //console.log(evenNumbers);

      urlCapaMapaCalor = evenNumbers[0].url;
      arrCampoMapaCalor = evenNumbers[0].campos;
      setCampoMapaCalor(evenNumbers[0].campo);
      setArrCamposMapaCalor(arrCampoMapaCalor as never);
      setHideCampo(false);
    });
    const cmbCamposMC = document.getElementById(
      "cmbCamposMC"
    ) as HTMLSelectElement;
    cmbCamposMC.addEventListener("calciteSelectChange", () => {
      setWhereCapaMapaCalor(cmbCamposMC.value);
      fnHeadMap();
    });

    async function loadInit() {
      arrCapasMC = configMapaCalor.tematico;
      setArrCapasMapaCalor(arrCapasMC as never);
    }

    loadInit();
  });

  function fnHeadMap() {
    //view.map.remove(layerHeadMap);
    console.log(campoMapaCalor);
    console.log(urlCapaMapaCalor);
    console.log(whereCapaMapaCalor);
    console.log("***");

    const colorStops = [
      { ratio: 0, color: "rgba(235, 237, 149, 0)" },
      { ratio: 0.4, color: "#EDB019" },
      { ratio: 0.6, color: "#D24F0B" },
      { ratio: 0.85, color: "#D21813" },
      { ratio: 0.95, color: "#BA5B6D" },
    ];

    const heatmapRenderer = new HeatmapRenderer({
      // legendOptions: {
      //   minLabel: "Few",
      //   maxLabel: "Frequent",
      // },
      field: campoMapaCalor,
      colorStops: colorStops,
      maxDensity: 0.01,
      minDensity: 0,
      //radius: 30,
    });

    //view.map.remove(layerHeadMap);
    const layerHeadMap = new FeatureLayer();
    layerHeadMap.url = urlCapaMapaCalor;
    layerHeadMap.opacity = 0.85;
    layerHeadMap.maxScale = 0;
    layerHeadMap.minScale = 10000000;
    layerHeadMap.renderer = heatmapRenderer;

    layerHeadMap.definitionExpression = whereCapaMapaCalor;
    layerHeadMap.title = tituloMapaCalor;

    if (featureLayerRef.current) {
      view.map.remove(featureLayerRef.current);
    }
    featureLayerRef.current = layerHeadMap as any;
    view.map.add(layerHeadMap);
    //view.map.add(layerHeadMap);
  }

  //loadInit(view);
  return (
    <div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.mapacalor.capas")}
          <CalciteSelect
            label={t("screens.widgets.mapacalor.capas")}
            id="cmbCapasMC"
            value={selectedCapa}
            // onCalciteSelectChange={(e) => fnChangeCapa(e.target.value)}
          >
            <CalciteOption value="--" selected>
              {t("screens.widgets.seleccione")}
            </CalciteOption>
            {arrCapasMapaCalor.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.label}>
                  {option.label}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel hidden={hideCampo}>
          {t("screens.widgets.mapacalor.campos")}
          <CalciteSelect
            label={t("screens.widgets.mapacalor.campos")}
            id="cmbCamposMC"
            // value={selectedCampo}
            // onCalciteSelectChange={(e) => fnChangeCampo(e.target.value)}
          >
            {/* <CalciteOption value="--" selected>
              {t("screens.widgets.seleccione")}
            </CalciteOption> */}
            {arrCamposMapaCalor.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.valor}>
                  {option.label}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
    </div>
  );
}
