import { useNavigate } from "react-router-dom";
import MapView from "@arcgis/core/views/MapView";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import { CustomFunctions } from "../../utils/CustomFunctions";
import SceneView from "@arcgis/core/views/SceneView";
import WebMap from "@arcgis/core/WebMap";
import CoordinateConversion from "@arcgis/core/widgets/CoordinateConversion";
import Collection from "@arcgis/core/core/Collection.js";
import esriConfig from "@arcgis/core/config";
import { ToolBarZoom } from "../../components/ToolBarZoom";
import Extent from "@arcgis/core/geometry/Extent";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import SearchSource from "@arcgis/core/widgets/Search/SearchSource";
import Graphic from "@arcgis/core/Graphic";
import query from "@arcgis/core/rest/support/Query";

import Locate from "@arcgis/core/widgets/Locate";
import {
  CalciteButton,
  CalciteOption,
  CalcitePopover,
  CalciteSelect,
  CalciteShell,
  CalcitePanel,
  CalciteAccordion,
  CalciteAccordionItem,
  CalciteList,
  CalciteListItem,
  CalciteIcon,
  CalciteLoader,
  CalciteListItemGroup,
  CalciteLabel,
  CalciteBlock,
  CalciteAction,
  CalciteNotice,
} from "@esri/calcite-components-react";
import { setAssetPath } from "@esri/calcite-components/dist/components";
import { useEffect, useRef, useState } from "react";
import { CustomMap } from "../../components/CustomMap";
import { useTranslation } from "react-i18next";
//import * as watchUtils from "@arcgis/core/core/watchUtils";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

import Point from "@arcgis/core/geometry/Point";

//Widgets por defecto
import Legend from "@arcgis/core/widgets/Legend";
import LayerList from "@arcgis/core/widgets/LayerList";
import Home from "@arcgis/core/widgets/Home";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Bookmarks from "@arcgis/core/widgets/Bookmarks";
import Print from "@arcgis/core/widgets/Print";
import Search from "@arcgis/core/widgets/Search";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import ElevationProfile from "@arcgis/core/widgets/ElevationProfile";
import Expand from "@arcgis/core/widgets/Expand";

//CUSTOM WIDGETS
import { BuscarDM } from "../../components/widgets/BuscarDM";
import { ConsultaGeologica } from "../../components/widgets/ConsultaGeologica";
import { PlanoCatastralA4 } from "../../components/widgets/PlanoCatastralA4";
import { CatastroRegion } from "../../components/widgets/CatastroRegion";
import { ProyectosMineros } from "../../components/widgets/ProyectosMineros";
import { UbicarCoordenada } from "../../components/widgets/UbicarCoordenada";
import { PreEvaluacion } from "../../components/widgets/PreEvaluacion";
import { ConsultasAvanzadas } from "../../components/widgets/ConsultasAvanzadas";
import { TransformarCoordenadas } from "../../components/widgets/TransformarCoordenadas";

import { PotencialMinero } from "../../components/widgets/PotencialMinero";
import { MapaPeligrosGeologicos } from "../../components/widgets/MapaPeligrosGeologicos";
import { AnalisisRiesgoGeologico } from "../../components/widgets/AnalisisRiesgoGeologico";
import { AnalisisExploracion } from "../../components/widgets/AnalisisExploracion";
import { MapaTematico } from "../../components/widgets/MapaTematico";
import { MapaCalor } from "../../components/widgets/MapaCalor";
import { RocaMineralIndustrial } from "../../components/widgets/RocaMineralIndustrial";
import { DescargaGeodatabase } from "../../components/widgets/DescargaGeodatabase";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import { SwitchCssMode } from "../../components/widgets/SwitchCssMode";
import { CatastroMinero } from "../../components/widgets/CatastroMinero";
import { Measure } from "../../components/widgets/Measure";
import { LocationCoords } from "../../components/widgets/LocationCoords";
import { TableResult } from "../../components/TableResult";

import "../style.css";
import logoWhite from "../../assets/images/logo-geocatmin-white.png";
import i18n from "../../i18n";
import { useUserActionsSelector } from "../../redux/hooks/userActionsHook";
import { useAppDispatch } from "../../redux/hooks";
import { ImportShapefile } from "../../components/widgets/ImportShapefile";
import { CustomPopupHTML } from "../../components/CustomPopupHTML";
import { MainSearching } from "../../components/widgets/MainSearching";
import {
  setRecords,
  setSelectedTable,
  setStartIni,
} from "../../redux/slices/userActionsSlice";
import { ListadoCapas } from "../../components/widgets/ListadoCapas";

setAssetPath("https://js.arcgis.com/calcite-components/1.8.0/assets");

const MainPage = () => {
  const { records, startIni } = useUserActionsSelector();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const mapDiv = useRef(null);
  const [viewState, setViewState] = useState<MapView | SceneView | undefined>();
  const [showMap, setShowMap] = useState(false);

  const utils = new CustomFunctions();

  const [panelVisible, setPanelVisible] = useState(true);
  const [selectedLang, setSelectedLang] = useState("en");
  const [iconLang, setIconLang] = useState("flag flag-us");
  const [displayQuerys, setDisplayQuerys] = useState(false);
  const [displayAnalysis, setDisplayAnalysis] = useState(true);
  const [selectedMode, setSelectedMode] = useState("3D");
  const [buscarCatastro, setBuscarCatastro] = useState<CatastroMinero>();
  //Objeto principal para la tabla de resultados
  const [dataSources, setDataSources] = useState<MainSearching>();

  const [activeWidgets, setActiveWidgets] = useState(false);
  const [showSearchDM, setShowSearchDM] = useState(false);
  const [showListadoCapas, setListadoCapas] = useState(false);

  const [showConsultaGeologica, setShowConsultaGeologica] = useState(false);
  const [showPlanoCatastralA4, setShowPlanoCatastralA4] = useState(false);
  const [showCatastroRegion, setShowCatastroRegion] = useState(false);
  const [showProyectosMineros, setShowProyectosMineros] = useState(false);
  const [showUbicarCoordenada, setShowUbicarCoordenada] = useState(false);
  const [showPreEvaluacion, setShowPreEvaluacion] = useState(false);
  const [showConsultasAvanzadas, setShowConsultasAvanzadas] = useState(false);
  const [showTransformarCoordenadas, setShowTransformarCoordenadas] =
    useState(false);

  const [showPotencialMinero, setShowPotencialMinero] = useState(false);
  const [showMapaPeligrosGeologicos, setShowMapaPeligrosGeologicos] =
    useState(false);
  const [showAnalisisRiesgoGeologico, setShowAnalisisRiesgoGeologico] =
    useState(false);
  const [showAnalisisExploracion, setShowAnalisisExploracion] = useState(false);
  const [showMapaTematico, setShowMapaTematico] = useState(false);
  const [showMapaCalor, setShowMapaCalor] = useState(false);
  const [showPerfilElevacion, setShowPerfilElevacion] = useState(false);
  const [showRocaMineralIndustrial, setShowRocaMineralIndustrial] =
    useState(false);
  const [showDescargaGeodatabase, setShowDescargaGeodatabase] = useState(false);

  /**** WIDGET HERRAMIENTAS ****/
  const [showLocation, setShowLocation] = useState(false);
  const [showBasemap, setShowBasemap] = useState(false);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [showSketch, setShowSketch] = useState(false);
  const [showMeasure, setShowMeasure] = useState(false);
  const [showElevation, setShowElevation] = useState(false);
  const [showImportMap, setShowImportMap] = useState(false);
  const [showOverview, setShowOverview] = useState(true);

  //CONFIGURACIÓN DEL PORTAL DE ARCGIS
  esriConfig.portalUrl = "https://geocatmin.ingemmet.gob.pe/portal"; //portal
  const webmapId =
    new URLSearchParams(window.location.search).get("webmap") ??
    "202d4b384d1541638688e9ea8570dc51"; //portal
  //"05c90f82a5ee418bbb913d42a462d1d1"; //AGOL

  const legend = new Legend();
  const basemaps = new BasemapGallery();
  const searchWidget = new Search();

  const bookmarks = new Bookmarks({
    editingEnabled: true,
  });
  ///Layer List
  // const layerList = new LayerList({
  //   selectionEnabled: true,
  // });
  const print = new Print({
    printServiceUrl:
      //"https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/GEOPROCESO/ExportWebMap/GPServer/Export%20Web%20Map",
      "https://geocatminnube.ingemmet.gob.pe/arcgis/rest/services/GEOPROCESO/ExportWebMap/GPServer/Export%20Web%20Map",
  });

  const elevationProfile = new ElevationProfile({
    profiles: [
      {
        type: "ground", // first profile line samples the ground elevation
      },
      {
        type: "view", // second profile samples the view and shows building profiles
      },
    ],
    visibleElements: {
      selectButton: false,
    },
  });
  const widgetOverview = new Expand({
    expandIcon: "view-visible",
  });
  const scaleBar = new ScaleBar();

  const changeLanguage = () => {
    if (selectedLang == "es") {
      setSelectedLang("en");
      setIconLang("flag flag-us");
    } else {
      setSelectedLang("es");
      setIconLang("flag flag-es");
    }
    i18n.changeLanguage(selectedLang);
  };

  const MostrarPanelWidgets = () => {
    if (panelVisible == true) {
      setPanelVisible(false);
    } else {
      setPanelVisible(true);
    }
  };

  const TipoWidget = (value: string) => {
    if (value == "querys") {
      setDisplayQuerys(false);
      setDisplayAnalysis(true);
    } else if (value == "analysis") {
      setDisplayQuerys(true);
      setDisplayAnalysis(false);
    } else {
      setDisplayQuerys(true);
      setDisplayAnalysis(true);
    }
  };

  const InactiveWidgets = () => {
    setShowSearchDM(false);
    setListadoCapas(false);
    setShowLocation(false);
    setShowBasemap(false);
    setShowElevation(false);
    setShowBookmarks(false);
    setShowPrint(false);
    setShowSketch(false);
    setShowMeasure(false);
    setShowImportMap(false);

    setShowConsultaGeologica(false);
    setShowPlanoCatastralA4(false);
    setShowCatastroRegion(false);
    setShowProyectosMineros(false);
    setShowUbicarCoordenada(false);
    setShowPreEvaluacion(false);
    setShowConsultasAvanzadas(false);
    setShowTransformarCoordenadas(false);

    setShowPotencialMinero(false);
    setShowMapaPeligrosGeologicos(false);
    setShowAnalisisRiesgoGeologico(false);
    setShowAnalisisExploracion(false);
    setShowMapaTematico(false);
    setShowMapaCalor(false);
    setShowPerfilElevacion(false);
    setShowRocaMineralIndustrial(false);
    setShowDescargaGeodatabase(false);

    setActiveWidgets(false);
  };

  const ActiveWidget = (name: string) => {
    InactiveWidgets();

    if (name != "-") {
      setPanelVisible(false);
    }

    if (name == "searchdm") {
      setShowSearchDM(true);
      setActiveWidgets(true);
    }
    if (name == "listadocapas") {
      setListadoCapas(true);
      setActiveWidgets(true);
    }

    if (name == "consultageologica") {
      setShowConsultaGeologica(true);
      setActiveWidgets(true);
    }
    if (name == "planocatastrala4") {
      setShowPlanoCatastralA4(true);
      setActiveWidgets(true);
    }
    if (name == "catastroregion") {
      setShowCatastroRegion(true);
      setActiveWidgets(true);
    }
    if (name == "proyectosmineros") {
      setShowProyectosMineros(true);
      setActiveWidgets(true);
    }
    if (name == "ubicarcoordenada") {
      setShowUbicarCoordenada(true);
      setActiveWidgets(true);
    }
    if (name == "preevaluacion") {
      setShowPreEvaluacion(true);
      setActiveWidgets(true);
    }
    if (name == "consultadistribucion") {
      //Abrir popup con IFRAME
      const modal: any = document.getElementById("popconsultadistribucion");
      modal.open = true;
    }
    if (name == "consultasavanzadas") {
      setShowConsultasAvanzadas(true);
      setActiveWidgets(true);
    }
    if (name == "transformarcoordenadas") {
      setShowTransformarCoordenadas(true);
      setActiveWidgets(true);
    }

    if (name == "potencialminero") {
      setShowPotencialMinero(true);
      setActiveWidgets(true);
    }
    if (name == "mapapeligrosgeologicos") {
      setShowMapaPeligrosGeologicos(true);
      setActiveWidgets(true);
    }
    if (name == "analisisriesgogeologico") {
      setShowAnalisisRiesgoGeologico(true);
      setActiveWidgets(true);
    }
    if (name == "analisisexploracion") {
      setShowAnalisisExploracion(true);
      setActiveWidgets(true);
    }
    if (name == "mapatematico") {
      setShowMapaTematico(true);
      setActiveWidgets(true);
    }
    if (name == "mapacalor") {
      setShowMapaCalor(true);
      setActiveWidgets(true);
    }
    if (name == "perfilelevacion") {
      setShowPerfilElevacion(true);
      setActiveWidgets(true);
    }
    if (name == "rocamineralindustrial") {
      setShowRocaMineralIndustrial(true);
      setActiveWidgets(true);
    }
    if (name == "descargageodatabase") {
      setShowDescargaGeodatabase(true);
      setActiveWidgets(true);
    }

    if (name == "location") {
      setShowLocation(!showLocation);
      setActiveWidgets(true);
    }
    if (name == "elevation") {
      setShowElevation(!showElevation);
      setActiveWidgets(true);
    }
    if (name == "basemap") {
      setShowBasemap(!showBasemap);
      setActiveWidgets(true);
      //cerrar widget
    }
    if (name == "bookmarks") {
      setShowBookmarks(!showBookmarks);
      setActiveWidgets(true);
    }
    if (name == "print") {
      setShowPrint(!showPrint);
      setActiveWidgets(true);
    }
    if (name == "sketch") {
      setShowSketch(!showSketch);
      setActiveWidgets(true);
    }
    if (name == "measure") {
      setShowMeasure(!showMeasure);
      setActiveWidgets(true);
    }
    if (name == "importmap") {
      setShowImportMap(!showImportMap);
      setActiveWidgets(true);
    }
  };

  /*** POPUP ACERCA DE INGEMMET ****/
  const PopupAboutOf = () => {
    const modal: any = document.getElementById("popAboutGeocatmin");
    modal.open = true;
  };

  const PopupServiciosWeb = () => {
    const modal: any = document.getElementById("popServiciosWeb");
    modal.open = true;
  };
  const featureLayerRef = useRef(null);

  const CloseWidget = (e: any) => {
    InactiveWidgets();
    /****ACTUALZIANDO ACTUAL DATA SOURCE***/
    dataSources?.dataSources.map((d, index) => {
      dataSources.dataSources[index].setFilterExtent(false);
    });
    viewState?.graphics.removeAll();
    //view.graphics.removeAll();
    utils.loadLayer(
      viewState,
      "Catastro Minero",
      "Catastro Minero",
      "estado <> 'Y'"
    );
    setDataSources(new MainSearching(dataSources?.view));
    dispatch(setRecords(0));
    dispatch(setSelectedTable(""));

    try {
      if (featureLayerRef.current) {
        dataSources?.view.map.remove(featureLayerRef.current);
      }

      const foundLayer = dataSources?.view.map.allLayers.find(function (
        layer: any
      ) {
        try {
          //console.log(layer.title);
          if (
            layer.title.toUpperCase() === "graphicsLayerResultado".toUpperCase()
          ) {
            layer.removeAll();
          }
          // layer kml, kmz, shp
          if (
            layer.title.toUpperCase() === "KML Layer".toUpperCase() ||
            layer.title.toUpperCase() === "SHP Layer".toUpperCase()
          ) {
            dataSources?.view.map.remove(layer);
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (e) {}
  };

  let graphicsLayerResultado = new GraphicsLayer();
  graphicsLayerResultado.id = "graphicsLayerResultado";
  graphicsLayerResultado.title = "graphicsLayerResultado";
  graphicsLayerResultado.listMode = "hide";
  //graphicsLayerResultado.listMode = "hide";

  let graphicsLayerZoom = new GraphicsLayer();
  graphicsLayerZoom.listMode = "hide";

  //const graphicsLayer = new GraphicsLayer();
  const sketch = new Sketch({
    layer: graphicsLayerResultado,
  });

  //viewState.add

  useEffect(() => {
    //redirecciona
    if (!startIni) {
      //navigate("https://geocatmin.ingemmet.gob.pe/geocatmin/");
      navigate("/");
    }

    if (mapDiv.current) {
      dispatch(setRecords(0));

      const webmap = new WebMap({
        portalItem: {
          id: webmapId,
        },
        ground: "world-elevation",
      });

      const extent = new Extent({
        xmin: -81.83199119546,
        ymin: -18.824816351589998,
        xmax: -67.96561295011001,
        ymax: 0.204784303349982,

        spatialReference: {
          wkid: 4326,
        },
      });

      const configMap = {
        map: webmap,
        // center: [-75, -10],
        zoom: 5,
        extent: extent,
        padding: {
          left: 0,
        },
      };

      const widgets: any[] = [
        legend,
        bookmarks,
        //layerList, ///layer list
        searchWidget,
        elevationProfile,
      ];
      const customMap = new CustomMap(configMap, mapDiv.current, widgets);
      const view = customMap.getView();

      const switchBtn = document.getElementById(
        "switch-btn"
      ) as HTMLInputElement;
      switchBtn.addEventListener("click", () => {
        setSelectedMode(customMap.view.type.toUpperCase());
        customMap.switchView();
      });

      /**********INICIO DE MAPA PARA OVERVIEW**************/
      const overviewMap = new WebMap({
        basemap: "topo-vector",
      });
      // Create the MapView for overview map
      const mapView = new MapView({
        container: "overviewDiv",
        map: overviewMap,
        constraints: {
          rotationEnabled: false,
        },
      });
      // Remove the default widgets
      mapView.ui.components = [];
      const extentDiv = document.getElementById("extentDiv") as HTMLElement;
      /**********FIN DE MAPA PARA OVERVIEW**************/

      //ASIGNANDO LAS VISTAS Y CONTENEDORES DE LOS WIDGETS
      legend.view = view;
      legend.container = "legend-container";
      //layerList.view = view;
      //layerList.container = "layers-container";
      basemaps.view = view;
      basemaps.container = "basemaps-container";
      bookmarks.view = view;
      bookmarks.container = "bookmarks-container";
      print.view = view as MapView;
      print.container = "print-container";
      sketch.view = view;
      sketch.container = "sketch-container";
      elevationProfile.view = view;
      elevationProfile.container = "elevation-container";

      //Coordinates
      const ccWidget = new CoordinateConversion();
      ccWidget.view = view as MapView;
      // ccWidget.visibleElements.expandButton = false;
      ccWidget.visibleElements.editButton = false;
      ccWidget.visibleElements.settingsButton = false;

      // const cord = new Collection();
      // cord.add("UTM");
      // cord.add("XY");
      // cord.add("DDM");
      // ccWidget.conversions = cord;
      const toRemove = ccWidget.formats.filter(
        (format) =>
          format.name !== "utm" &&
          format.name !== "xy" &&
          format.name !== "dms" &&
          format.name !== "dd" &&
          format.name !== "basemap"
      );
      ccWidget.formats.removeMany(toRemove);

      view.ui.add(ccWidget, "bottom-left");

      scaleBar.view = view as MapView;
      scaleBar.style = "ruler";
      view.ui.add(scaleBar, "bottom-left");

      const customSearchSourceDM = new SearchSource({
        placeholder: "Ingrese datos del DM",

        getSuggestions: ((params: any) => {
          console.log(params);
          let queryUrl =
            "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer/0";

          return executeQueryJSON(queryUrl, {
            where:
              "CONCESION  like  upper('%" +
              params.suggestTerm.replace(/ /g, "+") +
              "%')",
            outFields: ["CODIGOU", "CONCESION", "TIT_CONCES"],
          }).then(
            function (results) {
              return results.features.map((feature) => {
                return {
                  key: feature.attributes.CODIGOU,
                  text:
                    feature.attributes.CODIGOU +
                    " - " +
                    feature.attributes.CONCESION +
                    " - " +
                    feature.attributes.TIT_CONCES,
                  sourceIndex: params.sourceIndex,
                };
              });
            },
            function (error) {
              console.log(error);
            }
          );
        }) as __esri.GetSuggestionsParameters,

        getResults: ((params: any) => {
          let queryUrl =
            "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer/0";

          return executeQueryJSON(queryUrl, {
            // autocasts as new Query()
            where: "CODIGOU  like  upper('%" + params.suggestResult.key + "%')",
            outFields: ["CODIGOU", "CONCESION", "TIT_CONCES"],
            returnGeometry: true,
          }).then(
            function (results) {
              const searchResults = results.features.map((feature) => {
                const graphic = new Graphic({
                  geometry: feature.geometry,
                  attributes: feature.attributes,
                });

                const searchResult = {
                  extent: feature.geometry.extent.expand(1.5),
                  feature: graphic,
                  name:
                    feature.attributes.CODIGOU +
                    " - " +
                    feature.attributes.CONCESION +
                    " - " +
                    feature.attributes.TIT_CONCES,
                };
                return searchResult;
              });
              return searchResults;
            },
            function (error) {
              console.log(error);
            }
          );
        }) as __esri.GetResultsHandler,
      });

      searchWidget.view = view;
      searchWidget.container = "search-container";
      searchWidget.allPlaceholder = "Ingrese Búsqueda";
      searchWidget.includeDefaultSources = true;
      searchWidget.sources = [customSearchSourceDM] as any;

      /******************************** */

      //widget expand - overview
      let counterOverView = 0;
      const overviewDiv = document.getElementById("overviewDiv") as HTMLElement;
      widgetOverview.view = view;
      widgetOverview.content = overviewDiv;
      view.ui.add(widgetOverview, "bottom-right");

      const toolBarZoomDiv = document.getElementById(
        "toolBarZoomDiv"
      ) as HTMLElement;

      const homeWidget = new Home({
        view: view,
        id: "homeWidget",
      });
      const locateBtn = new Locate({
        view: view,
      });

      view.ui.add(homeWidget, "top-right");
      view.ui.move("zoom", "top-right");
      view.ui.add(toolBarZoomDiv, "top-right");
      view.ui.add(locateBtn, "top-right");

      webmap
        .when(() => {
          //layer de trabajo
          webmap.add(graphicsLayerResultado);
          webmap.add(graphicsLayerZoom);

          setShowMap(true);
          setViewState(view);
          setBuscarCatastro(new CatastroMinero(view));
          setShowOverview(false);
          //Objeto que reemplaza a CatastroMinero
          setDataSources(new MainSearching(view));

          //DESACTIVANO PAGINA DE INICIO
          dispatch(setStartIni(false));

          /***** WIDGET POR DEFECTO *****/
          ActiveWidget("searchdm");

          /**********INICIO DE MAPA PARA OVERVIEW**************/
          mapView.when(function () {
            // Update the overview extent whenever the MapView or SceneView extent changes
            view.watch("extent", updateOverviewExtent);

            if (view.spatialReference != null) {
              mapView.spatialReference = view.spatialReference;
              mapView.watch("extent", updateOverviewExtent);

              // Update the minimap overview when the main view becomes stationary

              //watchUtils.when(view, "stationary", updateOverview);

              reactiveUtils.watch(
                () => view.stationary,
                (stationary) => {
                  if (stationary) {
                    updateOverview();
                  }
                }
              );
            }

            function updateOverview() {
              if (!widgetOverview.expanded && counterOverView > 0) {
                return false;
              }
              counterOverView++;

              // Animate the MapView to a zoomed-out scale so we get a nice overview.
              // We use the "progress" callback of the goTo promise to update
              // the overview extent while animating
              mapView.goTo({
                center: view.center,
                scale:
                  view.scale *
                  2 *
                  Math.max(
                    view.width / mapView.width,
                    view.height / mapView.height
                  ),
              });
            }

            function updateOverviewExtent() {
              if (!widgetOverview.expanded && counterOverView > 0) {
                return false;
              }

              // Update the overview extent by converting the SceneView extent to the
              // MapView screen coordinates and updating the extentDiv position.
              const extent = view.extent;
              if (extent != null && extent.spatialReference != null) {
                const cuurWkid = extent.spatialReference.wkid;
                const pointLeft = new Point({
                  x: extent.xmin,
                  y: extent.ymin,
                  spatialReference: { wkid: cuurWkid },
                });
                const pointRight = new Point({
                  x: extent.xmax,
                  y: extent.ymax,
                  spatialReference: { wkid: cuurWkid },
                });
                const bottomLeft = mapView.toScreen(pointLeft);
                const topRight = mapView.toScreen(pointRight);

                if (bottomLeft != null && topRight != null) {
                  extentDiv.style.top = topRight.y + "px";
                  extentDiv.style.left = bottomLeft.x + "px";

                  extentDiv.style.height = bottomLeft.y - topRight.y + "px";
                  extentDiv.style.width = topRight.x - bottomLeft.x + "px";
                }
              }
            }
          });
          /**********FIN DE MAPA PARA OVERVIEW**************/
        })
        .catch((error) => {
          console.log("ERROR LOADING Map View:", error);
        });
    }
  }, []);

  return (
    <div className="rb-wrapper h-100 hiddenPanel">
      <div
        id="infoResultados"
        hidden={true}
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: "22px",
          position: "absolute",
          top: "100px",
          left: "420px",
          zIndex: 101,
          backgroundColor: "red",
          padding: "4px",
        }}
      >
        {records} - {dataSources?.records}
      </div>
      <div id="search-container" className="search-container"></div>
      <CalciteShell className="head-rb position-absolute z-2 w-100 d-inline-block">
        <CalcitePanel className="h-auto d-inline-block p-1">
          <img className="logo-rb position-absolute" src={logoWhite} alt="" />
          <ul className="ulTools-rb m-0 p-0 d-none d-md-inline list-inline position-absolute">
            <li className="liTool-rb liConsult me-4">
              <CalciteSelect
                className="typeConsult"
                label=""
                scale="m"
                title={t("screens.botones.hacer")}
                onCalciteSelectChange={(e) => TipoWidget(e.target.value)}
                id="cmbTypeConsult"
              >
                <CalciteOption value="">
                  {t("screens.home.whatdo")}
                </CalciteOption>
                <CalciteOption value="querys" selected={!displayQuerys}>
                  {t("screens.home.querys")}
                </CalciteOption>
                <CalciteOption value="analysis">
                  {t("screens.home.analysis")}
                </CalciteOption>
              </CalciteSelect>

              <CalciteSelect
                title={t("screens.botones.hacer")}
                className="itemsConsult"
                label=""
                scale="m"
                id="selectConsultas"
                name="cmbItemsConsult"
                hidden={displayQuerys}
                onCalciteSelectChange={(e) => ActiveWidget(e.target.value)}
              >
                <CalciteOption value="">
                  -{t("screens.menu.searching.title")}-
                </CalciteOption>
                <CalciteOption
                  value="searchdm"
                  id="optsearchdm"
                  selected={showSearchDM}
                >
                  {t("screens.menu.searching.searchdm")}
                </CalciteOption>
                {/*<CalciteOption value="listadocapas">
                  {t("screens.menu.searching.listadocapas")}
                </CalciteOption>*/}
                <CalciteOption value="consultageologica">
                  {t("screens.menu.searching.consultageologica")}
                </CalciteOption>
                <CalciteOption value="planocatastrala4">
                  {t("screens.menu.searching.planocatastrala4")}
                </CalciteOption>
                <CalciteOption value="catastroregion">
                  {t("screens.menu.searching.catastroregion")}
                </CalciteOption>
                <CalciteOption value="proyectosmineros">
                  {t("screens.menu.searching.proyectosmineros")}
                </CalciteOption>
                <CalciteOption value="ubicarcoordenada">
                  {t("screens.menu.searching.ubicarcoordenada")}
                </CalciteOption>
                <CalciteOption value="preevaluacion">
                  {t("screens.menu.searching.preevaluacion")}
                </CalciteOption>
                <CalciteOption value="consultadistribucion">
                  {t("screens.menu.searching.consultadistribucion")}
                </CalciteOption>
                <CalciteOption value="consultasavanzadas">
                  {t("screens.menu.searching.consultasavanzadas")}
                </CalciteOption>
                <CalciteOption value="transformarcoordenadas">
                  {t("screens.menu.searching.transformarcoordenadas")}
                </CalciteOption>
              </CalciteSelect>

              <CalciteSelect
                className="itemsConsult"
                label=""
                scale="m"
                id="selectAnalisis"
                title={t("screens.botones.opcion")}
                hidden={displayAnalysis}
                onCalciteSelectChange={(e) => ActiveWidget(e.target.value)}
              >
                <CalciteOption value="-">
                  -{t("screens.menu.spatial.title")}-
                </CalciteOption>
                <CalciteOption value="potencialminero">
                  {t("screens.menu.spatial.potencialminero")}
                </CalciteOption>
                <CalciteOption value="mapapeligrosgeologicos">
                  {t("screens.menu.spatial.mapapeligrosgeologicos")}
                </CalciteOption>
                <CalciteOption value="analisisriesgogeologico">
                  {t("screens.menu.spatial.analisisriesgogeologico")}
                </CalciteOption>
                <CalciteOption value="analisisexploracion">
                  {t("screens.menu.spatial.analisisexploracion")}
                </CalciteOption>
                <CalciteOption value="mapatematico">
                  {t("screens.menu.spatial.mapatematico")}
                </CalciteOption>
                <CalciteOption value="mapacalor">
                  {t("screens.menu.spatial.mapacalor")}
                </CalciteOption>
                <CalciteOption value="elevation">
                  {t("screens.menu.spatial.perfilelevacion")}
                </CalciteOption>
                <CalciteOption value="rocamineralindustrial">
                  {t("screens.menu.spatial.rocamineralindustrial")}
                </CalciteOption>
                <CalciteOption value="descargageodatabase">
                  {t("screens.menu.spatial.descargageodatabase")}
                </CalciteOption>
              </CalciteSelect>
            </li>
            <li className="liTool-rb liSearch me-4">
              <div>&nbsp;</div>
            </li>
            <li className="liTool-rb me-2">
              <CalciteButton
                kind="neutral"
                icon-start="layers"
                id="button-layers"
                title={t("screens.botones.listado")}
              ></CalciteButton>
              <CalcitePopover
                heading={t("screens.home.layers")}
                label={t("screens.home.layers")}
                // closable
                autoClose={true}
                referenceElement="button-layers"
                placement="bottom-end"
                overlayPositioning="fixed"
              >
                {/*
                <div
                  style={{ maxHeight: "500px", textAlign: "left" }}
                  id="layers-container"
                  hidden={false}
                ></div>
                */}
                <div>
                  {viewState != null && (
                    <ListadoCapas
                      // layerlist={layerList}
                      is3D={selectedMode}
                      view={viewState}
                    ></ListadoCapas>
                  )}
                </div>
              </CalcitePopover>
            </li>
            <li className="liTool-rb me-2">
              <CalciteButton
                kind="neutral"
                icon-start="list-bullet"
                id="button-legend"
                title={t("screens.botones.leyenda")}
              ></CalciteButton>
              <CalcitePopover
                heading={t("screens.home.legend")}
                label={t("screens.home.legend")}
                // closable
                autoClose={true}
                referenceElement="button-legend"
                placement="bottom-end"
                overlayPositioning="fixed"
              >
                <div
                  style={{ maxHeight: "500px", textAlign: "left" }}
                  id="legend-container"
                ></div>
              </CalcitePopover>
            </li>
            <li className="liTool-rb me-2">
              <CalciteButton
                kind="neutral"
                icon-start="handle-vertical"
                id="button-tools"
                title={t("screens.botones.herramientas")}
              ></CalciteButton>
              <CalcitePopover
                heading={t("screens.home.tools")}
                label={t("screens.home.tools")}
                //closable
                autoClose={true}
                referenceElement="button-tools"
                placement="bottom-end"
                overlayPositioning="fixed"
              >
                <div style={{ maxHeight: "500px", textAlign: "left" }}>
                  <CalciteList>
                    <CalciteListItem
                      label={t("screens.home.basemaps")}
                      onClick={(e) => ActiveWidget("basemap")}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="m"
                        icon="basemap"
                        style={{ paddingLeft: "8px" }}
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label={t("screens.home.bookmarks")}
                      onClick={(e) => ActiveWidget("bookmarks")}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="m"
                        icon="bookmark"
                        style={{ paddingLeft: "8px" }}
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label={t("screens.home.print")}
                      onClick={(e) => ActiveWidget("print")}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="m"
                        icon="print"
                        style={{ paddingLeft: "8px" }}
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label={t("screens.home.sketch")}
                      onClick={(e) => ActiveWidget("sketch")}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="m"
                        icon="annotate-tool"
                        style={{ paddingLeft: "8px" }}
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label={t("screens.home.measure")}
                      onClick={(e) => ActiveWidget("measure")}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="m"
                        icon="measure"
                        style={{ paddingLeft: "8px" }}
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label={t("screens.home.importmap")}
                      onClick={(e) => ActiveWidget("importmap")}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="m"
                        icon="file-shape"
                        style={{ paddingLeft: "8px" }}
                      />
                    </CalciteListItem>
                    <CalciteListItemGroup heading="Información">
                      <CalciteListItem
                        label="Servicios web"
                        onClick={(e) => PopupServiciosWeb()}
                      >
                        <CalciteIcon
                          slot="actions-start"
                          scale="m"
                          icon="web"
                          style={{ paddingLeft: "8px" }}
                        />
                      </CalciteListItem>
                      <CalciteListItem
                        label="Manual de Geocatmin"
                        onClick={(e) =>
                          window.open(
                            "https://geocatmin.ingemmet.gob.pe/manual/",
                            "_blank",
                            "noreferrer"
                          )
                        }
                      >
                        <CalciteIcon
                          slot="actions-start"
                          scale="m"
                          icon="file-text"
                          style={{ paddingLeft: "8px" }}
                        />
                      </CalciteListItem>
                      <CalciteListItem
                        label="Acerca de Geocatmin"
                        onClick={(e) => PopupAboutOf()}
                      >
                        <CalciteIcon
                          slot="actions-start"
                          scale="m"
                          icon="information"
                          style={{ paddingLeft: "8px" }}
                        />
                      </CalciteListItem>
                    </CalciteListItemGroup>
                  </CalciteList>
                </div>
              </CalcitePopover>
            </li>
            <li className="liTool-rb me-2">
              <CalciteButton
                kind="neutral"
                id="switch-btn"
                title={t("screens.botones.visualizacion")}
              >
                {selectedMode}
              </CalciteButton>
            </li>
            <li className="liTool-rb me-2">
              <SwitchCssMode
                view={viewState}
                title={t("screens.botones.vista")}
              ></SwitchCssMode>
            </li>
            <li className="liTool-rb">
              <CalciteButton kind="neutral" title={t("screens.botones.idioma")}>
                <i
                  className={`${iconLang} m-0`}
                  onClick={() => changeLanguage()}
                ></i>
              </CalciteButton>
            </li>
          </ul>

          {/***** SOLO PARA VERSION MOVIL ******/}
          <ul className="ulTools-rb m-0 p-0 d-md-none list-inline position-absolute ulMovil-rb">
            <li className="liTool-rb liConsult">
              <CalciteSelect
                className="typeConsult"
                label=""
                scale="m"
                title={t("screens.botones.hacer")}
                onCalciteSelectChange={(e) => TipoWidget(e.target.value)}
                id="cmbTypeConsult"
              >
                <CalciteOption value="">
                  {t("screens.home.whatdo")}
                </CalciteOption>
                <CalciteOption value="querys" selected={!displayQuerys}>
                  {t("screens.home.querys")}
                </CalciteOption>
                <CalciteOption value="analysis">
                  {t("screens.home.analysis")}
                </CalciteOption>
              </CalciteSelect>

              <CalciteSelect
                title={t("screens.botones.hacer")}
                className="itemsConsult"
                label=""
                scale="m"
                id="selectConsultas"
                name="cmbItemsConsult"
                hidden={displayQuerys}
                onCalciteSelectChange={(e) => ActiveWidget(e.target.value)}
              >
                <CalciteOption value="">
                  -{t("screens.menu.searching.title")}-
                </CalciteOption>
                <CalciteOption
                  value="searchdm"
                  id="optsearchdm"
                  selected={showSearchDM}
                >
                  {t("screens.menu.searching.searchdm")}
                </CalciteOption>
                <CalciteOption value="consultageologica">
                  {t("screens.menu.searching.consultageologica")}
                </CalciteOption>
                <CalciteOption value="planocatastrala4">
                  {t("screens.menu.searching.planocatastrala4")}
                </CalciteOption>
                <CalciteOption value="catastroregion">
                  {t("screens.menu.searching.catastroregion")}
                </CalciteOption>
                <CalciteOption value="proyectosmineros">
                  {t("screens.menu.searching.proyectosmineros")}
                </CalciteOption>
                <CalciteOption value="ubicarcoordenada">
                  {t("screens.menu.searching.ubicarcoordenada")}
                </CalciteOption>
                <CalciteOption value="preevaluacion">
                  {t("screens.menu.searching.preevaluacion")}
                </CalciteOption>
                <CalciteOption value="consultadistribucion">
                  {t("screens.menu.searching.consultadistribucion")}
                </CalciteOption>
                <CalciteOption value="consultasavanzadas">
                  {t("screens.menu.searching.consultasavanzadas")}
                </CalciteOption>
                <CalciteOption value="transformarcoordenadas">
                  {t("screens.menu.searching.transformarcoordenadas")}
                </CalciteOption>
              </CalciteSelect>

              <CalciteSelect
                className="itemsConsult"
                label=""
                scale="m"
                id="selectAnalisis"
                title={t("screens.botones.opcion")}
                hidden={displayAnalysis}
                onCalciteSelectChange={(e) => ActiveWidget(e.target.value)}
              >
                <CalciteOption value="-">
                  -{t("screens.menu.spatial.title")}-
                </CalciteOption>
                <CalciteOption value="potencialminero">
                  {t("screens.menu.spatial.potencialminero")}
                </CalciteOption>
                <CalciteOption value="mapapeligrosgeologicos">
                  {t("screens.menu.spatial.mapapeligrosgeologicos")}
                </CalciteOption>
                <CalciteOption value="analisisriesgogeologico">
                  {t("screens.menu.spatial.analisisriesgogeologico")}
                </CalciteOption>
                <CalciteOption value="analisisexploracion">
                  {t("screens.menu.spatial.analisisexploracion")}
                </CalciteOption>
                <CalciteOption value="mapatematico">
                  {t("screens.menu.spatial.mapatematico")}
                </CalciteOption>
                <CalciteOption value="mapacalor">
                  {t("screens.menu.spatial.mapacalor")}
                </CalciteOption>
                <CalciteOption value="elevation">
                  {t("screens.menu.spatial.perfilelevacion")}
                </CalciteOption>
                <CalciteOption value="rocamineralindustrial">
                  {t("screens.menu.spatial.rocamineralindustrial")}
                </CalciteOption>
                <CalciteOption value="descargageodatabase">
                  {t("screens.menu.spatial.descargageodatabase")}
                </CalciteOption>
              </CalciteSelect>
            </li>
          </ul>
          <ul className="ulTools-rb m-0 p-0 d-md-none list-inline position-absolute">
            <li className="liTool-rb">
              <SwitchCssMode
                view={viewState}
                title={t("screens.botones.vista")}
              ></SwitchCssMode>
            </li>
            <li className="liTool-rb ms-2">
              <CalciteButton kind="neutral">
                <i className="flag flag-us m-0"></i>
              </CalciteButton>
            </li>
          </ul>
        </CalcitePanel>
      </CalciteShell>

      <CalciteShell className="rb-container h-100 d-inline-block d-flex align-items-stretch">
        <div className="rb-panel">
          <div
            style={{ position: "relative", top: "-2px" }}
            hidden={!activeWidgets}
          >
            <CalciteButton
              kind="inverse"
              icon-start="chevron-right"
              className="flap-rb-2 position-absolute z-2"
              hidden={!panelVisible}
              onClick={(e) => MostrarPanelWidgets()}
            ></CalciteButton>
          </div>

          <div
            style={{
              padding: "0px",
              margin: "0px",
              maxHeight: "560px",
              // overflowY: "auto",
              // overflowX: "hidden",
            }}
            hidden={panelVisible}
          >
            <CalciteBlock
              collapsible
              open
              heading={t("screens.menu.searching.searchdm")}
              hidden={!showSearchDM}
              id="widget-searchdm"
              className="widget"
              style={{ with: "300px" }}
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showSearchDM && dataSources != null && (
                  <BuscarDM data={dataSources}></BuscarDM>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.consultageologica")}
              hidden={!showConsultaGeologica}
              id="widget-consultageologica"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showConsultaGeologica && viewState != null && (
                  <ConsultaGeologica
                    view={viewState}
                    catastro={buscarCatastro}
                  ></ConsultaGeologica>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.planocatastrala4")}
              hidden={!showPlanoCatastralA4}
              id="widget-planocatastrala4"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showPlanoCatastralA4 && dataSources != null && (
                  <PlanoCatastralA4 data={dataSources}></PlanoCatastralA4>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.catastroregion")}
              hidden={!showCatastroRegion}
              id="widget-catastroregion"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showCatastroRegion && dataSources != null && (
                  <CatastroRegion data={dataSources}></CatastroRegion>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.proyectosmineros")}
              hidden={!showProyectosMineros}
              id="widget-proyectosmineros"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showProyectosMineros && dataSources != null && (
                  <ProyectosMineros data={dataSources}></ProyectosMineros>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.ubicarcoordenada")}
              hidden={!showUbicarCoordenada}
              id="widget-ubicarcoordenada"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showUbicarCoordenada && viewState != null && (
                  <UbicarCoordenada
                    view={viewState}
                    catastro={buscarCatastro}
                  ></UbicarCoordenada>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.preevaluacion")}
              hidden={!showPreEvaluacion}
              id="widget-preevaluacion"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showPreEvaluacion && dataSources != null && (
                  <PreEvaluacion data={dataSources}></PreEvaluacion>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.consultasavanzadas")}
              hidden={!showConsultasAvanzadas}
              id="widget-consultasavanzadas"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showConsultasAvanzadas && dataSources != null && (
                  <ConsultasAvanzadas data={dataSources}></ConsultasAvanzadas>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.transformarcoordenadas")}
              hidden={!showTransformarCoordenadas}
              id="widget-transformarcoordenadas"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showTransformarCoordenadas && viewState != null && (
                  <TransformarCoordenadas
                    view={viewState}
                  ></TransformarCoordenadas>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.potencialminero")}
              hidden={!showPotencialMinero}
              id="widget-potencialminero"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showPotencialMinero && dataSources != null && (
                  <PotencialMinero data={dataSources}></PotencialMinero>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.mapapeligrosgeologicos")}
              hidden={!showMapaPeligrosGeologicos}
              id="widget-mapapeligrosgeologicos"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showMapaPeligrosGeologicos && dataSources != null && (
                  <MapaPeligrosGeologicos
                    catastro={buscarCatastro}
                    view={viewState}
                  ></MapaPeligrosGeologicos>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.analisisriesgogeologico")}
              hidden={!showAnalisisRiesgoGeologico}
              id="widget-analisisriesgogeologico"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showAnalisisRiesgoGeologico && dataSources != null && (
                  <AnalisisRiesgoGeologico
                    data={dataSources}
                  ></AnalisisRiesgoGeologico>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.analisisexploracion")}
              hidden={!showAnalisisExploracion}
              id="widget-analisisexploracion"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showAnalisisExploracion && dataSources != null && (
                  <AnalisisExploracion data={dataSources}></AnalisisExploracion>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.mapatematico")}
              hidden={!showMapaTematico}
              id="widget-mapatematico"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showMapaTematico && dataSources != null && (
                  <MapaTematico
                    catastro={buscarCatastro}
                    view={viewState}
                  ></MapaTematico>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.mapacalor")}
              hidden={!showMapaCalor}
              id="widget-mapacalor"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showMapaCalor && dataSources != null && (
                  <MapaCalor
                    catastro={buscarCatastro}
                    view={viewState}
                  ></MapaCalor>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.rocamineralindustrial")}
              hidden={!showRocaMineralIndustrial}
              id="widget-rocamineralindustrial"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showRocaMineralIndustrial && dataSources != null && (
                  <RocaMineralIndustrial
                    data={dataSources}
                  ></RocaMineralIndustrial>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.descargageodatabase")}
              hidden={!showDescargaGeodatabase}
              id="widget-descargageodatabase"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showDescargaGeodatabase && dataSources != null && (
                  <DescargaGeodatabase data={dataSources}></DescargaGeodatabase>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.searching.location")}
              hidden={!showLocation}
              id="widget-location"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showLocation && viewState != null && (
                  <LocationCoords view={viewState}></LocationCoords>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.menu.spatial.perfilelevacion")}
              hidden={!showElevation}
              id="widget-elevation"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div id="elevation-container"></div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.home.basemaps")}
              hidden={!showBasemap}
              id="widget-basemap"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div id="basemaps-container"></div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.home.bookmarks")}
              hidden={!showBookmarks}
              id="widget-bookmarks"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div id="bookmarks-container"></div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.home.print")}
              hidden={!showPrint}
              id="widget-print"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div id="print-container"></div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.home.sketch")}
              hidden={!showSketch}
              id="widget-sketch"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div id="sketch-container"></div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.home.measure")}
              hidden={!showMeasure}
              id="widget-measure"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showMeasure && <Measure view={viewState}></Measure>}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>

            <CalciteBlock
              className="widget"
              collapsible
              open
              heading={t("screens.home.importmap")}
              hidden={!showImportMap}
              id="widget-importmap"
            >
              <CalciteIcon scale="s" slot="icon" icon="drag"></CalciteIcon>
              <div className="contentAco-rb">
                {showImportMap && (
                  <ImportShapefile view={viewState}></ImportShapefile>
                )}
              </div>
              <CalciteAction
                slot="control"
                text="Esconder"
                scale="s"
                icon="chevron-left"
                onClick={(e) => MostrarPanelWidgets()}
              ></CalciteAction>
              <CalciteAction
                slot="control"
                text="Cerrar"
                scale="s"
                icon="x"
                onClick={(e) => CloseWidget(e)}
              ></CalciteAction>
            </CalciteBlock>
          </div>
        </div>

        <CalcitePanel className="rb-map d-flex align-items-start flex-column">
          <CalciteLoader
            label={t("screens.home.loading")}
            type="indeterminate"
            style={{ top: "120px" }}
            hidden={showMap}
          ></CalciteLoader>
          <div
            className="mapDiv w-100 h-100"
            ref={mapDiv}
            hidden={!showMap}
          ></div>

          {dataSources != null && (
            <div
              className="rb-results w-100 mt-auto p-1"
              hidden={!(records > 0 && dataSources?.records > 0)}
            >
              <TableResult data={dataSources}></TableResult>
            </div>
          )}
        </CalcitePanel>

        <div id="overviewDiv" hidden={showOverview}>
          <div id="extentDiv"></div>
        </div>
        {/* <div id="toolBarZoomDiv">
          {viewState != null && (
            <ToolBarZoom id="toolBarZoom" data={dataSources}></ToolBarZoom>
          )}
        </div> */}
      </CalciteShell>

      <CustomPopupHTML
        title={t("screens.menu.searching.consultadistribucion")}
        content={
          "<iframe width='100%' height='" +
          (window.document.body.scrollHeight - 100) +
          "' frameborder='0' src='https://app.powerbi.com/view?r=eyJrIjoiZDI1YTU3NDQtOGM2Ni00M2ZlLThmZmYtMmEyZWM2YWZmZTg1IiwidCI6ImNkNzkzYWQzLTdiZTItNGM4OS1iNmY1LTVmNmM4OTQzNDA2OSIsImMiOjR9'>/iframe>"
        }
        id="popconsultadistribucion"
        fullscreen={true}
      ></CustomPopupHTML>
      <CustomPopupHTML
        title="Acerca de Geocatmin"
        content="<center><h3>GEOCATMIN</h3><p>Sistema de Información Geográfico</p><p>Fecha de lanzamiento: Diciembre 2023</p><a href='https://www.minem.gob.pe/_legislacionM.php?idSector=1&idLegislacion=10223' target='_blank'>Oficializan Sistema de Cuadrículas Mineras</a><p><br><b>INGEMMET</b><br><br>Consultas:<br><i>jsalcedo@ingemmet.gob.pe</i><br><i>hcastro@ingemmet.gob.pe</i></p></center>"
        id="popAboutGeocatmin"
        fullscreen={false}
      ></CustomPopupHTML>
      <CustomPopupHTML
        title="Servicios Web"
        content="<center><img src='https://geocatmin.ingemmet.gob.pe/geocatmin/img/LogoINGEMMET_300px.png'/><br><h4>Servicio Web</h4></center>
        <p><strong>Catálogo de Metadatos</strong></p>
        <p>Portal de Datos de <em>INGEMMET</em>. Aquí puedes encontrar datos, servicios, mapas y más. </p>
        <p><a href='http://metadatos.ingemmet.gob.pe/'>Buscar conjuntos de datos, servicios y mapas</a></p>
        <p><strong>Servicio Web de Mapas</strong></p>
        <p>El servicio de mapas en web WMS es un estándar que permite realizar una solicitud de mapas en web</p>
        <p><b>Servicios WMS del INGEMMET</b></p>
        <ul>
          <li><a href='https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_GEOLOGIA_100K_INTEGRADA/MapServer/WMTS/1.0.0/WMTSCapabilities.xml' target='_blank'> WMST_GEOLOGIA100K</a></li>
          <li><a href='https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_GEOLOGIA/MapServer/WMTS/1.0.0/WMTSCapabilities.xml' target='_blank'> WMST_GEOLOGIANACIONAL</a></li>
          <li><a href='https://geocatmin.ingemmet.gob.pe/arcgis/services/SERV_CATASTRO_MINERO_WGS84/MapServer/WMSServer?request=GetCapabilities&service=WMS' target='_blank'> WMS_CATASTROMINERO</a></li>
        </ul>
        <p><b>Otros servicios</b>
        </p>
        <ul>
          <li><a href='https://www.idep.gob.pe/index.html#servicios' target='_blank'> Servicios IGN IDEP</a></li>
          <li><a href='https://livingatlas2.arcgis.com/landsatexplorer/index.html?eyJ4bWluIjotMTE0NjI3MjcuNzcxMzI5NzEsInltaW4iOi0yNjIzOTA4Ljc0NjQ0NDY2MDIsInhtYXgiOi01NDU3ODM0LjgyOTI0Nzg2LCJ5bWF4IjozNjUwODQuODA3NjE4MDc3Niwic3BhdGlhbFJlZmVyZW5jZSI6eyJ3a2lkIjoxMDIxMDB9fSZ7InJhc3RlckZ1bmN0aW9uIjoiQWdyaWN1bHR1cmUgd2l0aCBEUkEifSZudWxsJjAuMSYwJm51bGwmbnVsbCZudWxsJm51bGwmbnVsbCZudWxsJm51bGw=' target='_blank'> Landsat Explorer Peru</a></li>
          <li><a href='https://eos.com/landviewer/?lat=-11.72396&lng=-72.37793&z=7&id=LE07_L1TP_003070_20221108_20221108_02_RT&b=SWIR2,NIR,Green&anti&pansharpening=false' target='_blank'> Geocatmin Satelite & LANDVIEWER</a>
          </li>
        </ul>"
        id="popServiciosWeb"
        fullscreen={false}
      ></CustomPopupHTML>
    </div>
  );
};

export default MainPage;
