import MapView from "@arcgis/core/views/MapView";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import Graphic from "@arcgis/core/Graphic";
import SceneView from "@arcgis/core/views/SceneView";
import Extent from "@arcgis/core/geometry/Extent";
import { QueryField } from "../../core/interfaces/QueryField";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export class CatastroMinero {
  view: MapView | SceneView;
  // codigo: string;
  // nombre: string;
  urlQuery: string;
  queryWhere: string;
  capa: string;
  subcapa: string;
  // titular: string;

  fields: QueryField[];
  outFields: string[];
  coords: any[];
  result: any[];
  excecuted: boolean;
  visible: boolean;

  public constructor(view: any) {
    // this.codigo = "";
    // this.nombre = "";
    // this.titular = "";
    this.urlQuery = "";
    this.queryWhere = "";
    this.capa = "";
    this.subcapa = "";
    this.fields = [];
    this.outFields = [];
    this.coords = [];
    this.result = [];
    this.view = view;
    this.excecuted = false;
    this.visible = true;
  }

  // public setCodigo(value: string) {
  //   this.codigo = value;
  // }
  // public setNombre(value: string) {
  //   this.nombre = value;
  // }
  public setUrlQuery(value: string) {
    this.urlQuery = value;
  }
  public SetQueryWhere(value: string) {
    this.queryWhere = value;
  }
  public setCapa(value: string) {
    this.capa = value;
  }
  public setSubCapa(value: string) {
    this.subcapa = value;
  }
  // public setTitular(value: string) {
  //   this.titular = value;
  // }

  public setFields(value: any[]) {
    this.fields = value;
  }
  public setExcecuted(value: boolean) {
    this.excecuted = value;
  }
  public setVisible(value: boolean) {
    this.visible = value;
  }
  public LimpiarValores() {
    // this.setCodigo("");
    // this.setNombre("");
    // this.setTitular("");
    this.view.graphics.removeAll();
  }

  symbolLine = {
    color: [255, 0, 0, 11, 0.75],
    width: 1,
  };

  symbolPoly = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: this.symbolLine,
  };

  simplePoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [255, 0, 0],
      width: 1,
    },
  };

  fnExtentPoint(geometry: any) {
    return new Extent({
      xmin: geometry.x - 0.0001,
      ymin: geometry.y - 0.0001,
      xmax: geometry.x + 0.0001,
      ymax: geometry.y + 0.0001,
      spatialReference: {
        wkid: geometry.spatialReference.wkid,
      },
    });
  }

  //public catastroLayer = new FeatureLayer();

  loadLayer(view: any, capa: string, subCapa: string, where: string) {
    let catastroLayer = new FeatureLayer();
    try {
      const foundLayer = view.map.allLayers.find(function (layer: any) {
        //console.log(layer.title);
        try {
          //console.log(layer.title);
          if (layer.title.toUpperCase() === capa.toUpperCase()) {
            layer.visible = true;
            layer.sublayers.forEach((e: any) => {
              console.log(e);
              if (e.title.toUpperCase() == subCapa.toUpperCase()) {
                catastroLayer = e;
                catastroLayer.visible = true;
                catastroLayer.definitionExpression = where;
                //console.log(e);
                return;
              }
            });
          }

          //return layer.title.toUpperCase() === capa.toUpperCase();
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
      //loadLayer();
    }
  }

  public BuscarCatastro() {
    let querySymbol: any;

    this.excecuted = false;
    this.coords = [];
    this.result = [];

    const queryUrl = this.urlQuery;
    this.outFields = [];
    this.fields.forEach((e) => {
      this.outFields.push(e.field);
    });
    // this.outFields.push("OBJECTID");
    // this.outFields.push("SHAPE");

    if (this.visible === false) {
      this.loadLayer(this.view, this.capa, this.subcapa, this.queryWhere);
    }

    executeQueryJSON
      .call(null, queryUrl, {
        where: this.queryWhere,
        outFields: ["*"],
        returnGeometry: true,
        // outFields: this.outFields,
      })
      .then(
        (results) => {
          this.view.graphics.removeAll();
          const arrData: never[] = [];
          const arrCoords: never[] = [];
          const data = results.features;
          let counter: number = 0;

          if (data[0] != null && data[0].geometry != null) {
            switch (data[0].geometry.type) {
              case "point":
                querySymbol = this.simplePoint;
                break;
              case "polyline":
                querySymbol = this.symbolLine;
                break;
              case "polygon":
                querySymbol = this.symbolPoly;
                break;
              default:
                querySymbol = this.simplePoint;
                break;
            }
          }

          let extent = new Extent();
          data.forEach((e) => {
            const fila: any = {};
            this.outFields.forEach((f) => {
              fila[f] = e.attributes[f];
            });
            arrData.push(fila as never);
            const coord =
              e.geometry == null
                ? {
                    // latitude: 0, longitude: 0,
                    objectid: 0,
                  }
                : {
                    // latitude: e.geometry.extent.center.latitude,
                    // longitude: e.geometry.extent.center.longitude,
                    objectid: e.attributes["OBJECTID"],
                  };
            if (e.geometry != null) {
              const graphic = new Graphic({
                geometry: e.geometry,
                symbol: querySymbol,
              });
              let extentTemp;
              if (e.geometry.type === "point") {
                extentTemp = this.fnExtentPoint(e.geometry);
              } else {
                extentTemp = e.geometry.extent;
              }

              extent.union(extentTemp);

              //adjuntar a la capa de graphicsLayerResultado
              //this.view.graphicsLayerResultado.graphics.add(graphic);
              if (this.visible) {
                this.view.graphics.add(graphic);
              }

              extent.union(extentTemp);

              if (counter == 0) {
                extent = extentTemp;
              } else {
                extent.union(extentTemp);
              }
            }
            arrCoords.push(coord as never);
            counter++;
          });

          this.view.goTo(extent.expand(1.5));

          this.result = arrData;
          this.coords = arrCoords;
          this.excecuted = true;
        },
        (error) => {
          console.log(error);
          this.excecuted = false;
        }
      );
  }
}
