import { CalciteLabel, CalciteModal } from "@esri/calcite-components-react";

export function CustomPopupHTML(props: any) {
  const title = props.title;
  const content = props.content;
  const id = props.id;
  const fullscreen: boolean = props.fullscreen ?? false;

  return (
    <>
      {fullscreen && (
        <CalciteModal
          aria-labelledby="modal-title"
          id={id}
          scale="s"
          fullscreen
        >
          <div slot="header" id="modal-title">
            <b>{title}</b>
          </div>
          <div slot="content">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </CalciteModal>
      )}

      {!fullscreen && (
        <CalciteModal aria-labelledby="modal-title" id={id} scale="s">
          <div slot="header" id="modal-title">
            <b>{title}</b>
          </div>
          <div slot="content">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </CalciteModal>
      )}
    </>
  );
}
