import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import { CustomDataSource } from "../CustomDataSource";
import { QueryField } from "../../core/interfaces/QueryField";
import configCatastro from "./ConfigCatastro.json";
import { CustomFunctions } from "../../utils/CustomFunctions";
import {
  CalciteTableCell,
  CalciteTableHeader,
  CalciteTableRow,
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
  CalciteTable,
} from "@esri/calcite-components-react";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import StatisticDefinition from "@arcgis/core/rest/support/StatisticDefinition";
import Graphic from "@arcgis/core/Graphic";
import { MainSearching } from "./MainSearching";

export function CatastroRegion(props: any) {
  const { t } = useTranslation();

  let searching: MainSearching = props.data;
  const view = searching.view;
  const utils = new CustomFunctions();
  const dispatch = useAppDispatch();
  let dataSource = new CustomDataSource(view, "Catastro");
  let arrFields: QueryField[] = [];
  arrFields = configCatastro.Catastro as any;
  dataSource.setFields(arrFields);

  const [
    arrDepartamentosCatastroPorRegion,
    setArrDepartamentosCatastroPorRegion,
  ] = useState([]);
  const [arrProvinciasCatastroPorRegion, setArrProvinciasCatastroPorRegion] =
    useState([]);
  const [arrDistritosCatastroPorRegion, setArrDistritosCatastroPorRegion] =
    useState([]);
  const [arrHojaCatastroPorRegion, setArrHojaCatastroPorRegion] = useState([]);

  const [hideProvincia, setHideProvincia] = useState(true);
  const [hideDistrito, setHideDistrito] = useState(true);
  const [hideCuadrangulo, setHideCuadrangulo] = useState(true);

  const [codDptoCatastroPorRegion, setCodDptoCatastroPorRegion] = useState("");
  const [codProvCatastroPorRegion, setCodProvCatastroPorRegion] = useState("");
  const [codDistCatastroPorRegion, setCodDistCatastroPorRegion] = useState("");
  const [codHojaCatastroPorRegion, setCodHojaCatastroPorRegion] = useState("");

  const arrVacioCatastroPorRegion = [{ id: " ", name: "-- Seleccione --" }];

  const [dataResumen, setDataResumen] = useState<any[]>([]);
  const [hideTableResultado, setHideTableResultado] = useState(true);

  const departamentoUrl =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/0";
  const provinciaUrl =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/1";
  const distritoUrl =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/2";

  const cuadrangulosUrl =
    "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/WGS84/SERV_ZONAS_GEOWGS84/MapServer/0";

  const querySymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0],
    style: "solid",
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 0, 0, 11],
      width: 1,
    },
  };
  //let catastroLayer = new FeatureLayer();
  const capa = "Catastro Minero";
  const subCapa = "Catastro Minero";
  /*********** Llenar Array de UBIGEO *************** */
  //Combo Departamento - Region
  useEffect(() => {
    const queryUrl = departamentoUrl;
    //   "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/0";
    const queryWhere = "CD_DEPA <>'99'";

    executeQueryJSON
      .call(null, queryUrl, {
        where: queryWhere,
        outFields: ["CD_DEPA", "NM_DEPA"],
      })
      .then(
        function (results) {
          const data = results.features;
          const arrDataCatastroPorRegion: never[] = [];
          arrDataCatastroPorRegion.push(arrVacioCatastroPorRegion[0] as never);
          data.forEach((e) => {
            arrDataCatastroPorRegion.push({
              id: e.attributes.CD_DEPA,
              name: e.attributes.NM_DEPA,
            } as never);
          });

          setArrDepartamentosCatastroPorRegion(
            arrDataCatastroPorRegion as never
          );
          //   setCodProvCatastroPorRegion("");
          //   setCodDistCatastroPorRegion("");
          //   setCodHojaCatastroPorRegion("");
          //   setArrProvinciasCatastroPorRegion(arrVacioCatastroPorRegion as never);
          //   setArrDistritosCatastroPorRegion(arrVacioCatastroPorRegion as never);
          //loadLayer();
        },
        function (error) {
          console.log(error);
        }
      );
  }, []);
  //combo Provincia
  useEffect(() => {
    //Provincia
    console.log(codDptoCatastroPorRegion);
    if (codDptoCatastroPorRegion.trim() != "") {
      const queryUrl = provinciaUrl;
      // "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/1";
      const queryWhere = "CD_DEPA = '" + codDptoCatastroPorRegion + "'";

      executeQueryJSON
        .call(null, queryUrl, {
          where: queryWhere,
          outFields: ["CD_PROV", "NM_PROV"],
        })
        .then(
          function (results) {
            const data = results.features;
            const arrDataCatastroPorRegion: never[] = [];
            arrDataCatastroPorRegion.push(
              arrVacioCatastroPorRegion[0] as never
            );
            data.forEach((e) => {
              arrDataCatastroPorRegion.push({
                id: e.attributes.CD_PROV,
                name: e.attributes.NM_PROV,
              } as never);
            });
            setHideProvincia(false);
            setArrProvinciasCatastroPorRegion(
              arrDataCatastroPorRegion as never
            );
            setCodProvCatastroPorRegion("");
            setCodDistCatastroPorRegion("");
            setCodHojaCatastroPorRegion("");
            //   setArrDistritosCatastroPorRegion(
            //     arrVacioCatastroPorRegion as never
            //   );
          },
          function (error) {
            console.log(error);
          }
        );
      //loadDM(cmbDptoCatastroPorRegion.value, "");
    } else {
      setHideProvincia(true);
      setHideCuadrangulo(true);
    }
  }, [codDptoCatastroPorRegion]);
  //Combo Distrito
  useEffect(() => {
    if (codProvCatastroPorRegion.trim() != "") {
      const queryUrl = distritoUrl;
      // "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CARTOGRAFIA_DEMARCACION_WGS84/MapServer/2";
      const queryWhere = "CD_PROV = '" + codProvCatastroPorRegion + "'";

      executeQueryJSON
        .call(null, queryUrl, {
          where: queryWhere,
          outFields: ["CD_DIST", "NM_DIST"],
        })
        .then(
          function (results) {
            const data = results.features;
            const arrData: never[] = [];
            arrData.push(arrVacioCatastroPorRegion[0] as never);
            data.forEach((e) => {
              arrData.push({
                id: e.attributes.CD_DIST,
                name: e.attributes.NM_DIST,
              } as never);
            });
            setHideDistrito(false);
            setArrDistritosCatastroPorRegion(arrData as never);
            setCodDistCatastroPorRegion("");
            setCodHojaCatastroPorRegion("");
            //loadDM(cmbProvCatastroPorRegion.value, "");
          },

          function (error) {
            console.log(error);
          }
        );
    } else {
      setHideDistrito(true);
      //setArrDistritosCatastroPorRegion(arrVacioCatastroPorRegion as never);
    }
  }, [codProvCatastroPorRegion]);
  //Combo Hoja
  useEffect(() => {
    //console.log(codDptoCatastroPorRegion);
    //console.log(codProvCatastroPorRegion);
    //console.log(codDistCatastroPorRegion);
    //console.log(codHojaCatastroPorRegion);

    let ubigeo =
      codHojaCatastroPorRegion.trim().length > 0
        ? codHojaCatastroPorRegion
        : codDistCatastroPorRegion.trim().length > 0
        ? codDistCatastroPorRegion
        : codProvCatastroPorRegion.trim().length > 0
        ? codProvCatastroPorRegion
        : codDptoCatastroPorRegion.trim().length > 0
        ? codDptoCatastroPorRegion
        : "";
    let tipo = codHojaCatastroPorRegion.trim().length > 0 ? "HOJA" : "";

    if (ubigeo.length > 0) {
      let queryUrl = "";
      let queryWhere = "";
      let queryFields = ["*"];
      switch (ubigeo.length) {
        case 2:
          queryUrl = departamentoUrl;
          queryWhere = "cd_depa = '" + ubigeo + "'";
          break;
        case 4:
          queryUrl = provinciaUrl;
          queryWhere = "cd_prov = '" + ubigeo + "'";
          break;

        case 6:
          queryUrl = distritoUrl;
          queryWhere = "cd_dist = '" + ubigeo + "'";
          break;
      }

      if (tipo === "HOJA") {
        queryUrl = cuadrangulosUrl;
        queryWhere = "cd_hoja = '" + ubigeo + "'";
      }

      executeQueryJSON
        .call(null, queryUrl, {
          where: queryWhere,
          outFields: queryFields,
          returnGeometry: true,
        })
        .then(
          function (results) {
            view.graphics.removeAll();
            const data = results.features;
            data.forEach((e) => {
              if (e.geometry != null) {
                const graphic = new Graphic({
                  geometry: e.geometry,
                  symbol: querySymbol,
                });
                if (tipo != "HOJA") {
                  //Cuadrangulos
                  executeQueryJSON
                    .call(null, cuadrangulosUrl, {
                      //where: queryWhere,
                      geometry: graphic.geometry,
                      spatialRelationship: "intersects",
                      outFields: ["CD_HOJA", "NM_HOJA"],
                    })
                    .then(
                      function (results) {
                        //  console.log(results);
                        const data = results.features;
                        const arrData: never[] = [];
                        arrData.push(arrVacioCatastroPorRegion[0] as never);
                        data.forEach((e) => {
                          arrData.push({
                            id: e.attributes.CD_HOJA,
                            name:
                              "(" +
                              e.attributes.CD_HOJA +
                              ") " +
                              e.attributes.NM_HOJA,
                          } as never);
                        });

                        setArrHojaCatastroPorRegion(arrData as never);
                        setHideCuadrangulo(false);
                        // console.log(arrHojaCatastroPorRegion);
                      },
                      function (error) {
                        console.log(error);
                      }
                    );
                  //end cuadrangulos
                }
                view.graphics.add(graphic);
                view
                  .goTo(graphic.geometry.extent.expand(1.5))
                  .catch((error: any) => {
                    console.error(error);
                  });
              }
            });
          },
          function (error) {
            console.log(error);
          }
        );
      loadDM(ubigeo, tipo);
    } else {
      loadDM("", "");
    }
    //Consultar DM
    function loadDM(ubigeo: string, tipo: string) {
      dispatch(setRecords(0));
      searching.dataSources = [];
      searching.setExecuted(false);

      const queryUrl =
        "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer/0";
      let queryWhere =
        "estado <> 'Y' and (demagis like '" +
        ubigeo +
        "%' or demagis like '%;" +
        ubigeo +
        "%')";
      if (tipo === "HOJA") {
        queryWhere = "carta ='" + ubigeo + "'";
      }
      if (ubigeo.length == 0) {
        queryWhere = "estado <> 'Y'";
      }

      const sumDM = new StatisticDefinition({
        statisticType: "count",
        onStatisticField: "leyenda",
        outStatisticFieldName: "DENUNCIOS",
      });

      executeQueryJSON
        .call(null, queryUrl, {
          outStatistics: [sumDM],
          where: queryWhere,
          outFields: ["leyenda"],
          groupByFieldsForStatistics: ["leyenda"],
        })
        .then(
          function (results) {
            //console.log(results);
            const arrDataResumen: never[] = [];
            const data = results.features;
            let total = 0;
            data.forEach((e) => {
              total = total + Number(e.attributes.DENUNCIOS);
              arrDataResumen.push({
                estado: e.attributes.LEYENDA,
                num_dm: e.attributes.DENUNCIOS,
                imagen: "DM_" + e.attributes.LEYENDA + ".png",
              } as never);
            });
            arrDataResumen.push({
              estado: "Total",
              num_dm: total,
              imagen: "",
            } as never);

            setDataResumen(arrDataResumen);

            setHideTableResultado(false);
          },
          function (error) {
            console.log(error);
          }
        );

      //catastroLayer.definitionExpression = queryWhere;
      utils.loadLayer(view, capa, subCapa, queryWhere);

      console.log(queryWhere);
      if (ubigeo.length > 0) {
        dataSource.setUrlQuery(
          "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer/0"
        );
        dataSource.setQueryWhere(queryWhere);
        dataSource.Search(false, false);
        console.log(dataSource);
        searching.dataSources.push(dataSource);
      }
    }
  }, [
    codDptoCatastroPorRegion,
    codProvCatastroPorRegion,
    codDistCatastroPorRegion,
    codHojaCatastroPorRegion,
  ]);

  return (
    <div id="divCatastroPorRegion">
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.preevaluacion.departamento")}
          <CalciteSelect
            id="cmbDptoCatastroPorRegion"
            label={t("screens.widgets.preevaluacion.departamento")}
            // onChange={loadProvinciasCatastroPorRegion();}
            // value={selectedDepartamento}
            onCalciteSelectChange={(e) =>
              setCodDptoCatastroPorRegion(e.target.value)
            }
          >
            {arrDepartamentosCatastroPorRegion.map(
              (option: any, idx: number) => {
                return (
                  <CalciteOption key={option.id} value={option.id}>
                    {option.name}
                  </CalciteOption>
                );
              }
            )}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel hidden={hideProvincia}>
          {t("screens.widgets.preevaluacion.provincia")}
          <CalciteSelect
            label={t("screens.widgets.preevaluacion.provincia")}
            id="cmbProvCatastroPorRegion"
            value={codProvCatastroPorRegion}
            onCalciteSelectChange={(e) =>
              setCodProvCatastroPorRegion(e.target.value)
            }
          >
            {arrProvinciasCatastroPorRegion.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.id}>
                  {option.name}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel hidden={hideDistrito}>
          {t("screens.widgets.preevaluacion.distrito")}
          <CalciteSelect
            label={t("screens.widgets.preevaluacion.distrito")}
            id="cmbDistCatastroPorRegion"
            onCalciteSelectChange={(e) =>
              setCodDistCatastroPorRegion(e.target.value)
            }
          >
            {arrDistritosCatastroPorRegion.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.id}>
                  {option.name}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel hidden={hideCuadrangulo}>
          {t("screens.widgets.preevaluacion.cuadrangulo")}
          <CalciteSelect
            label={t("screens.widgets.preevaluacion.cuadrangulo")}
            id="cmbHojaCatastroPorRegion"
            onCalciteSelectChange={(e) =>
              setCodHojaCatastroPorRegion(e.target.value)
            }
            //value={selectedOptionCuadranguloCatastroPorRegion}
            // disabled={selectedDisableCuadranguloCatastroPorRegion}
          >
            {arrHojaCatastroPorRegion.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.id}>
                  {option.name}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteTable
          caption="Resultados"
          id="resumenTable"
          scale="s"
          zebra
          hidden={hideTableResultado}
        >
          <CalciteTableRow slot="table-header">
            <CalciteTableHeader alignment="center" heading="Estado">
              {" "}
            </CalciteTableHeader>
            <CalciteTableHeader
              alignment="center"
              heading="N° DM"
            ></CalciteTableHeader>
            <CalciteTableHeader
              alignment="center"
              heading=""
            ></CalciteTableHeader>
          </CalciteTableRow>

          {dataResumen?.map((item: any, indexrow: number) => (
            <CalciteTableRow>
              <CalciteTableCell>{item.estado}</CalciteTableCell>
              <CalciteTableCell alignment="end">{item.num_dm}</CalciteTableCell>
              <CalciteTableCell alignment="center">
                {item.imagen != null && item.imagen != "" && (
                  <img src={process.env.PUBLIC_URL + "/img/" + item.imagen} />
                )}
              </CalciteTableCell>
            </CalciteTableRow>
          ))}
        </CalciteTable>
      </div>
    </div>
  );
}
