import React, { useEffect, useState, useRef } from "react";
import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalciteList,
  CalciteListItem,
  CalciteAction,
  CalciteSelect,
  CalciteOption,
} from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import esriRequest from "@arcgis/core/request";
import toast, { Toaster } from "react-hot-toast";
import { MainSearching } from "./MainSearching";
import { CatastroMinero } from "./CatastroMinero";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";

export function DescargaGeodatabase(props: any) {
  const { t } = useTranslation();
  //let view = props.view;
  let searching: MainSearching = props.data;
  const view = searching.view;

  const [arrayCapasDescarga, setArrayCapasDescarga] = useState([]);
  const [arrayFormatoDescarga, setArrayFormatoDescarga] = useState([]);
  useEffect(() => {
    function loadInitDescargaGeodatabase() {
      fnSelectParametros();
      /***************************** */
      const graphicsLayerDescargaGeodatabase = new GraphicsLayer();
      const sketchDescargaGeodatabase = new Sketch({
        layer: graphicsLayerDescargaGeodatabase,
        creationMode: "single",
        defaultUpdateOptions: {
          tool: "move",
        },
      });
      sketchDescargaGeodatabase.visibleElements = {
        createTools: {
          point: false,
          polyline: false,
          //   circle: false,
        },
        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
        settingsMenu: false,
        undoRedoMenu: false,
      };
      sketchDescargaGeodatabase.view = view;
      sketchDescargaGeodatabase.container =
        "sketch-container-DescargaGeodatabase";
      sketchDescargaGeodatabase.on("create", function (event) {
        if (event.state === "complete") {
          graphicsLayerDescargaGeodatabase.remove(event.graphic);

          view.graphics.add(event.graphic);
          sketchDescargaGeodatabase.complete();
          console.log(event.graphic);

          //fnGraficarDescargaGeodatabase(event.graphic.geometry);
        }
      });
    }
    async function fnSelectParametros() {
      console.log("fnSelectCapas");
      // setArrarrCamposConsultaGeologica([]);
      // setArrValoresConsultaGeologica([]);

      const serviceUrl =
        "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/GEOPROCESO/ExtractData/GPServer/Extract%20Data";

      const queryParams = {
        where: "1=1", // Modify the where clause as needed
        outFields: "*",
        f: "json", // You can change the output format if needed
      };

      const requestOptions = {
        query: queryParams,
        // headers: customHeaders
      };

      // Send the query request
      esriRequest(serviceUrl, requestOptions)
        .then((response) => {
          console.log(response);
          const arrCamposCG = response.data.parameters;

          arrCamposCG.forEach((e: any) => {
            switch (e.name) {
              case "Layers_to_Clip":
                console.log(e);

                const arrDataTemp = e.choiceList;
                const arrData: never[] = [];
                arrDataTemp.forEach((e: any) => {
                  arrData.push({
                    id: e,
                    name: e,
                  } as never);
                });
                setArrayCapasDescarga(arrData as never);
                break;
              case "Area_of_Interest":
                console.log(e);
                break;
              case "Feature_Format":
                console.log(e);

                const arrDataTemp1 = e.choiceList;
                const arrData1: never[] = [];
                arrDataTemp1.forEach((e: any) => {
                  arrData1.push({
                    id: e,
                    name: e,
                    select: e == e.defaultValue,
                  } as never);
                });
                setArrayFormatoDescarga(arrData1 as never);
                break;
              case "Raster_Format":
                console.log(e);
                break;
              case "Output_Zip_File":
                console.log(e);

                break;
            }
          });

          // Handle the response data here
          // setHideCamposConsultaGeologica(false);
          // setHideCamposValorConsultaGeologica(true);
          // setHideHerramientasConsultaGeologica(true);
          // setHideBuscarConsultaGeologica(true);

          // let cadena = "OBJECTID GLOBALID SHAPE SHAPE.LEN SHAPE.AREA";
          // arrCamposCG = response.data.fields;
          // const camposFilter = arrCamposCG.filter(
          //   (field) => cadena.indexOf(field.name) == -1
          // );
          // const arrData: never[] = [];
          // arrData.push(arrVacio[0] as never);
          // camposFilter.forEach((e) => {
          //   arrData.push({
          //     id: e.name,
          //     name: e.name,
          //   } as never);
          // });

          // setArrarrCamposConsultaGeologica(arrData as never);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Query Error:", error);
        });
    }
    loadInitDescargaGeodatabase();
  }, []);
  let LayerList = "";
  function fnExportar() {
    //console.log("Exportar datos");

    let FeatureFormat = "";
    let areaInteres = "" as any;

    const cmbFormatoDescarga = document.getElementById(
      "cmbFormatoDescarga"
    ) as HTMLSelectElement;
    console.log(cmbFormatoDescarga.value);
    FeatureFormat =
      cmbFormatoDescarga.value != null
        ? cmbFormatoDescarga.value
        : "File Geodatabase - GDB - .gdb";

    // console.log(LayerList);
    // console.log(areaInteres);
    // console.log(FeatureFormat);
  }
  const fnLayerList = (e: any) => {
    console.log(e);

    const arrData1 = [] as any;
    const arrCapas = e.target.selectedItems;

    arrCapas.forEach((e: any) => {
      arrData1.push(e.value);
    });
    LayerList = JSON.stringify(arrData1);
  };

  return (
    <>
      <div id="divDescargaGeodatabase">
        <div
          className="row py-2"
          style={{ height: "200px", overflow: "overlay" }}
        >
          <CalciteLabel>
            {t("screens.widgets.DescargaGeodatabase.capas")}
            <CalciteList
              selection-mode="multiple"
              id="lstCapas"
              onCalciteListChange={(e) => {
                fnLayerList(e);
              }}
            >
              {arrayCapasDescarga.map((option: any, idx: number) => {
                return (
                  <CalciteListItem
                    label={option.name}
                    //description="Designated routes for hikers to use."
                    value={option.id}
                  ></CalciteListItem>
                );
              })}
            </CalciteList>
          </CalciteLabel>
        </div>

        <div className="row py-2">
          <CalciteLabel>
            {t("screens.widgets.DescargaGeodatabase.area")}
            <div id="sketch-container-DescargaGeodatabase"></div>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <CalciteLabel>
            {t("screens.widgets.DescargaGeodatabase.formato")}
            <CalciteSelect id="cmbFormatoDescarga" label="Buffer">
              <CalciteOption
                value="File Geodatabase - GDB - .gdb"
                selected={true}
              >
                File Geodatabase - GDB - .gdb
              </CalciteOption>
              <CalciteOption value="Shapefile - SHP - .shp">
                Shapefile - SHP - .shp
              </CalciteOption>

              {arrayFormatoDescarga.map((option: any, idx: number) => {
                return (
                  <CalciteOption value={option.id} selected={option.select}>
                    {option.name}
                  </CalciteOption>
                );
              })}
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <div className="col-12 col-md d-flex">
            <CalciteButton
              iconStart="information"
              // onClick={() => fnAplicarBuffer()}
              className="ms-2"
            >
              {t("screens.widgets.DescargaGeodatabase.ayuda")}
            </CalciteButton>
          </div>
          <div className="col-12 col-md d-flex">
            <CalciteButton
              iconStart="gear"
              onClick={() => fnExportar()}
              className="ms-2"
            >
              {t("screens.widgets.DescargaGeodatabase.ejecutar")}
            </CalciteButton>
          </div>
        </div>
      </div>

      <Toaster />
    </>
  );
}
