import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import * as geoprocessor from "@arcgis/core/rest/geoprocessor";
import JobInfo from "@arcgis/core/rest/support/JobInfo";
import ParameterValue from "@arcgis/core/rest/support/ParameterValue";
import "./styleWidget.css";
import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalciteOption,
  CalciteSelect,
  CalciteProgress,
} from "@esri/calcite-components-react";
import { CustomPopupPDF } from "../../components/CustomPopupPDF";
import Draw from "@arcgis/core/views/draw/Draw";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as coordinateFormatter from "@arcgis/core/geometry/coordinateFormatter.js";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";
import * as projection from "@arcgis/core/geometry/projection";
import Point from "@arcgis/core/geometry/Point.js";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { CustomFunctions } from "../../utils/CustomFunctions";
import Polygon from "@arcgis/core/geometry/Polygon";
import { MainSearching } from "./MainSearching";
import { useAlert } from "../../core/providers/AlertProvider";

export function PlanoCatastralA4(props: any) {
  const { t } = useTranslation();
  //let view = props.view;
  let searching: MainSearching = props.data;
  const view = searching.view;

  const showAlert = useAlert();

  const utils = new CustomFunctions();
  const [txtCoordeEsteTemp, settxtCoordeEsteTemp] = useState("0.00");
  const [txtCoordeNorteTemp, settxtCoordeNorteTemp] = useState("0.00");
  const [txtSpTemp, setSpTemp] = useState("102100");
  //const [showLoading, setLoading] = useState(true);
  // const [txtCoordeEste, settxtCoordeEste] = useState("0.00");
  // const [txtCoordeNorte, settxtCoordeNorte] = useState("0.00");
  const [selectedOptionZona, setselectedOptionZona] = useState("18");
  const [selectedOptionBuffer, setselectedOptionBuffer] = useState("2");
  const [selectedOptionEscala, setselectedOptionEscala] = useState("25000");
  const [selectedOptionOrientacion, setselectedOptionOrientacion] =
    useState("V");

  const [txtXMax, settxtXMax] = useState(0);
  const [txtYMax, settxtYMax] = useState(0);
  const [txtXMin, settxtXMin] = useState(0);
  const [txtYMin, settxtYMin] = useState(0);
  const [planoCatastralPDF, setPlanoCatastralPDF] = useState("");

  // const [showAlert, setShowAlert] = useState(false);
  // const [messageAlert, setMessageAlert] = useState("");
  // const [typeAlert, setTypeAlert] = useState("brand");
  //const punto =new Point();
  useEffect(() => {
    coordinateFormatter.load();
    projection.load();

    const btnUbicaPunto = document.getElementById(
      "btnUbicaPunto"
    ) as HTMLSelectElement;

    const txtEste = document.getElementById("txtEste") as HTMLSelectElement;
    const txtNorte = document.getElementById("txtNorte") as HTMLSelectElement;

    const txtEsteMax = document.getElementById(
      "txtEsteMax"
    ) as HTMLSelectElement;
    const txtNorteMax = document.getElementById(
      "txtNorteMax"
    ) as HTMLSelectElement;
    const txtEsteMin = document.getElementById(
      "txtEsteMin"
    ) as HTMLSelectElement;
    const txtNorteMin = document.getElementById(
      "txtNorteMin"
    ) as HTMLSelectElement;

    const btnPlano = document.getElementById("btnPlano") as HTMLSelectElement;
    const btnUbicar = document.getElementById("btnUbicar") as HTMLSelectElement;
    const cboZona = document.getElementById("cboZona") as HTMLSelectElement;
    const cboBuffer = document.getElementById("cboBuffer") as HTMLSelectElement;
    const cboEscala = document.getElementById("cboEscala") as HTMLSelectElement;
    const cboOrientacion = document.getElementById(
      "cboOrientacion"
    ) as HTMLSelectElement;
    // cboZona.value = "18";
    // cboOrientacion.value = "V";
    // cboEscala.value = "25000";
    // cboBuffer.value = "2";

    btnUbicaPunto.addEventListener("click", (e) => {
      ubicarPunto();
    });

    btnPlano.addEventListener("click", (e) => {
      plano();
    });
    btnUbicar.addEventListener("click", (e) => {
      ubicar();
    });

    cboZona.addEventListener("calciteSelectChange", (e) => {
      console.log("cboZona");
      //fnChangeZona(cboZona.value);
      ubicar();
    });

    cboBuffer.addEventListener("calciteSelectChange", (e) => {
      console.log("cboBuffer");
      //fnChangeBuffer(cboBuffer.value);
      ubicar();
    });
    cboEscala.addEventListener("calciteSelectChange", (e) => {
      console.log("cboEscala");
      //fnChangeEscala(cboEscala.value);
      ubicar();
    });
    cboOrientacion.addEventListener("calciteSelectChange", (e) => {
      console.log("cboOrientacion");
      //fnChangeOrientacion(cboOrientacion.value);
      ubicar();
    });

    function createPointGraphic(coordinates: any[]) {
      try {
        showAlert({
          title: t("screens.menu.searching.planocatastrala4"),
          message: t("screens.mensajes.procesando"),
          type: "info",
          autoClose: true,
        });
        let sp = new SpatialReference({ wkid: view.spatialReference.wkid });
        //txtEste.value=
        // settxtCoordeEsteTemp(coordinates[0]);
        // settxtCoordeNorteTemp(coordinates[1]);
        // setSpTemp(view.spatialReference.wkid);

        let punto = new Point();
        punto.x = coordinates[0];
        punto.y = coordinates[1];
        punto.spatialReference = sp;

        const spOut = utils.getSpatialReference(4326);
        let centro = webMercatorUtils.project(punto, spOut);

        let loTxtPunto = coordinateFormatter.toUtm(
          centro as Point,
          "north-south-indicators",
          true
        );
        let loTxtPuntoArr = loTxtPunto.split(" ");
        txtEste.value = loTxtPuntoArr[1];
        txtNorte.value = loTxtPuntoArr[2];
        cboZona.value = loTxtPuntoArr[0].replace(/[^0-9]+/g, "");
        // settxtCoordeEste(loTxtPuntoArr[1]);
        // settxtCoordeNorte(loTxtPuntoArr[2]);
        // setselectedOptionZona(loTxtPuntoArr[0].replace(/[^0-9]+/g, ""));

        ubicar();
      } catch (error: any) {
        console.log(error);

        showAlert({
          title: t("screens.menu.searching.planocatastrala4"),
          message: error.message,
          type: "danger",
          autoClose: true,
        });
      }
    }

    // const fnChangeZona = (value: string) => {
    //   //setselectedOptionZona(value);
    //   ubicar();
    // };
    // const fnChangeBuffer = (value: string) => {
    //   setselectedOptionBuffer(value);
    //   ubicar();
    // };
    // const fnChangeEscala = (value: string) => {
    //   setselectedOptionEscala(value);
    //   ubicar();
    // };
    // const fnChangeOrientacion = (value: string) => {
    //   setselectedOptionOrientacion(value);
    //   ubicar();
    // };
    const draw = new Draw({
      view: view,
    });

    const ubicarPunto = () => {
      view.graphics.removeAll();
      let action = draw.create("point");
      action.on("cursor-update", function (evt) {
        //createPointGraphic(evt.coordinates);
      });
      action.on("draw-complete", function (evt) {
        createPointGraphic(evt.coordinates);
      });
    };

    //Opcion Ubicar
    const ubicar = () => {
      if (Number(txtEste.value) > 0 && Number(txtNorte.value) > 0) {
        //setLoading(false);
        try {
          view.graphics.removeAll();
          //Punto medio
          let bufferGraphic;
          const graphicsLayer = new GraphicsLayer();

          let PuntoBuffer = new Point();
          PuntoBuffer.longitude = Number(txtEste.value);
          PuntoBuffer.latitude = Number(txtNorte.value);
          PuntoBuffer.spatialReference = utils.getSpatialReference(
            Number("327" + cboZona.value)
          );

          let PuntoBufferProy = projection.project(
            PuntoBuffer,
            utils.getSpatialReference(Number(txtSpTemp))
          );

          const simpleMarkerSymbol = {
            type: "simple-marker",
            color: "red",
            style: "cross",
            size: "12px",
            outline: {
              color: [255, 255, 255],
              width: 1,
            },
          };

          const pointGraphic = new Graphic({
            geometry: PuntoBufferProy as Point,
            symbol: simpleMarkerSymbol,
          });
          //poligon Buffer
          const buffer = geometryEngine.geodesicBuffer(
            pointGraphic.geometry,
            //Number(selectedOptionBuffer),
            Number(cboBuffer.value),
            "kilometers"
          );

          let symbolBuffer = {
            type: "simple-fill",
            color: [250, 0, 0, 0.5],
            style: "solid",
            outline: {
              color: [250, 0, 0, 1],
              width: 1,
            },
          };

          bufferGraphic = new Graphic({
            geometry: buffer as Polygon,
            symbol: symbolBuffer,
          });

          //Area de Impresion

          let _w = 0;
          let _h = 0;
          let x = Number(txtEste.value);
          let y = Number(txtNorte.value);

          //if (selectedOptionOrientacion == "H") {
          if (cboOrientacion.value == "H") {
            _w = 27.7547;
            _h = 14.7724;
          } else {
            _w = 19.3741;
            _h = 22.8583;
          }

          let escala = Number(cboEscala.value); //selectedOptionEscala

          let w = (_w * (escala / 100)) / 2;
          let h = (_h * (escala / 100)) / 2;

          // let _addRingVertices = [];

          let _xMin = x - w;
          let _xMax = x + w;
          let _yMin = y - h;
          let _yMax = y + h;

          // settxtXMax(_xMax);
          // settxtXMin(_xMin);
          // settxtYMax(_yMax);
          // settxtYMin(_yMin);
          txtNorteMax.value = _yMax.toString();
          txtNorteMin.value = _yMin.toString();
          txtEsteMax.value = _xMax.toString();
          txtEsteMin.value = _xMin.toString();

          // _addRingVertices.push([_xMin, _yMin]);
          // _addRingVertices.push([_xMin, _yMax]);
          // _addRingVertices.push([_xMax, _yMax]);
          // _addRingVertices.push([_xMax, _yMin]);

          const rings = [
            [
              [_xMin, _yMin],
              [_xMin, _yMax],
              [_xMax, _yMax],
              [_xMax, _yMin],
            ],
          ];

          const polygon = new Polygon({
            rings: rings,
            spatialReference: { wkid: Number("327" + cboZona.value) },
          });

          let center = projection.project(
            polygon,
            utils.getSpatialReference(Number(txtSpTemp))
          );

          let symbolArea = {
            type: "simple-fill",
            color: [250, 0, 0, 0.5],
            style: "none",
            outline: {
              color: [250, 0, 0, 1],
              width: 1,
              style: "dash",
            },
          };

          let areaGraphic = new Graphic({
            geometry: center as Polygon,
            symbol: symbolArea,
          });

          //console.log(center);

          //Añadir graficos
          view.graphics.add(bufferGraphic);
          view.graphics.add(pointGraphic);
          view.graphics.add(areaGraphic);

          //zoom
          view
            .goTo({
              //center: [PuntoBufferProy.x, PuntoBufferProy.y],
              target: PuntoBufferProy as Point,
              zoom: 12,
            })
            .catch(function (error: any) {
              console.error(error);
            });
          //console.log(PuntoBuffer);
        } catch (error: any) {
          console.log(error);
          //setLoading(true);
          showAlert({
            title: t("screens.menu.searching.planocatastrala4"),
            message: error.message,
            type: "danger",
            autoClose: true,
          });
        }
      } else {
        showAlert({
          title: t("screens.menu.searching.planocatastrala4"),
          message: t("screens.mensajes.error"),
          type: "danger",
          autoClose: true,
        });
      }
    };

    //Opcion Plano y Listado
    const plano = () => {
      console.log("plano y Listado");

      showAlert({
        title: t("screens.menu.searching.planocatastrala4"),
        message: t("screens.mensajes.procesando"),
        type: "info",
        autoClose: true,
      });

      async function execute(): Promise<void> {
        try {
          //  setLoading(false);
          const gpUrl =
            "https://geocatminnube.ingemmet.gob.pe/arcgis/rest/services/GEOPROCESO/ImpresionRadio/GPServer/ImpresionRadio";
          //"https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/GEOPROCESO/ImpresionRadio/GPServer/ImpresionRadio";
          const params = {
            Este: txtEste.value,
            Norte: txtNorte.value,
            Zona: cboZona.value,
            Radio: cboBuffer.value, // selectedOptionBuffer,
            Escala: cboEscala.value, //selectedOptionEscala,
            Orientacion: cboOrientacion.value, //selectedOptionOrientacion,
            xMin: txtEsteMin.value,
            xMax: txtEsteMax.value,
            yMin: txtNorteMin.value,
            yMax: txtNorteMax.value,
          };
          console.log("Geoprocessor");
          const jobInfo: JobInfo = await geoprocessor.submitJob(gpUrl, params);
          console.log("waitForJobCompletion");
          await jobInfo.waitForJobCompletion({
            statusCallback: checkJobStatus,
          });

          console.log("fetchResultData");

          const result: ParameterValue = await jobInfo.fetchResultData(
            "codigo"
          );
          console.log("result");
          console.log(JSON.stringify(result.value));
          if (JSON.stringify(result.value).length > 5) {
            let archPDF =
              "https://geocatminnube.ingemmet.gob.pe/output/" +
              result.value +
              "_f.pdf#view=Fit";

            setPlanoCatastralPDF(archPDF);
            console.log(archPDF);

            const modal: any = document.getElementById("userCustomModalPDF");
            modal.open = true;
          } else {
            showAlert({
              title: t("screens.menu.searching.planocatastrala4"),
              message: t("screens.mensajes.procesando"),
              type: "info",
              autoClose: true,
            });
          }
        } catch (e) {
          console.log(e);
          fnMensaje("Error ....", "danger");
        }
      }

      function checkJobStatus(jobInfo: JobInfo): void {
        //console.log(jobInfo.jobStatus);
        showAlert({
          title: t("screens.menu.searching.planocatastrala4"),
          message: t("screens.mensajes.procesando"),
          type: "info",
          autoClose: true,
        });
      }
      execute();
    };
    function fnMensaje(mensaje: string, tipo: any) {
      showAlert({
        title: t("screens.menu.searching.planocatastrala4"),
        message: mensaje,
        type: tipo,
        autoClose: true,
      });
    }
  });
  return (
    <div id="planoCatastralDiv">
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.planocatastral.este")}
          <CalciteInput
            placeholder={t("screens.widgets.planocatastral.esteplaceholder")}
            type="number"
            id="txtEste"
            //value={txtCoordeEste}
            //onCalciteInputChange={(e) => settxtCoordeEste(e.target.value)}
          ></CalciteInput>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.planocatastral.norte")}
          <CalciteInput
            placeholder={t("screens.widgets.planocatastral.norteplaceholder")}
            type="number"
            id="txtNorte"
            // value={txtCoordeNorte}
            // onCalciteInputChange={(e) => settxtCoordeNorte(e.target.value)}
          ></CalciteInput>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.planocatastral.zona")}

          <CalciteSelect
            label={t("screens.widgets.planocatastral.zonaplaceholder")}
            // value={selectedOptionZona}
            id="cboZona"
            // onCalciteSelectChange={(e) => fnChangeZona(e.target.value)}
          >
            <CalciteOption value="17">WGS84 UTM Z17s</CalciteOption>
            <CalciteOption value="18">WGS84 UTM Z18s</CalciteOption>
            <CalciteOption value="19">WGS84 UTM Z19s </CalciteOption>
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.planocatastral.radio")}

          <CalciteSelect
            label={t("screens.widgets.planocatastral.radioplaceholder")}
            // value={selectedOptionBuffer}
            id="cboBuffer"
            // onCalciteSelectChange={(e) => fnChangeBuffer(e.target.value)}
          >
            <CalciteOption value="2">2 km</CalciteOption>
            <CalciteOption value="4">4 km</CalciteOption>
            <CalciteOption value="8">8 km</CalciteOption>
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.planocatastral.escala")}

          <CalciteSelect
            label={t("screens.widgets.planocatastral.escalaplaceholder")}
            // value={selectedOptionEscala}
            id="cboEscala"
            // onCalciteSelectChange={(e) => fnChangeEscala(e.target.value)}
          >
            <CalciteOption value="25000">1: 25,000</CalciteOption>
            <CalciteOption value="50000">1: 50,000</CalciteOption>
            <CalciteOption value="100000">1:100,000</CalciteOption>
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.planocatastral.orientacion")}

          <CalciteSelect
            label={t("screens.widgets.planocatastral.orientacionplaceholder")}
            // value={selectedOptionOrientacion}
            id="cboOrientacion"
            // onCalciteSelectChange={(e) => fnChangeOrientacion(e.target.value)}
          >
            <CalciteOption value="H">Horizontal</CalciteOption>
            <CalciteOption value="V">Vertical</CalciteOption>
          </CalciteSelect>
        </CalciteLabel>
      </div>
      {/* <div className="row pt-2"> */}
      <CalciteButton
        iconStart="pin"
        style={{ padding: "3px" }}
        label={t("screens.widgets.planocatastral.btnubicarpunto")}
        id="btnUbicaPunto"
        // onClick={() => ubicarPunto()}
        title="Ubica punto en el mapa"
      ></CalciteButton>
      <CalciteButton
        iconStart="feature-layer"
        label={t("screens.widgets.planocatastral.btnubicar")}
        style={{ padding: "3px" }}
        id="btnUbicar"
        // onClick={() => ubicar()}
      >
        {t("screens.widgets.planocatastral.btnubicar")}
      </CalciteButton>
      <CalciteButton
        iconStart="gear"
        label={t("screens.widgets.planocatastral.btnplano")}
        style={{ padding: "3px" }}
        id="btnPlano"
        // onClick={() => plano()}
      >
        {t("screens.widgets.planocatastral.btnplano")}
      </CalciteButton>
      {/* </div> */}
      <CustomPopupPDF
        title="Plano Catastral"
        url={planoCatastralPDF}
        id="userCustomModalPDF"
      ></CustomPopupPDF>

      <CalciteInput type="text" id="txtEsteMax" hidden></CalciteInput>
      <CalciteInput type="text" id="txtEsteMin" hidden></CalciteInput>
      <CalciteInput type="text" id="txtNorteMax" hidden></CalciteInput>
      <CalciteInput type="text" id="txtNorteMin" hidden></CalciteInput>
    </div>
  );
}
