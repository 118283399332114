import { ReactElement, createContext, useContext, useState } from "react";
import { AlertContext } from "./AlertContext";
import { CustomAlert } from "../interfaces/CustomAlert";
import { CalciteAlert } from "@esri/calcite-components-react";

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

export const AlertProvider = ({ children }: any) => {
  const [icon, setIcon] = useState("circle-disallowed");
  const [type, setType] = useState<
    "danger" | "info" | "success" | "warning" | undefined
  >("info");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [autoclose, setAutoclose] = useState(true);

  const showAlert = (alert: CustomAlert) => {
    setType(alert.type);
    setTitle(alert.title);
    setMessage(alert.message);
    setAutoclose(alert.autoClose);

    switch (alert.type) {
      case "warning":
        setIcon("exclamation-mark-triangle");
        break;
      case "success":
        setIcon("check-circle");
        break;
      case "info":
        setIcon("information");
        break;
      default:
        setIcon("circle-disallowed");
        break;
    }

    const modal: any = document.getElementById("userCustomAlert");
    modal.open = true;
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      <CalciteAlert
        icon={icon}
        kind={type}
        label="Custom Alert"
        id="userCustomAlert"
        autoCloseDuration="fast"
        //autoClose={autoclose}
        autoClose
      >
        <div slot="title" id="customAlertTitle">
          {title}
        </div>
        <div slot="message" id="customAlertMessage">
          {message}
        </div>
      </CalciteAlert>
    </AlertContext.Provider>
  );
};
