import { useEffect, useState } from "react";
import { CalciteButton, CalciteSwitch } from "@esri/calcite-components-react";
import { setAssetPath } from "@esri/calcite-components/dist/components";
import "../style.css";
import earth_intro_hd from "../../assets/videos/earth_intro_hd_mini.mp4";
import { useNavigate } from "react-router-dom";
import logoWhite from "../../assets/images/logo-geocatmin-white.png";
import logoIngemmet from "../../assets/images/logo-ingemmet-blanco.png";
import { CustomPopupHTML } from "../../components/CustomPopupHTML";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords, setStartIni } from "../../redux/slices/userActionsSlice";
import { useAlert } from "../../core/providers/AlertProvider";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { CustomFunctions } from "../../utils/CustomFunctions";

setAssetPath("https://js.arcgis.com/calcite-components/1.2.0/assets");

const IntroScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  dispatch(setRecords(0));

  const { t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("es");
  const [selectedLangBrowser, setSelectedLangBrowser] = useState(
    navigator.language
  );
  const utils = new CustomFunctions();
  const config = utils.loadconfig("config_intro");

  //leer archivo configuracion externo
  /*******************************/
  const [arrConfig, setArrConfig] = useState([]);
  const [arrConfigLink, setArrConfigLink] = useState([]);

  useEffect(() => {
    const configIntro = () => {
      fetch(`${process.env.PUBLIC_URL}/config/config_intro.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          //console.log(response);
          return response.json();
        })
        .then((data) => {
          //console.log(data);
          let arrConfigTemp: any[];
          arrConfigTemp = data.opciones;
          setArrConfig(arrConfigTemp as never);

          arrConfigTemp = data.links;
          setArrConfigLink(arrConfigTemp as never);
        })
        .catch((error) => console.error(error));
    };
    configIntro();
  }, []);

  const showAlert = useAlert();
  let showChecked = true;

  //console.log(navigator.language);
  try {
    const r = fetch(
      "https://geocatminapp.ingemmet.gob.pe/bdgeocientifica/app/api/account/IteracionService/8/geocatmin3d"
    );
  } catch (e) {
    console.log(e);
  }

  const MostrarPopup = () => {
    const modal: any = document.getElementById("userCustomModalHTML");
    modal.open = true;
  };

  const ChangeChecked = (value: boolean) => {
    showChecked = value;
  };

  const chkAceptar: any = document.getElementById("chkAceptar");
  const Ingresar = () => {
    if (chkAceptar?.checked || showChecked) {
      dispatch(setStartIni(true));
      navigate("main");
    } else {
      showAlert({
        title: "Términos y condiciones",
        message: "Debe aceptar los términos y condiciones para poder ingresar",
        type: "danger",
        autoClose: true,
      });
    }
  };
  const Descargar = (url: any) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (selectedLangBrowser != "es-ES") {
      setSelectedLang("en");
    }
  }, [selectedLangBrowser]);
  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang]);

  return (
    <div className="appContent" style={{ backgroundColor: "#000000" }}>
      <div className="bgVideo">
        <video loop autoPlay muted playsInline>
          <source src={earth_intro_hd} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="bgGradient"></div>
      </div>
      <div className="msgHome">
        <div className="row  divIntroCenter">
          <div className="col">
            <div className="logoHome mb-3 mb-md-4">
              <img src={logoWhite} />
            </div>
            <h1 className="fw-medium fs-5 mb-3 mb-md-4">
              {t("screens.intro.info")}
            </h1>
            <br />
            <ul className="ulNews ps-0 mb-3 mb-md-4">
              <li>{t("screens.intro.titNew")}:</li>
              <li className="movilOcular">
                {t("screens.intro.catastro")}{" "}
                <CalciteButton
                  class="mx-1"
                  scale="m"
                  splitChild="secondary"
                  onClick={(e) =>
                    Descargar(
                      "https://geocatminapp.ingemmet.gob.pe/complementos/Descargas/DESCARGA_WGS84/DESCARGA/CMI_WGS84_17S.zip"
                    )
                  }
                >
                  17
                </CalciteButton>
                <CalciteButton
                  class="mx-1"
                  scale="m"
                  onClick={(e) =>
                    Descargar(
                      "https://geocatminapp.ingemmet.gob.pe/complementos/Descargas/DESCARGA_WGS84/DESCARGA/CMI_WGS84_18S.zip"
                    )
                  }
                >
                  18
                </CalciteButton>
                <CalciteButton
                  class="mx-1"
                  scale="m"
                  onClick={(e) =>
                    Descargar(
                      "https://geocatminapp.ingemmet.gob.pe/complementos/Descargas/DESCARGA_WGS84/DESCARGA/CMI_WGS84_19S.zip"
                    )
                  }
                >
                  19
                </CalciteButton>
              </li>
              {arrConfig.map((option: any, idx: number) => {
                return (
                  <li>
                    {selectedLang == "es" ? option.title : option.titleIng}
                  </li>
                );
              })}
            </ul>

            <ul className="ulNews ps-0 mb-3 mb-md-4">
              <li>{t("screens.intro.titLink")}:</li>
              {arrConfigLink.map((option: any, idx: number) => {
                return (
                  <li>
                    <a
                      href={option.link}
                      target="_blank"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      {selectedLang == "es" ? option.title : option.titleIng}
                    </a>
                  </li>
                );
              })}
            </ul>

            <div className="col-md-16 d-flex align-items-center movilOcular">
              <p style={{ fontSize: "smaller", color: "#6a6a6a" }}>
                {t("screens.intro.importante")}
              </p>
            </div>

            <div className="row mb-md-4 d-flex align-items-center">
              <div className="col-md-8 d-flex align-items-center movilOcular">
                <CalciteSwitch
                  scale="m"
                  class="ms-0 mr-4"
                  id="chkAceptar"
                  checked
                  onCalciteSwitchChange={(e) => ChangeChecked(e.target.checked)}
                ></CalciteSwitch>
                <label className="terms">
                  {t("screens.intro.acepto")}{" "}
                  <a href="#" onClick={(e) => MostrarPopup()}>
                    {t("screens.intro.acepto1")}
                  </a>{" "}
                  {/* {t("screens.intro.acepto2")} */}
                </label>
              </div>
              <div className="col-md-4 mt-md-0 mt-3 div-center">
                <button
                  type="button"
                  id="btnIngresar"
                  className="btnHome-rb btn btn-primary btn-md"
                  onClick={(e) => Ingresar()}
                >
                  {t("screens.intro.ingresar")} &#8594;
                </button>
              </div>
            </div>

            <div className="col-md-8  copyIngemmet">
              <br />
              <img className="" src={logoIngemmet} />
              <span className="ms-2">- © 2023</span>
            </div>
          </div>

          <CustomPopupHTML
            title={t("screens.intro.tituloTerminos")} //"Términos y Condiciones"
            content={t("screens.intro.contenidoTerminos")}
            id="userCustomModalHTML"
          ></CustomPopupHTML>
        </div>
      </div>
    </div>
  );
};

export default IntroScreen;
