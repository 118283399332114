import { useEffect, useState } from "react";
import {
  CalciteButton,
  CalciteIcon,
  CalciteInput,
  CalciteLabel,
} from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import { QueryField } from "../../core/interfaces/QueryField";
import configCatastro from "./ConfigCatastro.json";
import { MainSearching } from "./MainSearching";
import { CustomDataSource } from "../CustomDataSource";
import { useAlert } from "../../core/providers/AlertProvider";

export function BuscarDM(props: any) {
  const { t } = useTranslation();
  let searching: MainSearching = props.data;
  const view = searching.view;
  let dataSource = new CustomDataSource(view, "Catastro");

  const [txtCodigo, setTxtCodigo] = useState("");
  const [txtName, setTxtName] = useState("");
  const [txtTitular, setTxtTitular] = useState("");

  const dispatch = useAppDispatch();
  const showAlert = useAlert();

  let arrFields: QueryField[] = [];
  arrFields = configCatastro.Catastro as any;
  dataSource.setFields(arrFields);

  /*const configCatastro = () => {
    fetch(`${process.env.PUBLIC_URL}/data/ConfigCatastro.json`, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(response => {
      return response.json();
    }).then(data => {
      arrFields = data.Catastro;
      dataSource.setFields(arrFields);
    })
    .catch(error => console.error(error));
  };*/

  useEffect(() => {
    //LLAMANDO A LA CONFIGURACIÓN JSON
    //configCatastro();

    if (dataSource.error != "") {
      showAlert({
        title: t("screens.menu.searching.searchdm"),
        message: "Ha ocurrido un error al conectarse al servicio",
        type: "warning",
        autoClose: true,
      });
    }
  }, [dataSource]);

  const searchTenements = () => {
    if (txtTitular == "" && txtName == "" && txtCodigo == "") {
      showAlert({
        title: t("screens.menu.searching.searchdm"),
        message: t("screens.widgets.searchdm.errorv"),
        type: "danger",
        autoClose: true,
      });
      return;
    } else {
      showAlert({
        title: t("screens.menu.searching.searchdm"),
        message: t("screens.home.loading"),
        type: "info",
        autoClose: true,
      });
      dispatch(setRecords(0));
      searching.dataSources = [];
      searching.setExecuted(false);

      let queryWhere = "";
      if (txtCodigo.toUpperCase().length > 0)
        queryWhere =
          queryWhere +
          " upper(CODIGOU) LIKE '%" +
          txtCodigo.toUpperCase() +
          "%' AND ";

      if (txtTitular.toUpperCase().length > 0)
        queryWhere =
          queryWhere +
          "upper(TIT_CONCES) LIKE '%" +
          txtTitular.toUpperCase() +
          "%' AND ";

      if (txtName.toUpperCase().length > 0)
        queryWhere =
          queryWhere +
          "upper(CONCESION) LIKE '%" +
          txtName.toUpperCase() +
          "%' AND";

      queryWhere = queryWhere.substring(0, queryWhere.length - 4);
      dataSource.setUrlQuery(
        "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/SERV_CATASTRO_MINERO_WGS84/MapServer/0"
      );
      dataSource.setQueryWhere(queryWhere);
      dataSource.Search(true, true);
      searching.dataSources.push(dataSource);
    }
  };

  function fnKeyEnter(event: any) {
    if (event.keyCode == 13) {
      searchTenements();
    }
  }

  const clearTenements = () => {
    setTxtCodigo("");
    setTxtName("");
    setTxtTitular("");
    dataSource.Clear();
  };

  return (
    <>
      <div>
        {searching != null && (
          <>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.searchdm.code")}

                <CalciteInput
                  placeholder={t("screens.widgets.searchdm.codeph")}
                  type="text"
                  value={txtCodigo}
                  onKeyUp={(e) => fnKeyEnter(e)}
                  onCalciteInputChange={(e) => setTxtCodigo(e.target.value)}
                ></CalciteInput>
              </CalciteLabel>
            </div>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.searchdm.name")}
                <CalciteInput
                  placeholder={t("screens.widgets.searchdm.nameph")}
                  onKeyUp={(e) => fnKeyEnter(e)}
                  type="text"
                  value={txtName}
                  onCalciteInputChange={(e) => setTxtName(e.target.value)}
                ></CalciteInput>
              </CalciteLabel>
            </div>
            <div className="row pt-2">
              <CalciteLabel>
                {t("screens.widgets.searchdm.titular")}
                <CalciteInput
                  placeholder={t("screens.widgets.searchdm.titularph")}
                  type="text"
                  onKeyUp={(e) => fnKeyEnter(e)}
                  value={txtTitular}
                  onCalciteInputChange={(e) => setTxtTitular(e.target.value)}
                ></CalciteInput>
              </CalciteLabel>
            </div>
            <div className="row py-2">
              <div className="col-12 col-md d-flex">
                <CalciteButton
                  id="btnClear"
                  iconStart="reset"
                  label={t("screens.widgets.searchdm.btnclean")}
                  onClick={() => clearTenements()}
                  appearance="transparent"
                >
                  {t("screens.widgets.searchdm.btnclean")}{" "}
                </CalciteButton>
              </div>
              <div className="col-12 col-md d-flex">
                <CalciteButton
                  id="btnSearch"
                  iconStart="search"
                  label={t("screens.widgets.searchdm.btnsearch")}
                  onClick={() => searchTenements()}
                  className="ms-2"
                >
                  {t("screens.widgets.searchdm.btnsearch")}
                </CalciteButton>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
