import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalciteTab,
  CalciteTabNav,
  CalciteTabTitle,
  CalciteTabs,
} from "@esri/calcite-components-react";
import { ChangeEvent, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import request from "@arcgis/core/request";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Field from "@arcgis/core/layers/support/Field";
import KMLLayer from "@arcgis/core/layers/KMLLayer.js";

export function ImportShapefile(view: any) {
  const { t } = useTranslation();

  const [txtNotify, setTxtNotify] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const portalUrl = "https://www.arcgis.com";

  const [selectSHP, setSelectSHP] = useState(true);
  const [selectKML, setSelectKML] = useState(false);

  const featureLayerRef = useRef(null);

  const handleFileChange = (target: any) => {
    if (target.files) {
      setFile(target.files[0]);
    } else {
      setTxtNotify("Seleccione un archivo!");
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      setTxtNotify("Seleccione un archivo!");
      return;
    }

    const fileName = file.name.toLocaleLowerCase();
    if (fileName.indexOf(".zip") !== -1) {
      generateFeatureCollection(fileName);
    } else {
      setTxtNotify("Agregar un shapefile en formato .zip");
    }
  };
  const handleUploadClickKml = () => {
    if (!file) {
      setTxtNotify("Seleccione un archivo!");
      return;
    }

    const fileName = file.name.toLocaleLowerCase();
    setTxtNotify("Cargando " + fileName + "...");
    if (fileName.indexOf(".kml") !== -1 || fileName.indexOf(".kmz") !== -1) {
      //generateFeatureCollection(fileName);
      const formData = new FormData();
      formData.append("file", file);
      fetch(
        "https://geocatmin.ingemmet.gob.pe/geoservicios/Archivo/subirArchivo",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((result) => {
          //console.log("success", result);
          //console.log("graficar archivo kml - " + fileName);
          if (featureLayerRef.current) {
            //view.map.remove(featureLayerRef.current);
            view?.view.map.layers.remove(featureLayerRef.current);
          }
          setTxtNotify("Graficando " + fileName + "...");
          const kmlLayer = new KMLLayer({
            url:
              "https://geocatmin.ingemmet.gob.pe/geoservicios/archivos/" +
              fileName,
            title: "KML Layer",
          });

          view?.view.map.layers.add(kmlLayer);

          setTxtNotify("");
          featureLayerRef.current = kmlLayer as any;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setTxtNotify("Agregar un archivo en formato .kml o .kmz");
    }
  };

  const generateFeatureCollection = (fileName: string) => {
    let name: any = fileName.split(".");
    name = name[0].replace("c:\\fakepath\\", "");
    setTxtNotify("Cargando " + name + "...");

    // define the input params for generate see the rest doc for details
    // https://developers.arcgis.com/rest/users-groups-and-items/generate.htm
    const params = {
      name: name,
      targetSR: view?.view.spatialReference,
      maxRecordCount: 1000,
      enforceInputFileSizeLimit: true,
      enforceOutputJsonSizeLimit: true,
      // generalize features to 10 meters for better performance
      generalize: true,
      maxAllowableOffset: 10,
      reducePrecision: true,
      numberOfDigitsAfterDecimal: 0,
    };

    const myContent = {
      filetype: "shapefile",
      publishParameters: JSON.stringify(params),
      f: "json",
    };

    // use the REST generate operation to generate a feature collection from the zipped shapefile
    const myForm = document.getElementById("uploadFormShp") as HTMLFormElement;
    request(portalUrl + "/sharing/rest/content/features/generate", {
      query: myContent,
      body: myForm,
      responseType: "json",
    })
      .then((response) => {
        const layerName =
          response.data.featureCollection.layers[0].layerDefinition.name;
        setTxtNotify(layerName + " cargado.");
        addShapefileToMap(response.data.featureCollection);
      })
      .catch(errorHandler);
  };

  const errorHandler = (error: any) => {
    console.log(error);
    setTxtNotify(error.message);
  };

  const addShapefileToMap = (featureCollection: any) => {
    let sourceGraphics: any[] = [];

    const layers = featureCollection.layers.map((layer: any) => {
      const graphics = layer.featureSet.features.map((feature: any) => {
        return Graphic.fromJSON(feature);
      });
      sourceGraphics = sourceGraphics.concat(graphics);
      const featureLayer = new FeatureLayer({
        objectIdField: "FID",
        source: graphics,
        fields: layer.layerDefinition.fields.map((field: any) => {
          return Field.fromJSON(field);
        }),
      });
      return featureLayer;
      // associate the feature with the popup on click to enable highlight and zoom to
    });
    view?.view.map.addMany(layers);
    view?.view.goTo(sourceGraphics).catch((error: any) => {
      if (error.name != "AbortError") {
        console.error(error);
      }
    });

    setFile(null);
    setTxtNotify("");
  };

  const fnCambioTab = (e: any) => {
    if (e.selectedTabId == 0) {
      setSelectSHP(true);
      setSelectKML(false);
    } else {
      setSelectSHP(false);
      setSelectKML(true);
    }
  };
  return (
    <div>
      <CalciteTabs>
        <CalciteTabNav
          slot="title-group"
          // onCalciteTabChange={(e) => {
          //   fnCambioTab(e);
          // }}
        >
          <CalciteTabTitle id="Shapefile">Shapefile</CalciteTabTitle>
          <CalciteTabTitle id="KML">KML</CalciteTabTitle>
        </CalciteTabNav>
        <CalciteTab>
          <div style={{ padding: "6px" }}>
            {t("screens.widgets.importmap.titleshp")}.<br />
            {t("screens.widgets.importmap.noteshp")}
            <br />
            <br />
            <form
              encType="multipart/form-data"
              method="POST"
              id="uploadFormShp"
            >
              {/*<CalciteInput type="file" onCalciteInputChange={ (e) => handleFileChange(e.target) } id="inFile" name="file"></CalciteInput>*/}
              <CalciteLabel
                style={{
                  padding: "5px",
                  borderStyle: "dashed solid",
                  borderWidth: "1px",
                }}
              >
                <input
                  type="file"
                  name="file"
                  accept=".zip"
                  onChange={(e) => handleFileChange(e.target)}
                  id="inFile"
                />
              </CalciteLabel>
            </form>
            <div className="text-center" style={{ marginTop: "8px" }}>
              <CalciteButton
                iconStart="upload"
                onClick={() => handleUploadClick()}
              >
                Cargar archivo
              </CalciteButton>
              <span
                className="file-upload-status"
                style={{ opacity: "1" }}
                id="upload-status"
              >
                <p style={{ color: "red", paddingTop: "8px" }}>{txtNotify}</p>
              </span>
            </div>
          </div>
        </CalciteTab>
        <CalciteTab>
          <div style={{ padding: "6px" }}>
            {t("screens.widgets.importmap.titlekml")}.<br />
            {t("screens.widgets.importmap.notekml")}
            <br />
            <br />
            <form
              encType="multipart/form-data"
              method="POST"
              id="uploadFormKml"
            >
              <CalciteLabel
                style={{
                  padding: "5px",
                  borderStyle: "dashed solid",
                  borderWidth: "1px",
                }}
              >
                <input
                  type="file"
                  name="file"
                  accept=".kml, .kmz"
                  onChange={(e) => handleFileChange(e.target)}
                  id="inFile"
                />
              </CalciteLabel>
            </form>
            <div className="text-center" style={{ marginTop: "8px" }}>
              <CalciteButton
                iconStart="upload"
                onClick={() => handleUploadClickKml()}
              >
                Cargar archivo KML
              </CalciteButton>
              <span
                className="file-upload-status"
                style={{ opacity: "1" }}
                id="upload-status"
              >
                <p style={{ color: "red", paddingTop: "8px" }}>{txtNotify}</p>
              </span>
            </div>
          </div>
        </CalciteTab>
      </CalciteTabs>
    </div>
  );
}
