import {
  CalciteButton,
  CalciteIcon,
  CalciteList,
  CalciteListItem,
  CalcitePopover,
  CalciteTab,
  CalciteTabNav,
  CalciteTabTitle,
  CalciteTabs,
} from "@esri/calcite-components-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../redux/hooks";
import { setRecords, setSelectedTable } from "../redux/slices/userActionsSlice";
import { MainSearching } from "./widgets/MainSearching";
import { CustomDataTable } from "./CustomDataTable";
import { CustomDataSource } from "./CustomDataSource";
import { CustomPopupFilter } from "./CustomPopupFilter";
import { useAlert } from "../core/providers/AlertProvider";
import { useUserActionsSelector } from "../redux/hooks/userActionsHook";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

export function TableResult(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showAlert = useAlert();
  const { records } = useUserActionsSelector();
  
  let searching: MainSearching = props.data;
  let view: any = searching.view;
  const [tables, setTables] = useState<number>(0);
  const { tableName } = useUserActionsSelector();

  useEffect(() => {
    reactiveUtils.watch(
      () => view.stationary,
      (stationary) => {
        if (stationary) {
          //ALGORIMO PARA APLICAR FILTRO POR EXTENT
          if(searching.dataSources.length > 0 && tableName !== ""){
            searching.dataSources?.map((item: CustomDataSource, index:number) => {
              if(item.name == tableName && item.filterExtent){
                //console.log("FILTRO POR EXTENT", tableName);
                let curGeom = view.extent;
                searching.dataSources[index].setQueryGeom(curGeom);
                searching.dataSources[index].Search(false, true, searching);
                searching.setExecuted(false);
                dispatch(setRecords(searching.records));
              }
            });
          }

          //ALGORIMO PARA MOSTRAR LA TABLA DE RESULTADOS
          if(searching.dataSources.length > 0 && !searching.executed){
            let total = 0;
            let executed = false;
            let filterExtent = false;
            searching.dataSources.forEach((e: CustomDataSource) =>{
              total = total + e.result.length;
              executed = e.executed;
              filterExtent = e.filterExtent;
            });
  
            if(executed || filterExtent){
              setTables(searching.dataSources.length);
              if(!filterExtent){
                searching.setExecuted(executed);
                dispatch(setRecords(total));
                searching.setRecords(total);
              }
              
              console.log("TOTAL REGISTROS EN RESULTADO:", total);
              if(total == 0 && !filterExtent){
                showAlert({title: "Resultados de búsqueda", message: "No se encontraron resultados para su búsqueda", type: "warning", autoClose: true});
              }
            }
          }
        }
      }
    );
  }, [props, records]);

  const PopupTableFilter = (tableName: string, index: number) => {
    const popMenu: any = document.getElementById("popTableOptions" + index.toString());
    popMenu.open = false;

    dispatch(setSelectedTable(tableName));
    const modal: any = document.getElementById("popTableFilter");
    modal.open = true;
  }

  const ClearTableFilter = (tableName: string, index: number) => {
    searching.dataSources?.map((item: CustomDataSource, index:number) => {
      if(item.name == tableName){
        searching.dataSources[index].setFilterExtent(false);
        searching.dataSources[index].ClearQueryFilter();
        searching.dataSources[index].Search(true, true);
      }
    });
    searching.setExecuted(false);

    const popMenu: any = document.getElementById("popTableOptions" + index.toString());
    popMenu.open = false;
  }

  const ExportData = (item: any, index: number) => {
    item.ExportXls();

    const popMenu: any = document.getElementById("popTableOptions" + index.toString());
    popMenu.open = false;
  }

  const CerrarTabla = () => {
    searching.dataSources = [];
    searching.setRecords(0);
    dispatch(setRecords(0));
    dispatch(setSelectedTable(""));
    setTables(0);
  };

  const SeleccionarTab = (tableName: string) => {
    console.log(tableName);
    searching.dataSources?.map((item: CustomDataSource, index:number) => {
      if(item.name == tableName){
        searching.dataSources[index].Search(!item.filterExtent, true);
        searching.setExecuted(false);
      }
    });
  }

  return (
    <div>
    {tables > 0 && searching.dataSources.length > 0 &&
      <CalciteTabs>
        <CalciteTabNav slot="title-group">
          {
            searching.dataSources?.map((item: any, index:number) =>(
              //item.result.length > 0 &&  
              (index == 0 ?
              <CalciteTabTitle key={index} selected><div onClick={(e) => SeleccionarTab(item.name)}>{item.name}</div>
                <CalciteIcon icon="handle-vertical" scale="s" style={{marginLeft: "8px"}} id={"navSource" + index.toString()}/>
                <CalcitePopover id={"popTableOptions" + index.toString()} label="Menu" referenceElement={"navSource" + index.toString()} pointerDisabled placement="bottom-start" scale="s">
                  <CalciteList>
                    <CalciteListItem label="Exportar data" onClick={(e) => ExportData(item, index)}>
                      <CalciteIcon
                        slot="actions-start"
                        scale="s"
                        icon="export"
                        style={{paddingLeft: "5px"}} 
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label="Aplicar filtro"
                      onClick={(e) => PopupTableFilter(item.name, index)}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="s"
                        icon="filter"
                        style={{paddingLeft: "5px"}} 
                      />
                    </CalciteListItem>
                    <CalciteListItem
                        label="Limpiar filtros"
                        onClick={(e) => ClearTableFilter(item.name, index)}
                      >
                        <CalciteIcon
                          slot="actions-start"
                          scale="s"
                          icon="reset"
                          style={{paddingLeft: "5px"}} 
                        />
                    </CalciteListItem>
                  </CalciteList>
                </CalcitePopover>
              </CalciteTabTitle>
              :
              <CalciteTabTitle key={index}><div onClick={(e) => SeleccionarTab(item.name)}>{item.name}</div>
                <CalciteIcon icon="handle-vertical" scale="s" style={{marginLeft: "8px"}} id={"navSource" + index.toString()}/>
                <CalcitePopover id={"popTableOptions" + index.toString()} label="Menu" referenceElement={"navSource" + index.toString()} pointerDisabled placement="bottom-start" scale="s">
                  <CalciteList>
                  <CalciteListItem label="Exportar data" onClick={(e) => ExportData(item, index)}>
                      <CalciteIcon
                        slot="actions-start"
                        scale="s"
                        icon="export"
                        style={{paddingLeft: "5px"}} 
                      />
                    </CalciteListItem>
                    <CalciteListItem
                      label="Aplicar filtro"
                      onClick={(e) => PopupTableFilter(item.name, index)}
                    >
                      <CalciteIcon
                        slot="actions-start"
                        scale="s"
                        icon="filter"
                        style={{paddingLeft: "5px"}} 
                      />
                    </CalciteListItem>
                    <CalciteListItem
                        label="Limpiar filtros"
                        onClick={(e) => ClearTableFilter(item.name, index)}
                      >
                        <CalciteIcon
                          slot="actions-start"
                          scale="s"
                          icon="reset"
                          style={{paddingLeft: "5px"}} 
                        />
                    </CalciteListItem>
                  </CalciteList>
                </CalcitePopover>
              </CalciteTabTitle>
            )))
          }
          <CalciteTabTitle>
            <CalciteButton
              kind="neutral"
              icon-start="x"
              onClick={(e) => CerrarTabla()}
              title="Cerrar"
            ></CalciteButton>
          </CalciteTabTitle>
        </CalciteTabNav>
        {
          searching.dataSources?.map((item: CustomDataSource, index:number) =>(
            //item.result.length > 0 && 
            (index == 0 ?
            <CalciteTab key={index} selected style={{overflowX:"hidden", width:"100%"}} id={"tabResult" + index.toString()}>
              <CustomDataTable view={view} dataSource={item}></CustomDataTable>
            </CalciteTab>
            :
            <CalciteTab key={index} id={"tabResult" + index.toString()}>
              <CustomDataTable view={view} dataSource={item}></CustomDataTable>
            </CalciteTab>
          )))
        }
      </CalciteTabs>
    }
      <CustomPopupFilter id="popTableFilter" data={searching}></CustomPopupFilter>
    </div>
  );
}
