import React, { useEffect, useState, useRef } from "react";
import {
  CalciteButton,
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
  CalciteInputNumber,
  CalciteCheckbox,
} from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../core/providers/AlertProvider";
import { CustomFunctions } from "../../utils/CustomFunctions";
import Point from "@arcgis/core/geometry/Point";
import * as coordinateFormatter from "@arcgis/core/geometry/coordinateFormatter.js";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";
import * as projection from "@arcgis/core/geometry/projection";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

import FeaturesViewModel from "@arcgis/core/widgets/Features/FeaturesViewModel.js";
import Popup from "@arcgis/core/widgets/Popup";

//Export
export const UbicarCoordenada = (props: any) => {
  const { t } = useTranslation();
  const showAlert = useAlert();
  const featureLayerRef = useRef(null);
  const view = props.view;
  const utils = new CustomFunctions();

  const [txtEsteUbicarCoordenada, setTxtEsteUbicarCoordenada] = useState("0");
  const [txtNorteUbicarCoordenada, setTxtNorteUbicarCoordenada] = useState("0");
  const [zonaUbicarCoordenada, setZonaUbicarCoordenada] = useState("4326");
  const [capturarCoordenada, setCapturarCoordenada] = useState(false);
  const [graficar, setGraficar] = useState(false);

  let featuresViewModel = new FeaturesViewModel();
  coordinateFormatter.load();
  projection.load();
  // Create a Popup
  //   var popup = new Popup({
  //     visible: true, // Set this to true to initially show the popup
  //     view: view,
  //   });

  //   view.popup = popup;

  const fnKeyEnterUbicarCoordenada = (event: any) => {
    if (event.keyCode == 13) {
      console.log("tecla enter");
    }
  };
  const fnGraficarCoordenada = () => {
    if (
      Number(txtEsteUbicarCoordenada) != 0 ||
      Number(txtNorteUbicarCoordenada) != 0
    ) {
      fnGraficarCoordenadaDraw(
        Number(txtEsteUbicarCoordenada),
        Number(txtNorteUbicarCoordenada),
        Number(zonaUbicarCoordenada)
      );
    } else {
      showAlert({
        title: "Ubicación de Coordenadas",
        message: "Por favor ingrese coordenadas validas",
        type: "danger",
        autoClose: true,
      });
    }
  };
  function fnGraficarCoordenadaDraw(x: number, y: number, zona: number) {
    //view.graphics.removeAll();

    let pointGraf = new Point();
    pointGraf.x = x;
    pointGraf.y = y;
    pointGraf.z = 1000;
    pointGraf.spatialReference = utils.getSpatialReference(zona);

    const spOut = utils.getSpatialReference(view.spatialReference.wkid);
    //let centro = webMercatorUtils.project(pointGraf, spOut);
    let centro = projection.project(pointGraf, spOut) as Point;
    const pointGraphic = new Graphic({
      geometry: centro as Point,
      symbol: utils.simpleMarkerSymbol(),
    });
    console.log(pointGraphic);

    if (featureLayerRef.current) {
      view.map.remove(featureLayerRef.current);
    }

    let graphicsLayerResultado = new GraphicsLayer();
    graphicsLayerResultado.title = "graphicsLayerResultado";
    graphicsLayerResultado.id = "id";
    graphicsLayerResultado.listMode = "hide";
    graphicsLayerResultado.add(pointGraphic);

    featureLayerRef.current = graphicsLayerResultado as any;
    view.map.add(graphicsLayerResultado);
    //if (chkZoom.value) {
    //if (showCheckedZoom) {
    utils.goto(view, pointGraphic);
    //utils.goToLocation(view,pointGraphic.geometry.x,pointGraphic.geometry.y,1000)
    //view.popup.visible = true;
    //}
  }
  const setCapturarCoord = () => {
    //console.log("Capturar coordenadas");
    setCapturarCoordenada(true);
  };
  //evento de captura de coordenada
  useEffect(() => {
    view.on("click", (event: any) => {
      console.log(capturarCoordenada);
      if (capturarCoordenada) {
        //popup.visible = false;
        try {
          const lstZonaUbicarCoordenada = document.getElementById(
            "lstZonaUbicarCoordenada"
          ) as HTMLSelectElement;

          const x = event.mapPoint.x;
          const y = event.mapPoint.y;

          //fnZona(x, y);
          let pointDraw = new Point();
          pointDraw.x = x;
          pointDraw.y = y;
          pointDraw.spatialReference = utils.getSpatialReference(
            view.spatialReference.wkid
          );

          const spOut = utils.getSpatialReference(4326);
          let centro = webMercatorUtils.project(pointDraw, spOut);

          let EsteUbicarCoordenada = "0";
          let NorteUbicarCoordenada = "0";

          //console.log(centro.get("x"));
          //console.log(centro.get("y"));

          if (lstZonaUbicarCoordenada.value == "4326") {
            EsteUbicarCoordenada = centro.get("x");
            NorteUbicarCoordenada = centro.get("y");
            //fnGraficarCoordenada(centro.get("x"), centro.get("y"), 4326);
          } else {
            let loTxtPunto = coordinateFormatter.toUtm(
              centro as Point,
              "north-south-indicators",
              true
            );
            let loTxtPuntoArr = loTxtPunto.split(" ");
            let txtZona = loTxtPuntoArr[0].replace(/[^0-9]+/g, "");
            const spOutQuery = utils.getSpatialReference(
              Number("327" + txtZona)
            );
            //let pointJson = centro.toJSON();

            let geomQueryTemp = projection.project(centro, spOutQuery) as Point;
            let pointJson = geomQueryTemp.toJSON();
            EsteUbicarCoordenada = pointJson["x"];
            NorteUbicarCoordenada = pointJson["y"];
            setZonaUbicarCoordenada("327" + txtZona);
          }

          setTxtEsteUbicarCoordenada(EsteUbicarCoordenada);
          setTxtNorteUbicarCoordenada(NorteUbicarCoordenada);
          setCapturarCoordenada(false);

          setGraficar(true);
        } catch (e) {
          console.log(e);
        }
      }
    });
  }, [capturarCoordenada]);
  useEffect(() => {
    if (graficar) {
      const btnUbicar = document.getElementById(
        "btnUbicar"
      ) as HTMLSelectElement;

      btnUbicar.click();
      setGraficar(false);
      //   try {
      //     //view.popup.destroy();
      //     view.popup.close();
      //   } catch (e) {
      //     console.log(e);
      //   }
    }
  }, [graficar]);
  //Captura la coordenada de la pantalla
  //   useEffect(() => {
  //     setZonaUbicarCoordenada("4326");
  //   }, []);

  //Grafica el punto en el mapa segun los datos ingresados
  //   useEffect(() => {
  //     console.log("Graficar");
  //   }, [txtEsteUbicarCoordenada, txtNorteUbicarCoordenada, zonaUbicarCoordenada]);
  //   useEffect(() => {
  //     console.log(zonaUbicarCoordenada);
  //   }, [zonaUbicarCoordenada]);
  return (
    <>
      <div id="divUbicarCoordenada">
        <CalciteLabel>
          {t("screens.widgets.UbicarCoordenada.ingrese")}
        </CalciteLabel>

        <div className="row py-2">
          <CalciteLabel>
            {t("screens.widgets.TransformarCoordenadas.zona")}

            <CalciteSelect
              label={t("screens.widgets.TransformarCoordenadas.zona")}
              id="lstZonaUbicarCoordenada"
              value={zonaUbicarCoordenada}
              onCalciteSelectChange={(e) => {
                setZonaUbicarCoordenada(e.target.value);
              }}
            >
              <CalciteOption value="4326">GCS_WGS_1984_Decimales</CalciteOption>
              <CalciteOption value="32717">WGS 1984 UTM Zone_17S</CalciteOption>
              <CalciteOption value="32718">WGS 1984 UTM Zone_18S</CalciteOption>
              <CalciteOption value="32719">WGS 1984 UTM Zone_19S</CalciteOption>
            </CalciteSelect>
          </CalciteLabel>
        </div>

        <div className="row py-2">
          <div className="col-12 col-md d-flex">
            <CalciteLabel>
              {Number(zonaUbicarCoordenada) == 4326
                ? t("screens.widgets.UbicarCoordenada.longitud")
                : t("screens.widgets.TransformarCoordenadas.este")}

              <CalciteInputNumber
                // placeholder={
                //   Number(zonaUbicarCoordenada) == 4326
                //     ? t("screens.widgets.UbicarCoordenada.longitud")
                //     : t("screens.widgets.TransformarCoordenadas.este")
                // }
                alignment="end"
                id="txtEste"
                number-button-type="none"
                status="idle"
                step="any"
                value={txtEsteUbicarCoordenada}
                onKeyUp={(e) => fnKeyEnterUbicarCoordenada(e)}
                onCalciteInputNumberChange={(e) =>
                  setTxtEsteUbicarCoordenada(e.target.value)
                }
              ></CalciteInputNumber>
            </CalciteLabel>
          </div>
          <div className="col-12 col-md d-flex">
            <CalciteLabel>
              {Number(zonaUbicarCoordenada) == 4326
                ? t("screens.widgets.UbicarCoordenada.latitud")
                : t("screens.widgets.TransformarCoordenadas.norte")}

              <CalciteInputNumber
                // placeholder={
                //   Number(zonaUbicarCoordenada) == 4326
                //     ? t("screens.widgets.UbicarCoordenada.latitud")
                //     : t("screens.widgets.TransformarCoordenadas.norte")
                // }
                alignment="end"
                id="txtNorte"
                number-button-type="none"
                status="idle"
                step="any"
                value={txtNorteUbicarCoordenada}
                onKeyUp={(e) => fnKeyEnterUbicarCoordenada(e)}
                onCalciteInputNumberChange={(e) =>
                  setTxtNorteUbicarCoordenada(e.target.value)
                }
              ></CalciteInputNumber>
            </CalciteLabel>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-12 col-md d-flex">
            <CalciteButton
              iconStart="pin"
              onClick={() => setCapturarCoord()}
              id="btnCapturar"
              className="ms-2"
            >
              {t("screens.widgets.UbicarCoordenada.capturar")}
            </CalciteButton>
          </div>
          <div className="col-12 col-md d-flex">
            <CalciteButton
              id="btnUbicar"
              iconStart="pin-tear"
              onClick={() => fnGraficarCoordenada()}
              className="ms-2"
            >
              {t("screens.widgets.UbicarCoordenada.ubicar")}
            </CalciteButton>
          </div>
        </div>
      </div>
      {/* <div className="col-12 col-md d-flex">
        <CalciteLabel layout="inline">
          <CalciteCheckbox
            id="chkZoom"
            scale="m"
            // ref={selectRef}
            // onCalciteCheckboxChange={(e) => fnChkZoom(e.target.checked)}
          ></CalciteCheckbox>
          {t("screens.mensajes.zoom")}
        </CalciteLabel>
      </div> */}
    </>
  );
};
