import { executeQueryJSON } from "@arcgis/core/rest/query";
import Graphic from "@arcgis/core/Graphic";
import Extent from "@arcgis/core/geometry/Extent";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { QueryField } from "../core/interfaces/QueryField";
import { ExcelExport } from "../utils/ExcelExport";
import { MainSearching } from "./widgets/MainSearching";

export class CustomDataSource {
  view: any;
  name: string;
  urlQuery: string;
  queryWhere: string;
  queryFilter: string;
  filterExtent: boolean;
  queryGeom: any;
  layer: string;
  subLayer: string;
  fields: QueryField[];
  outFields: string[];
  coords: any[];
  result: any[];
  executed: boolean;
  visible: boolean;
  extent: Extent;
  exportTool: any;
  exportData: any[];
  error: string;

  symbolLine = {
    color: [255, 0, 0, 11, 0.75],
    width: 1,
  };

  symbolPoly = {
    type: "simple-fill",
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: this.symbolLine,
  };

  simplePoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [255, 0, 0],
      width: 1,
    },
  };

  public constructor(view: any, name: string) {
    this.view = view;
    this.name = name;
    this.urlQuery = "";
    this.queryWhere = "";
    this.queryFilter = "";
    this.filterExtent = false;
    this.queryGeom = null;
    this.layer = "";
    this.subLayer = "";
    this.fields = [];
    this.outFields = [];
    this.coords = [];
    this.result = [];
    this.executed = false;
    this.visible = true;
    this.extent = new Extent();
    this.exportTool = new ExcelExport();
    this.exportData = [];
    this.error = "";
  }

  public setError(value: string) {
    this.error = value;
  }

  public setUrlQuery(value: string) {
    this.urlQuery = value;
  }

  public setQueryFilter(value: string) {
    this.queryFilter = value;
  }

  public setFilterExtent(value: boolean) {
    this.filterExtent = value;
    if (!value) {
      if (this.queryWhere !== "") {
        this.setQueryGeom(null);
      }
    }
  }

  public ClearQueryFilter() {
    this.queryFilter = "";
  }

  public setQueryWhere(value: string) {
    this.queryWhere = value;
  }

  public setQueryGeom(value: any) {
    this.queryGeom = value;
  }

  public setLayer(value: string) {
    this.layer = value;
  }

  public setSubLayer(value: string) {
    this.subLayer = value;
  }

  public setFields(value: any[]) {
    this.fields = value;
  }

  public setExecuted(value: boolean) {
    this.executed = value;
  }

  public setVisible(value: boolean) {
    this.visible = value;
  }

  public Clear() {
    this.view.graphics.removeAll();
  }

  public ExtentPoint(geometry: any) {
    return new Extent({
      xmin: geometry.x - 0.0001,
      ymin: geometry.y - 0.0001,
      xmax: geometry.x + 0.0001,
      ymax: geometry.y + 0.0001,
      spatialReference: {
        wkid: geometry.spatialReference.wkid,
      },
    });
  }

  private LoadLayer(view: any, capa: string, subCapa: string, where: string) {
    let catastroLayer = new FeatureLayer();
    try {
      const foundLayer = view.map.allLayers.find(function (layer: any) {
        try {
          if (layer.title.toUpperCase() === capa.toUpperCase()) {
            layer.visible = true;
            layer.sublayers.forEach((e: any) => {
              if (e.title.toUpperCase() == subCapa.toUpperCase()) {
                catastroLayer = e;
                catastroLayer.visible = true;
                catastroLayer.definitionExpression = where;
                return;
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async Search(
    goto: boolean,
    graphics: boolean,
    dataSource: MainSearching | null = null
  ) {
    this.setExecuted(false);
    if (!this.filterExtent) {
      this.coords = [];
      this.result = [];
    }

    const queryUrl = this.urlQuery;
    this.outFields = [];
    if (this.fields.length > 0) {
      this.fields.forEach((e) => {
        this.outFields.push(e.field);
      });
    }

    //VERIFICANDO FILTROS HABILITADOS
    let curQueryWhere = this.queryWhere;
    if (this.queryFilter !== "") {
      if (curQueryWhere !== "") {
        curQueryWhere = curQueryWhere + " AND " + this.queryFilter;
      } else {
        curQueryWhere = this.queryFilter;
      }
    }

    if (this.visible === false) {
      this.LoadLayer(this.view, this.layer, this.subLayer, curQueryWhere);
    }

    if (this.queryGeom != null) {
      const geomQuery = curQueryWhere !== "" ? curQueryWhere : "1 = 1";
      executeQueryJSON
        .call(null, queryUrl, {
          where: geomQuery,
          geometry: this.queryGeom,
          spatialRelationship: "intersects",
          outFields: ["*"],
          returnGeometry: true,
          returnCentroid: true,
        })
        .then(
          (results) => {
            this.getResuts(results, goto, graphics);
            if (dataSource != null) {
              dataSource.setRecords(results.features.length);
            }
          },
          (error) => {
            console.log(error);
            this.setError(error);
            this.coords = [];
            this.result = [];
          }
        );
    } else {
      executeQueryJSON
        .call(null, queryUrl, {
          where: curQueryWhere,
          outFields: ["*"],
          returnGeometry: true,
          // outFields: this.outFields,
        })
        .then(
          (results) => {
            this.getResuts(results, goto, graphics);
            if (dataSource != null) {
              dataSource.setRecords(results.features.length);
            }
          },
          (error) => {
            console.log(error);
            this.setError(error);
            this.coords = [];
            this.result = [];
          }
        );
    }
  }

  private getResuts(results: any, goto: boolean, graphics: boolean) {
    if (graphics) {
      //Buscando grafico del sketch
      let sketchGraphic: any = null;
      this.view.graphics.items.forEach((e: any) => {
        if (e.attributes != null) {
          if (e.attributes["id"] != null) {
            if (e.attributes.id == "sketchedObject") {
              sketchGraphic = e;
            }
          }
        }
      });

      this.view.graphics.removeAll();
      if (sketchGraphic != null) {
        this.view.graphics.add(sketchGraphic);
      }
    }

    const arrData: never[] = [];
    const arrCoords: never[] = [];
    const data = results.features;
    let counter: number = 0;

    data.forEach((e: any) => {
      const fila: any = {};
      this.outFields.forEach((f) => {
        fila[f] = e.attributes[f];
      });
      arrData.push(fila as never);
      const coord =
        e.geometry == null
          ? {
              // latitude: 0, longitude: 0,
              OBJECTID: 0,
            }
          : {
              OBJECTID: e.attributes["OBJECTID"],
            };

      if (e.geometry != null) {
        const querySymbol = this.getSymbol(e.geometry.type);
        const graphic = new Graphic({
          geometry: e.geometry,
          symbol: querySymbol,
          attributes: { id: "searchedObject" },
        });
        let extentTemp;
        if (e.geometry.type === "point") {
          extentTemp = this.ExtentPoint(e.geometry);
        } else {
          extentTemp = e.geometry.extent;
        }

        if (this.visible) {
          if (graphics) {
            this.view.graphics.add(graphic);
          }
        }

        this.extent.union(extentTemp);

        if (counter == 0) {
          this.extent = extentTemp;
        } else {
          this.extent.union(extentTemp);
        }
      }

      arrCoords.push(coord as never);
      counter++;
    });

    if (goto) {
      if (arrData.length > 0) {
        this.GoTo();
      } else {
        this.view.goTo(this.view.extent.expand(1.01));
      }
    }

    arrData.forEach((e: any) => {
      let fila: any = {};
      this.fields.forEach((c: any) => {
        fila[c.title] =
          c.datatype == "date" ? this.toDate(e[c.field]) : e[c.field];
      });
      this.exportData.push(fila);
    });

    this.result = arrData;
    this.coords = arrCoords;
    this.setExecuted(true);
    this.setError("");
  }

  public ExportXls() {
    const timestamp = Date.now();
    this.exportTool.exportToExcel(this.exportData, timestamp.toString());
  }

  public GoTo() {
    this.view.goTo(this.extent.expand(1.5));
  }

  private toDate(timestamp: number) {
    if (timestamp == null || timestamp == 0) {
      return "";
    }
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
  }

  private getSymbol(type: string) {
    let querySymbol: any;
    switch (type) {
      case "point":
        querySymbol = this.simplePoint;
        break;
      case "polyline":
        querySymbol = this.symbolPoly;
        break;
      case "polygon":
        querySymbol = this.symbolPoly;
        break;
      default:
        querySymbol = this.simplePoint;
        break;
    }
    return querySymbol;
  }
}
