import React, { useEffect, useState, useRef } from "react";
import {
  CalciteSelect,
  CalciteButton,
  CalciteOption,
  CalciteInput,
  CalciteCheckbox,
  CalciteLabel,
  CalciteTable,
  CalciteTableRow,
  CalciteTooltip,
  CalciteTableHeader,
  CalciteTableCell,
  CalcitePanel,
} from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../core/providers/AlertProvider";
import { ExcelExport } from "../../utils/ExcelExport";
import axios from "axios";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as projection from "@arcgis/core/geometry/projection";
import * as XLSX from "xlsx";
import Graphic from "@arcgis/core/Graphic";
import Multipoint from "@arcgis/core/geometry/Multipoint";
import { CustomFunctions } from "../../utils/CustomFunctions";

export interface TableCoord {
  id: number;
  este: number;
  norte: number;
  estetrans: number;
  nortetrans: number;
  mensaje: string;
}

export function TransformarCoordenadas(props: any) {
  const featureLayerRef = useRef(null);
  const showAlert = useAlert();
  const { t } = useTranslation();
  let view = props.view;
  const alertTitle = "Transformación de coordenadas";
  const exportTool = new ExcelExport();

  const utils = new CustomFunctions();

  const [txtEsteTransformarCoordenadas, setTxtEsteTransformarCoordenadas] =
    useState("");
  const [txtNorteTransformarCoordenadas, setTxtNorteTransformarCoordenadas] =
    useState("");
  const [tableCoords, setTableCoords] = useState<TableCoord[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedDatum, setSelectedDatum] = useState("PSAD");
  const [selectedZona, setSelectedZona] = useState("17");

  const [uploadFile, setUploadFile] = useState(true);
  const [txtNotify, setTxtNotify] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [chkGraficar, setChkGraficar] = useState(false);
  let arrCoords: TableCoord[] = [];

  function fnKeyEnter(event: any) {
    if (event.keyCode == 13) {
      console.log("tecla enter");
    }
  }

  const AddCoord = () => {
    if (txtEsteTransformarCoordenadas == "") {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorcx"),
        type: "danger",
        autoClose: true,
      });
      return false;
    }
    if (txtNorteTransformarCoordenadas == "") {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorcy"),
        type: "danger",
        autoClose: true,
      });
      return false;
    }
    if (isNaN(Number(txtEsteTransformarCoordenadas))) {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errornx"),
        type: "danger",
        autoClose: true,
      });
      return false;
    } else {
      if (parseFloat(txtEsteTransformarCoordenadas) == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errorn0"),
          type: "danger",
          autoClose: true,
        });
        return false;
      }
    }
    if (isNaN(Number(txtNorteTransformarCoordenadas))) {
      showAlert({
        title: alertTitle,
        message: t("screens.widgets.location.errorny"),
        type: "danger",
        autoClose: true,
      });
      return false;
    } else {
      if (parseFloat(txtNorteTransformarCoordenadas) == 0) {
        showAlert({
          title: alertTitle,
          message: t("screens.widgets.location.errorn0"),
          type: "danger",
          autoClose: true,
        });
        return false;
      }
    }

    let coordX = parseFloat(txtEsteTransformarCoordenadas);
    let coordY = parseFloat(txtNorteTransformarCoordenadas);
    ApplyTransform(coordX, coordY);
    fnGraficar();
  };

  async function ApplyTransform(
    coordX: number,
    coordY: number,
    rowStart: number = 1,
    rowEnd: number = 1
  ) {
    //VALIDACIONES DE COORDENADAS
    if (coordX < 100000 || coordX > 999999) {
      showAlert({
        title: alertTitle,
        message: "La coordenada Este es incorrecta",
        type: "danger",
        autoClose: true,
      });
      return false;
    }
    if (coordY < 1000000 || coordY > 9999999) {
      showAlert({
        title: alertTitle,
        message: "La coordenada Norte es incorrecta",
        type: "danger",
        autoClose: true,
      });
      return false;
    }

    //REALIZANDO TRANSFORMACION
    let wsTrans = "";
    if (selectedDatum == "PSAD") {
      wsTrans =
        "https://geocatminapp.ingemmet.gob.pe/bdgeocientifica/app/api/account/Coord56a84/" +
        coordY.toString() +
        "/" +
        coordX.toString() +
        "/" +
        selectedZona +
        "/0/0";
    }
    if (selectedDatum == "WGS") {
      wsTrans =
        "https://geocatminapp.ingemmet.gob.pe/bdgeocientifica/app/api/account/Coord84a56/" +
        coordY.toString() +
        "/" +
        coordX.toString() +
        "/" +
        selectedZona +
        "/0/0";
    }

    if (wsTrans != "") {
      const result = await axios
        .get(wsTrans)
        .then(async function (response) {
          let transX = 0;
          let transY = 0;
          let mensaje = "";
          const data = await response.data;

          data.map((e: any) => {
            if (e.TipoRespuesta == "Este") {
              transX = parseFloat(e.ValorRespuesta);
            }
            if (e.TipoRespuesta == "Norte") {
              transY = parseFloat(e.ValorRespuesta);
            }
            if (e.TipoRespuesta == "Mensaje") {
              mensaje = e.ValorRespuesta;
            }
          });

          const item: TableCoord = {
            id: 0,
            este: coordX,
            norte: coordY,
            estetrans: transX,
            nortetrans: transY,
            mensaje: mensaje,
          };
          if (rowStart == 1 && rowEnd == 1) {
            setTableCoords([...tableCoords, item]);
            setTxtEsteTransformarCoordenadas("0");
            setTxtNorteTransformarCoordenadas("0");
          } else {
            arrCoords.push(item);
            if (rowStart == rowEnd) {
              setTableCoords(arrCoords);
              setTxtEsteTransformarCoordenadas("0");
              setTxtNorteTransformarCoordenadas("0");
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      return result;
    }
  }

  const ExportXs = () => {
    let exportData: TableCoord[] = [];
    tableCoords.forEach((e, index) => {
      let fila: any = {};
      fila["ID"] = index + 1;
      fila["Este"] = e.este;
      fila["Norte"] = e.norte;
      fila["Este Transf."] = e.estetrans;
      fila["Norte Transf."] = e.nortetrans;
      fila["Mensaje"] = e.mensaje;
      exportData.push(fila);
    });

    const timestamp = Date.now();
    exportTool.exportToExcel(exportData, timestamp.toString());
  };

  const RemoveCoord = () => {
    if (tableCoords.length == 0) {
      showAlert({
        title: alertTitle,
        message: "No existen registros por eliminar",
        type: "warning",
        autoClose: true,
      });
      return;
    }

    if (selectedIndex < 0) {
      showAlert({
        title: alertTitle,
        message: "Seleccione un registro",
        type: "danger",
        autoClose: true,
      });
      return;
    }

    let data = [...tableCoords];
    data.splice(selectedIndex, 1);
    setTableCoords(data);
    fnGraficar();
  };

  const ShowUploadFile = () => {
    setTxtNotify("Seleccione un archivo!");
    const status = !uploadFile;
    setUploadFile(status);
  };

  const ClearCords = () => {
    //    view.graphics.removeAll();
    const data: TableCoord[] = [];
    setTableCoords(data);
    setUploadFile(true);
    if (featureLayerRef.current) {
      view.map.remove(featureLayerRef.current);
    }
  };

  const handleFileChange = (target: any) => {
    if (target.files) {
      setFile(target.files[0]);
    } else {
      setTxtNotify("Seleccione un archivo!");
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      setTxtNotify("Seleccione un archivo!");
      return;
    }

    const fileName = file.name.toLocaleLowerCase();

    if (fileName.indexOf(".xlsx") !== -1) {
      getTemplateCoords(fileName);
    } else {
      setTxtNotify("Subir un archivo en formato .XLSX");
    }
  };

  const getTemplateCoords = (fileName: string) => {
    let name: any = fileName.split(".");
    name = name[0].replace("c:\\fakepath\\", "");
    setTxtNotify("Cargando " + name + "...");

    //READING FILE
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //const data = XLSX.utils.sheet_to_csv(ws,  {header: 1, defval: ""});
      let data = XLSX.utils.sheet_to_csv(ws);

      showAlert({
        title: alertTitle,
        message: "Procesando ...",
        type: "info",
        autoClose: true,
      });
      /* Update state */
      ImportTemplateData(data);
      setTxtNotify("Archivo cargado");
      setFile(null);
      setUploadFile(true);

      const myForm = document.getElementById(
        "uploadFormXls"
      ) as HTMLFormElement;
      myForm.reset();
      if (chkGraficar) {
        //AQUI COLOCAR EL GRAFICO EN EL MAPA
        fnGraficar();
      }
    };
    reader.readAsBinaryString(file as Blob);
  };

  async function ImportTemplateData(data: string) {
    arrCoords = [];
    const lines = data.split(/\r?\n/);
    lines.forEach((row, index) => {
      if (index > 0) {
        const line = row.split(",");
        if (line.length < 3) {
          showAlert({
            title: alertTitle,
            message: "El formato cargado es incorrecto",
            type: "warning",
            autoClose: true,
          });
          return false;
        }
        const id = line[0];
        const x = line[1];
        const y = line[2];

        if (x == "" || y == "") {
          showAlert({
            title: alertTitle,
            message:
              "La coordenada de la fila " +
              index.toString() +
              " está incompleta",
            type: "warning",
            autoClose: true,
          });
          return false;
        }
        if (isNaN(Number(x)) || isNaN(Number(y))) {
          showAlert({
            title: alertTitle,
            message:
              "La coordenada de la fila " + index.toString() + " es incorrecta",
            type: "warning",
            autoClose: true,
          });
          return false;
        }
        ApplyTransform(parseFloat(x), parseFloat(y), index, lines.length - 1);
      }
    });
  }

  const fnChkGraficar = (event: any) => {
    //console.log(event.target.checked);
    setChkGraficar(event.target.checked);
    if (event.target.checked) {
      fnGraficar();
    }
  };
  projection.load();
  function fnGraficar() {
    if (tableCoords.length === 0) {
      return;
    }

    let datumTemp = selectedDatum == "WGS" ? "2487" : "3271";
    datumTemp = datumTemp + selectedZona.substring(1);
    console.log(datumTemp);
    let pointData: any[] = [];

    tableCoords.forEach((e) => {
      pointData.push([e.estetrans, e.nortetrans]);
    });

    var multipointGeometry = new Multipoint({
      points: pointData,
      spatialReference: utils.getSpatialReference(Number(datumTemp)),
    });

    const spOut = utils.getSpatialReference(view.spatialReference.wkid);
    //let centro = webMercatorUtils.project(pointGraf, spOut);
    let centro = projection.project(multipointGeometry, spOut) as Multipoint;
    const pointGraphic = new Graphic({
      geometry: centro as Multipoint,
      symbol: utils.simpleMarkerSymbol(),
    });

    if (featureLayerRef.current) {
      view.map.remove(featureLayerRef.current);
    }

    let graphicsLayerResultado = new GraphicsLayer();
    graphicsLayerResultado.title = "graphicsLayerResultado";
    graphicsLayerResultado.id = "id";
    graphicsLayerResultado.listMode = "hide";
    graphicsLayerResultado.add(pointGraphic);

    featureLayerRef.current = graphicsLayerResultado as any;
    view.map.add(graphicsLayerResultado);
    //view.goTo(pointGraphic.geometry.extent.expand(1.5));
    if (tableCoords.length > 1) {
      view.goTo(pointGraphic.geometry.extent.expand(1.5));
    } else {
      view
        .goTo({
          target: pointGraphic,
          zoom: 12,
        })
        .catch(function (error: any) {
          console.error(error);
        });
    }

    //WGS84 -- 32717, 32718, 32719
    //PSAD -- 24877, 24878, 24879

    // let pointGraf = new Point();
    //   pointGraf.x = x;
    //   pointGraf.y = y;
    //   pointGraf.spatialReference = utils.getSpatialReference(zona);
  }
  return (
    <>
      <div id="divTransformarCoordenadas">
        <CalciteLabel>
          {t("screens.widgets.TransformarCoordenadas.vertices")}
        </CalciteLabel>
        <div className="row py-2">
          <div className="col-8 col-md d-flex">
            <CalciteLabel>
              {t("screens.widgets.TransformarCoordenadas.datum")}
              <CalciteSelect
                label={t("screens.widgets.TransformarCoordenadas.datum")}
                id="lstDatumTransformarCoordenadas"
                style={{ width: "200px" }}
                onCalciteSelectChange={(e) => setSelectedDatum(e.target.value)}
              >
                <CalciteOption value="PSAD">
                  {" "}
                  PSAD 56 --{">"} WGS 84
                </CalciteOption>
                <CalciteOption value="WGS">
                  WGS 84 --{">"} PSAD 56
                </CalciteOption>
              </CalciteSelect>
            </CalciteLabel>
          </div>
          <div className="col-4 col-md d-flex">
            <CalciteLabel>
              {t("screens.widgets.TransformarCoordenadas.zona")}

              <CalciteSelect
                label={t("screens.widgets.TransformarCoordenadas.zona")}
                id="lstZonaTransformarCoordenadas"
                onCalciteSelectChange={(e) => setSelectedZona(e.target.value)}
              >
                <CalciteOption value="17">17</CalciteOption>
                <CalciteOption value="18">18</CalciteOption>
                <CalciteOption value="19">19</CalciteOption>
              </CalciteSelect>
            </CalciteLabel>
          </div>
        </div>

        <div className="row py-2">
          <div className="col-4 col-md d-flex">
            <CalciteLabel>
              {t("screens.widgets.TransformarCoordenadas.este")}
              <CalciteInput
                style={{ width: "110px" }}
                placeholder={t("screens.widgets.TransformarCoordenadas.este")}
                type="number"
                number-button-type="none"
                group-separator
                alignment="end"
                value={txtEsteTransformarCoordenadas}
                onKeyUp={(e) => fnKeyEnter(e)}
                onCalciteInputChange={(e) =>
                  setTxtEsteTransformarCoordenadas(e.target.value)
                }
              ></CalciteInput>
            </CalciteLabel>
          </div>
          <div className="col-4 col-md d-flex">
            <CalciteLabel>
              {t("screens.widgets.TransformarCoordenadas.norte")}
              <CalciteInput
                style={{ width: "110px" }}
                group-separator
                alignment="end"
                number-button-type="none"
                placeholder={t("screens.widgets.TransformarCoordenadas.norte")}
                type="number"
                value={txtNorteTransformarCoordenadas}
                onKeyUp={(e) => fnKeyEnter(e)}
                onCalciteInputChange={(e) =>
                  setTxtNorteTransformarCoordenadas(e.target.value)
                }
              ></CalciteInput>
            </CalciteLabel>
          </div>
          <div className="col-4 col-md d-flex" style={{ paddingTop: "22px" }}>
            <CalciteButton
              iconStart="plus"
              className="ms-2"
              id="btnAdicionaVertice"
              style={{ height: "24px" }}
              onClick={(e) => AddCoord()}
            ></CalciteButton>
            <CalciteTooltip reference-element="btnAdicionaVertice">
              Adiciona un vertice
            </CalciteTooltip>
            <CalciteButton
              iconStart="trash"
              className="ms-2"
              id="btnEliminaVertice"
              style={{ height: "24px" }}
              onClick={(e) => RemoveCoord()}
            ></CalciteButton>
            <CalciteTooltip reference-element="btnEliminaVertice">
              Elimina un vertice
            </CalciteTooltip>
          </div>
        </div>

        {/* /************************************************ */}
        <div
          style={{
            // width: "100%",
            // overflow: "auto",
            marginBottom: "5px",
            marginTop: "4px",
            // maxHeight: "300px",
          }}
        >
          <CalcitePanel style={{ height: "200px" }}>
            <CalciteTable
              caption="Simple table"
              scale="s"
              selectionMode="single"
              layout="auto"
            >
              <CalciteTableRow slot="table-header">
                <CalciteTableHeader
                  heading="Este"
                  style={{ with: "300px" }}
                ></CalciteTableHeader>
                <CalciteTableHeader
                  heading="Norte"
                  style={{ with: "300px" }}
                ></CalciteTableHeader>
                <CalciteTableHeader
                  heading="Este Transf."
                  // style={{ height: "24px" }}
                  style={{ with: "300px" }}
                ></CalciteTableHeader>
                <CalciteTableHeader
                  heading="Norte Transf."
                  // style={{ height: "24px" }}
                  style={{ with: "300px" }}
                ></CalciteTableHeader>
                <CalciteTableHeader
                  heading="Mensaje"
                  // style={{ height: "24px" }}
                  style={{ with: "400px" }}
                ></CalciteTableHeader>
              </CalciteTableRow>
              {tableCoords.length > 0 ? (
                tableCoords.map((item, index) => {
                  return (
                    <CalciteTableRow
                      key={index}
                      onCalciteTableRowSelect={(e) => setSelectedIndex(index)}
                    >
                      <CalciteTableCell
                        alignment="end"
                        key={"c1" + index.toString()}
                      >
                        {item.este}
                      </CalciteTableCell>
                      <CalciteTableCell
                        alignment="end"
                        key={"c2" + index.toString()}
                      >
                        {item.norte}
                      </CalciteTableCell>
                      <CalciteTableCell
                        alignment="end"
                        key={"c3" + index.toString()}
                      >
                        {item.estetrans}
                      </CalciteTableCell>
                      <CalciteTableCell
                        alignment="end"
                        key={"c4" + index.toString()}
                      >
                        {item.nortetrans}
                      </CalciteTableCell>
                      <CalciteTableCell
                        alignment="end"
                        key={"c5" + index.toString()}
                      >
                        {item.mensaje}
                      </CalciteTableCell>
                    </CalciteTableRow>
                  );
                })
              ) : (
                <CalciteTableRow>
                  <CalciteTableCell colSpan={5}>
                    No existen registros
                  </CalciteTableCell>
                </CalciteTableRow>
              )}
            </CalciteTable>
          </CalcitePanel>
        </div>
        {/* /*************************************************/}

        <div>
          <CalciteButton
            iconStart="upload"
            id="btnUpload"
            className="ms-2"
            onClick={(e) => ShowUploadFile()}
          ></CalciteButton>
          <CalciteTooltip reference-element="btnUpload">
            Importar coordenadas
          </CalciteTooltip>
          {tableCoords.length > 0 ? (
            <CalciteButton
              iconStart="download"
              id="btnDownload"
              className="ms-2"
              onClick={(e) => ExportXs()}
            ></CalciteButton>
          ) : (
            <CalciteButton
              iconStart="download"
              id="btnDownload"
              className="ms-2"
              disabled
            ></CalciteButton>
          )}
          <CalciteTooltip reference-element="btnDownload">
            Descargar grilla
          </CalciteTooltip>
          <a
            href={`${process.env.PUBLIC_URL}/plantilla/CoordenadasPlantilla.xlsx`}
            target="_blank"
          >
            <CalciteButton
              iconStart="file-excel"
              id="btnTemplate"
              className="ms-2"
            ></CalciteButton>
            <CalciteTooltip reference-element="btnTemplate">
              Descargar plantilla
            </CalciteTooltip>
          </a>

          <CalciteButton
            iconStart="refresh"
            id="btnRefresh"
            className="ms-2"
            onClick={(e) => ClearCords()}
          ></CalciteButton>
          <CalciteTooltip reference-element="btnRefresh">
            Limpiar todo
          </CalciteTooltip>
        </div>
        <div style={{ padding: "6px" }} hidden={uploadFile}>
          <form encType="multipart/form-data" method="POST" id="uploadFormXls">
            <CalciteLabel
              style={{
                padding: "5px",
                borderStyle: "dashed solid",
                borderWidth: "1px",
              }}
            >
              <input
                type="file"
                name="file"
                onChange={(e) => handleFileChange(e.target)}
                id="inFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </CalciteLabel>
            <div className="text-center" style={{ marginTop: "8px" }}>
              <CalciteButton
                iconStart="upload-to"
                onClick={() => handleUploadClick()}
              >
                Cargar archivo
              </CalciteButton>
              <span
                className="file-upload-status"
                style={{ opacity: "1" }}
                id="upload-status"
              >
                <p style={{ color: "red", padding: "4px" }}>{txtNotify}</p>
              </span>
            </div>
          </form>
        </div>
        <div className="row py-2">
          <CalciteLabel layout="inline">
            <CalciteCheckbox
              id="chkGraficarTransformarCoordenadas"
              scale="m"
              onCalciteCheckboxChange={(e) => fnChkGraficar(e)}
            ></CalciteCheckbox>
            {t("screens.widgets.TransformarCoordenadas.graficar")}
          </CalciteLabel>
        </div>
      </div>
    </>
  );
}
