import React, { useEffect, useState } from "react";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import {
  CalciteButton,
  CalciteSelect,
  CalciteOption,
  CalciteLabel,
} from "@esri/calcite-components-react";
import "./styleWidget.css";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import { CatastroMinero } from "./CatastroMinero";
import { CustomPopupPDF } from "../../components/CustomPopupPDF";
import configRMI from "./ConfigRocaMineralIndustrial.json";
import { QueryField } from "../../core/interfaces/QueryField";
import { CustomDataSource } from "../CustomDataSource";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { useAlert } from "../../core/providers/AlertProvider";
import { MainSearching } from "./MainSearching";
import { setRecords } from "../../redux/slices/userActionsSlice";
import { useAppDispatch } from "../../redux/hooks";
import { CustomFunctions } from "../../utils/CustomFunctions";

export function RocaMineralIndustrial(props: any) {
  let searching: MainSearching = props.data;
  const view = searching.view;

  //let catastro: CatastroMinero = props.catastro;

  const { t } = useTranslation();
  const showAlert = useAlert();
  let dataSource = new CustomDataSource(view, "RocasMineralesIndustriales");
  let arrFields: QueryField[] = [];
  arrFields = configRMI.layout as any;
  dataSource.setFields(arrFields);
  const utils = new CustomFunctions();

  const dispatch = useAppDispatch();

  const [arr_01_rmi, setArr_01_rmi] = useState([]);
  const [arr_02_rmi, setArr_02_rmi] = useState([]);
  const [arr_03_rmi, setArr_03_rmi] = useState([]);
  const [arr_04_rmi, setArr_04_rmi] = useState([]);
  const [arr_05_rmi, setArr_05_rmi] = useState([]);

  const [lblSelect01, setlblSelect01] = useState(
    "screens.widgets.RocaMineralIndustrial.sustancia"
  );
  const [lblSelect02, setlblSelect02] = useState("--");
  const [lblSelect03, setlblSelect03] = useState("--");
  const [lblSelect04, setlblSelect04] = useState("--");
  const [lblSelect05, setlblSelect05] = useState("--");

  const [lstSelect_Tipovalue, setLstSelect_Tipovalue] = useState("");
  const [lstSelect_01_RMIvalue, setLstSelect_01_RMIvalue] = useState("");
  const [lstSelect_02_RMIvalue, setLstSelect_02_RMIvalue] = useState("");
  const [lstSelect_03_RMIvalue, setLstSelect_03_RMIvalue] = useState("");
  const [lstSelect_04_RMIvalue, setLstSelect_04_RMIvalue] = useState("");
  const [lstSelect_05_RMIvalue, setLstSelect_05_RMIvalue] = useState("");

  const [hideDiv_01_RMI, setHideDiv_01_RMI] = useState(true);
  const [hideDiv_02_RMI, setHideDiv_02_RMI] = useState(true);
  const [hideDiv_03_RMI, setHideDiv_03_RMI] = useState(true);
  const [hideDiv_04_RMI, setHideDiv_04_RMI] = useState(true);
  const [hideDiv_05_RMI, setHideDiv_05_RMI] = useState(true);

  const [manualRMI, setManualRMI] = useState(
    "https://geocatminapp.ingemmet.gob.pe/complementos/Descargas/ManualGeocatminRMI.pdf"
  );

  let arrData: never[] = [];
  let campo = "";
  //let arrFields: QueryField[] = [];
  //arrFields = configRMI.layout as any;

  const capa = configRMI.capa;
  const subCapa = configRMI.subcapa;
  useEffect(() => {
    let RMILayer = new FeatureLayer();
    async function loadLayer() {
      try {
        const foundLayer = view.map.allLayers.find(function (layer: any) {
          try {
            if (layer.title.toUpperCase() === capa.toUpperCase()) {
              layer.sublayers.forEach((e: any) => {
                if (e.title.toUpperCase() == subCapa.toUpperCase()) {
                  RMILayer = e;
                  //console.log(e);
                  return;
                }
              });
            }

            //return layer.title.toUpperCase() === capa.toUpperCase();
          } catch (error) {
            console.log(error);
          }
        });
      } catch (error) {
        console.log(error);
        //loadLayer();
      }
    }

    const cmbRMIConsulta = document.getElementById(
      "cmbRMIConsulta"
    ) as HTMLSelectElement;

    cmbRMIConsulta.addEventListener("calciteSelectChange", () => {
      onSelectTipo(cmbRMIConsulta.value);
    });

    const lstSelect_01_RMI = document.getElementById(
      "lstSelect_01_RMI"
    ) as HTMLSelectElement;

    lstSelect_01_RMI.addEventListener("calciteSelectChange", () => {
      fnSelect01(lstSelect_01_RMI.value);
    });

    const lstSelect_02_RMI = document.getElementById(
      "lstSelect_02_RMI"
    ) as HTMLSelectElement;

    lstSelect_02_RMI.addEventListener("calciteSelectChange", () => {
      fnSelect02(lstSelect_02_RMI.value);
    });

    const lstSelect_03_RMI = document.getElementById(
      "lstSelect_03_RMI"
    ) as HTMLSelectElement;

    lstSelect_03_RMI.addEventListener("calciteSelectChange", () => {
      fnSelect03(lstSelect_03_RMI.value);
    });

    const lstSelect_04_RMI = document.getElementById(
      "lstSelect_04_RMI"
    ) as HTMLSelectElement;

    lstSelect_04_RMI.addEventListener("calciteSelectChange", () => {
      fnSelect04(lstSelect_04_RMI.value);
    });

    const lstSelect_05_RMI = document.getElementById(
      "lstSelect_05_RMI"
    ) as HTMLSelectElement;

    lstSelect_05_RMI.addEventListener("calciteSelectChange", () => {
      fnSelect05();
    });
    function fnMostrarDiv(div: any): void {
      // console.log("fnMostrarDiv");
      setHideDiv_01_RMI(true);
      setHideDiv_02_RMI(true);
      setHideDiv_03_RMI(true);
      setHideDiv_04_RMI(true);
      setHideDiv_05_RMI(true);
      switch (div) {
        case "div_01_RMI":
          setHideDiv_01_RMI(false);
          setHideDiv_02_RMI(true);
          setHideDiv_03_RMI(true);
          setHideDiv_04_RMI(true);
          setHideDiv_05_RMI(true);
          break;
        case "div_02_RMI":
          setHideDiv_01_RMI(false);
          setHideDiv_02_RMI(false);
          setHideDiv_03_RMI(true);
          setHideDiv_04_RMI(true);
          setHideDiv_05_RMI(true);
          break;
        case "div_03_RMI":
          setHideDiv_01_RMI(false);
          setHideDiv_02_RMI(false);
          setHideDiv_03_RMI(false);
          setHideDiv_04_RMI(true);
          setHideDiv_05_RMI(true);
          break;
        case "div_04_RMI":
          setHideDiv_01_RMI(false);
          setHideDiv_02_RMI(false);
          setHideDiv_03_RMI(false);
          setHideDiv_04_RMI(false);
          setHideDiv_05_RMI(true);
          break;
        case "div_05_RMI":
          setHideDiv_01_RMI(false);
          setHideDiv_02_RMI(false);
          setHideDiv_03_RMI(false);
          setHideDiv_04_RMI(false);
          setHideDiv_05_RMI(false);
          break;
      }
    }

    function fnUso(value: any) {
      //console.log("fnUso");
      arrData = [];
      let _tipo = value.length;
      let _Lista = "";
      // fnOcultarSelect();

      var urlServ =
        "https://geocatminapp.ingemmet.gob.pe/bdgeocientifica/app/service/rmi/actividad-economica" +
        "/" +
        value;

      (async () => {
        const response = await fetch(urlServ);
        const arrDataTemp = await response.json();
        arrData.push({
          id: "",
          name: "-- Seleccione --", // {t("screens.widgets.seleccione")}
        } as never);

        for (var i in arrDataTemp) {
          arrData.push({
            id: arrDataTemp[i]["Codigo"],
            name: arrDataTemp[i]["Descripcion"],
          } as never);
        }
        switch (_tipo) {
          case 0:
            //_Lista = "arr_01_rmi";
            //console.log(arrData);
            setArr_01_rmi(arrData as never);
            //console.log(arr_01_rmi);
            setlblSelect01(
              "screens.widgets.RocaMineralIndustrial.Actividad_Economica"
            );
            fnMostrarDiv("div_01_RMI");
            //dojo.byId("lblSelect01").innerHTML = 'Actividad Econ&oacute;mica';

            //setlblSelect01({t("screens.widgets.RocaMineralIndustrial.Actividad_Economica"));

            break;
          case 2:
            //fnMostrarDiv('divSelec_01_RMI');

            setlblSelect02("screens.widgets.RocaMineralIndustrial.Industria");
            //dojo.byId("lblSelect02").innerHTML = 'Industria';
            //_Lista = 'lstSelect_02_RMI';
            setArr_02_rmi(arrData as never);
            fnMostrarDiv("div_02_RMI");
            break;
          case 4:
            setlblSelect03(
              "screens.widgets.RocaMineralIndustrial.Productos_Insumos"
            );
            //dojo.byId("lblSelect03").innerHTML = 'Productos e Insumos';
            //_Lista = 'lstSelect_03_RMI';
            setArr_03_rmi(arrData as never);
            fnMostrarDiv("div_03_RMI");
            break;
          case 6:
            setlblSelect04(
              "screens.widgets.RocaMineralIndustrial.Usos_Aplicacion"
            );
            //dojo.byId("lblSelect04").innerHTML = 'Usos o Aplicacón';
            //_Lista = 'lstSelect_04_RMI';
            setArr_04_rmi(arrData as never);
            fnMostrarDiv("div_04_RMI");
            break;
          case 8:
            //dojo.byId("lblSelect05").innerHTML = 'Sustancia';
            //_Lista = 'lstSelect_05_RMI';
            setlblSelect05("screens.widgets.RocaMineralIndustrial.sustancia");
            setArr_05_rmi(arrData as never);
            fnMostrarDiv("div_05_RMI");
            break;
        }
      })();
    }
    function fnUbicacion(tipo: any) {
      console.log("fnUbicacion");

      switch (tipo) {
        case 0:
          //dojo.byId("lblSelect01").innerHTML = 'Tipo de Ubicaci&oacute;n';

          setlblSelect01(
            "screens.widgets.RocaMineralIndustrial.tipo_ubicacion"
          );

          arrData = [];
          arrData.push({
            id: "",
            name: "-- Seleccione --", // {t("screens.widgets.seleccione")}
          } as never);

          arrData.push({ id: 1, name: "Region" } as never);
          arrData.push({ id: 2, name: "Cuadrangulo" } as never);

          fnllenarSelect(arrData, "lstSelect_01_RMI");
          fnMostrarDiv("div_01_RMI");
          break;
        case 1:
          //dojo.byId("lblSelect02").innerHTML = 'Regi&oacute;n';
          setlblSelect02("screens.widgets.RocaMineralIndustrial.Region");
          fnQuery(
            "1=1 and departamento is not null",
            "DEPARTAMENTO",
            "lstSelect_02_RMI",
            true
          );
          fnMostrarDiv("div_02_RMI");
          break;
        case 2:
          //dojo.byId("lblSelect03").innerHTML = 'Provincia';
          setlblSelect03("screens.widgets.RocaMineralIndustrial.Provincia");
          fnQuery(
            "DEPARTAMENTO='" +
              lstSelect_02_RMI.value +
              "' and provincia is not null",
            "PROVINCIA",
            "lstSelect_03_RMI",
            true
          );
          fnQuery(
            "DEPARTAMENTO='" +
              lstSelect_02_RMI.value +
              "' and provincia is not null",
            "PROVINCIA",
            "",
            false
          );
          fnMostrarDiv("div_03_RMI");
          break;
        case 3:
          //dojo.byId("lblSelect04").innerHTML = 'Distrito';
          setlblSelect04("screens.widgets.RocaMineralIndustrial.Distrito");
          fnQuery(
            "DEPARTAMENTO='" +
              lstSelect_02_RMI.value +
              "' and PROVINCIA='" +
              lstSelect_03_RMI.value +
              "' and DISTRITO is not null",
            "DISTRITO",
            "lstSelect_04_RMI",
            true
          );
          fnQuery(
            "DEPARTAMENTO='" +
              lstSelect_02_RMI.value +
              "' and PROVINCIA='" +
              lstSelect_03_RMI.value +
              "' and DISTRITO is not null",
            "DISTRITO",
            "",
            false
          );
          fnMostrarDiv("div_04_RMI");
          break;
        case 4:
          fnQuery(
            "DEPARTAMENTO='" +
              lstSelect_02_RMI.value +
              "' and PROVINCIA='" +
              lstSelect_03_RMI.value +
              "' and DISTRITO ='" +
              lstSelect_04_RMI.value +
              "'  and DISTRITO is not null",
            "DISTRITO",
            "",
            false
          );

          break;
        case 5:
          //dojo.byId("lblSelect02").innerHTML = 'Cuadr&aacute;ngulo';
          setlblSelect02("screens.widgets.RocaMineralIndustrial.Cuadrangulo");
          fnQuery("1=1", "HOJA", "lstSelect_02_RMI", true);
          fnMostrarDiv("div_02_RMI");
          break;
        case 6:
          fnQuery(
            "HOJA like '" + lstSelect_02_RMI.value + "'",
            "HOJA",
            "",
            false
          );
          break;
      }
    }
    function fnRocaMineral(tipo: any) {
      console.log("fnRocaMineral");
      campo = "SUSTANCIA";
      switch (tipo) {
        case 0:
          setlblSelect01("screens.widgets.RocaMineralIndustrial.sustancia");
          fnQuery("1=1", campo, "lstSelect_01_RMI", true);
          fnMostrarDiv("div_01_RMI");
          break;
        case 1:
          fnQuery(
            campo + " = '" + lstSelect_01_RMI.value + "'",
            campo,
            "",
            false
          );
          break;
        case 2:
          fnQuery(
            campo + " = '" + lstSelect_05_RMI.value + "'",
            campo,
            "",
            false
          );
          break;
      }
    }

    function fnProyecto(tipo: any) {
      console.log("fnProyecto");
      campo = "PROYECTO";
      switch (tipo) {
        case 0:
          //dojo.byId("lblSelect01").innerHTML = 'Proyecto';
          setlblSelect01("screens.widgets.RocaMineralIndustrial.Proyecto");
          fnQuery("1=1", campo, "lstSelect_01_RMI", true);
          fnMostrarDiv("div_01_RMI");
          break;
      }
    }
    function fnCategoria(tipo: any) {
      console.log("fnCategoria");
      campo = "CAT_DEPOSITO";
      switch (tipo) {
        case 0:
          //dojo.byId("lblSelect01").innerHTML = 'Categor&iacute;a del Dep&oacute;sito';
          setlblSelect01("screens.widgets.RocaMineralIndustrial.Categoria");
          fnQuery("1=1", campo, "lstSelect_01_RMI", true);
          fnMostrarDiv("div_01_RMI");
          break;
      }
    }
    function fnTamanio(tipo: any) {
      console.log("fnTamanio");
      campo = "TAM_DEPOSITO";
      switch (tipo) {
        case 0:
          //dojo.byId("lblSelect01").innerHTML = 'Tama&ntilde;o del Dep&oacute;sito';
          setlblSelect01("screens.widgets.RocaMineralIndustrial.Tamanio");
          fnQuery("1=1", campo, "lstSelect_01_RMI", true);
          fnMostrarDiv("div_01_RMI");
          break;
      }
    }
    function fnCategoriaProd(tipo: any) {
      console.log("fnCategoriaProd");
      campo = "CAT_PRODUCCION";
      switch (tipo) {
        case 0:
          //dojo.byId("lblSelect01").innerHTML = 'Categor&iacute;a de Producci&oacute;n';
          fnQuery("1=1", campo, "lstSelect_01_RMI", true);
          fnMostrarDiv("div_01_RMI");
          break;
      }
    }

    function fnSelect01(value: any) {
      console.log("fnSelect01");
      console.log(value);
      setLstSelect_01_RMIvalue(value);
      console.log(cmbRMIConsulta.value);
      switch (Number(cmbRMIConsulta.value)) {
        case 1: //Tipo de Roca
          //console.log(lstTipo_RMI.value);
          break;
        case 2: //Uso
          //console.log(lstSelect_01_RMIvalue);
          //fnUso(lstSelect_01_RMIvalue);
          console.log(value);
          fnUso(value);
          break;
        case 3: //Ubicacion
          //console.log(lstTipo_RMIvalue);

          if (Number(lstSelect_01_RMI.value) == 1) {
            fnUbicacion(1);
          } else {
            {
              fnUbicacion(5);
            }
          }
          break;
        case 4: //Roca/Mineral
          console.log(lstSelect_01_RMI.value);
          fnRocaMineral(1);
          break;
        case 5: //Proyecto
          campo = "PROYECTO";
          fnQuery(
            campo + " = '" + lstSelect_01_RMI.value + "'",
            campo,
            "",
            false
          );
          break;
        case 6: //Categoría del Depósito
          campo = "CAT_DEPOSITO";
          fnQuery(
            campo + " = '" + lstSelect_01_RMI.value + "'",
            campo,
            "",
            false
          );
          break;
        case 7: //Tamaño del Depósito
          campo = "TAM_DEPOSITO";
          fnQuery(
            campo + " = '" + lstSelect_01_RMI.value + "'",
            campo,
            "",
            false
          );
          break;
        case 8: //Categoría de Producción
          campo = "CAT_PRODUCCION";
          fnQuery(
            campo + " = '" + lstSelect_01_RMI.value + "'",
            campo,
            "",
            false
          );
          break;
      }
    }
    function fnSelect02(value: any) {
      console.log("fnSelect02");
      console.log(value);
      setLstSelect_02_RMIvalue(value);
      switch (Number(cmbRMIConsulta.value)) {
        case 1: //Tipo de Roca
          console.log(cmbRMIConsulta.value);
          break;
        case 2: //Uso
          console.log();
          fnUso(value);

          break;
        case 3: //Ubicacion
          console.log(lstSelect_02_RMIvalue);
          if (Number(lstSelect_01_RMI.value) == 1) {
            fnUbicacion(2);
          } else {
            {
              fnUbicacion(6);
            }
          }
          break;
        case 4: //Roca/Mineral
          console.log(cmbRMIConsulta.value);
          break;
      }
    }
    function fnSelect03(value: any) {
      console.log("fnSelect03");
      console.log(value);
      setLstSelect_03_RMIvalue(value);
      switch (Number(cmbRMIConsulta.value)) {
        case 1: //Tipo de Roca
          console.log(cmbRMIConsulta.value);
          break;
        case 2: //Uso
          console.log(value);
          fnUso(value);

          break;
        case 3: //Ubicacion
          console.log(cmbRMIConsulta.value);

          fnUbicacion(3);

          break;
        case 4: //Roca/Mineral
          console.log(cmbRMIConsulta.value);
          break;
      }
    }
    function fnSelect04(value: any) {
      console.log("fnSelect04");
      console.log(value);
      setLstSelect_04_RMIvalue(value);
      switch (Number(cmbRMIConsulta.value)) {
        case 1: //Tipo de Roca
          console.log(cmbRMIConsulta.value);
          break;
        case 2: //Uso
          console.log(lstSelect_04_RMI.value);
          fnUso(value);

          break;
        case 3: //Ubicacion
          console.log(cmbRMIConsulta.value);
          fnUbicacion(4);
          break;
        case 4: //Roca/Mineral
          console.log(cmbRMIConsulta.value);
          break;
      }
    }
    function fnSelect05() {
      switch (Number(cmbRMIConsulta.value)) {
        case 1: //Tipo de Roca
          console.log(cmbRMIConsulta.value);
          break;
        case 2: //Uso
          console.log(lstSelect_02_RMI.value);
          //_thisRMI.fnUso(lstSelect_05_RMI.value[0]);
          fnRocaMineral(2);

          break;
        case 3: //Ubicacion
          console.log(cmbRMIConsulta.value);
          break;
        case 4: //Roca/Mineral
          console.log(cmbRMIConsulta.value);

          break;
      }
    }

    function fnQuery(where: any, fields: any, _lista: any, unico: any) {
      dispatch(setRecords(0));
      searching.dataSources = [];
      searching.setExecuted(false);

      showAlert({
        title: "Rocas y Minerales Indusctriales",
        message:
          "Por favor espere unos minutos. Se está generando la información solicitada",
        type: "info",
        autoClose: true,
      });

      arrFields = configRMI.layout as any;
      //console.log("fnQuery");
      //console.log(where);
      const queryUrl =
        "https://geocatminapp.ingemmet.gob.pe/arcgis/rest/services/SERV_ROCAS_MINERALES_INDUSTRIALES/MapServer/0";
      arrData = [];
      console.log(where);
      if (unico) {
        //Carga Combo

        executeQueryJSON
          .call(null, queryUrl, {
            where: where,
            outFields: [fields],
            returnGeometry: false,
            returnDistinctValues: true,
            orderByFields: fields,
            // outFields: this.outFields,
          })
          .then(
            (results) => {
              //console.log(results);
              const arrDataTemp = results.features;
              arrData.push({
                id: "",
                name: "-- Seleccione --", // {t("screens.widgets.seleccione")}
              } as never);
              arrDataTemp.forEach((e) => {
                arrData.push({
                  id: e.attributes[fields],
                  name: e.attributes[fields],
                } as never);
              });
              //console.log(arrData);
              fnllenarSelect(arrData, _lista);
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        utils.loadLayer(view, capa, subCapa, where);

        dataSource.setUrlQuery(queryUrl);
        dataSource.setQueryWhere(where);
        dataSource.Search(false, false);
        //console.log(dataSource);
        searching.dataSources.push(dataSource);

        // catastro.setVisible(false);
        // catastro.setUrlQuery(queryUrl);
        // catastro.SetQueryWhere(where);
        // catastro.setCapa(capa);
        // catastro.setSubCapa(subCapa);
        // catastro.setFields(arrFields);
        // catastro.BuscarCatastro();
      }
    }
    function fnllenarSelect(data: any, select: any) {
      //console.log(data);
      //console.log(select);
      //setArr_01_rmi
      //lstSelect_01_RMI

      switch (select) {
        case "lstSelect_01_RMI":
          setArr_01_rmi(data as never);
          break;
        case "lstSelect_02_RMI":
          setArr_02_rmi(data as never);
          break;
        case "lstSelect_03_RMI":
          setArr_03_rmi(data as never);
          break;
        case "lstSelect_04_RMI":
          setArr_04_rmi(data as never);
          break;
      }

      // _thisRMI.fnIniciarSelect(select)
      // var sel = dom.byId(select);
      // var codigoAnterior = '';
      // for (var i = 0; i < data.length; i++) {
      //     if (codigoAnterior != data[i].Codigo) {
      //         //if (((data[i].Codigo).trim()).length > 0) {
      //         var c = dojo.doc.createElement('option');
      //         c.innerHTML = data[i].Descripcion;
      //         c.value = data[i].Codigo;
      //         sel.appendChild(c);
      //         codigoAnterior = data[i].Codigo;
      //         //}
      //     }

      //     // console.log(i);
      // };
    }

    function onSelectTipo(value: any) {
      setArr_01_rmi(arrData as never);
      //console.log(value);
      setLstSelect_Tipovalue(value);
      //console.log(cmbRMIConsulta.value);
      switch (Number(value)) {
        case 1: //Tipo de Roca
          //console.log(value);
          break;
        case 2: //Uso
          //console.log(value);
          fnUso("");
          break;
        case 3: //Ubicacion
          fnUbicacion(0);
          break;
        case 4: //Roca/Mineral
          fnRocaMineral(0);
          break;
        case 5: //Proyecto
          fnProyecto(0);
          break;
        case 6: //Categoría del Depósito
          fnCategoria(0);
          break;
        case 7: //Tamaño del Depósito
          fnTamanio(0);
          break;
        case 8: //Categoría de Producción
          fnCategoriaProd(0);
          break;
        default:
          fnMostrarDiv("");
      }
    }
    //loadLayer();
  }, [
    lstSelect_Tipovalue,
    lstSelect_01_RMIvalue,
    lstSelect_02_RMIvalue,
    lstSelect_03_RMIvalue,
    lstSelect_04_RMIvalue,
    view,
  ]);
  function btnManualRMI() {
    const modal: any = document.getElementById("manualRMIModalPDF");
    modal.open = true;
  }
  return (
    <>
      <div id="divRocaMineralIndustrial">
        <>
          <div className="row pt-2">
            <CalciteLabel>
              {t("screens.widgets.RocaMineralIndustrial.Consulta")}
              <CalciteSelect
                id="cmbRMIConsulta"
                label={t("screens.widgets.RocaMineralIndustrial.Consulta")}
                placeholder=" - Seleccione -"
                // onCalciteSelectChange={(e) => onSelectTipo(e.target.value)}
              >
                <CalciteOption value="">
                  {t("screens.widgets.seleccione")}
                </CalciteOption>
                <CalciteOption value="4">
                  {t("screens.widgets.RocaMineralIndustrial.sustancia")}
                </CalciteOption>
                <CalciteOption value="2">
                  {t("screens.widgets.RocaMineralIndustrial.inf_geol_econ")}
                </CalciteOption>
                <CalciteOption value="3">
                  {t("screens.widgets.RocaMineralIndustrial.ubigeo")}
                </CalciteOption>
                <CalciteOption value="5">
                  {t("screens.widgets.RocaMineralIndustrial.proyecto")}
                </CalciteOption>
                <CalciteOption value="6">
                  {t("screens.widgets.RocaMineralIndustrial.categoria_dep")}
                </CalciteOption>
                <CalciteOption value="7">
                  {t("screens.widgets.RocaMineralIndustrial.tamano")}
                </CalciteOption>
                <CalciteOption value="8">
                  {t("screens.widgets.RocaMineralIndustrial.categoria_prod")}
                </CalciteOption>
              </CalciteSelect>
            </CalciteLabel>
          </div>
          {/*************************  Nivel 1 **********************/}
          <div className="row pt-2">
            <CalciteLabel hidden={hideDiv_01_RMI}>
              {t(lblSelect01)}
              <CalciteSelect
                label={t(lblSelect01)}
                id="lstSelect_01_RMI"
                // value={lstSelect_01_RMIvalue}
                // onCalciteSelectChange={(e) => fnSelect01(e.target.value)}
              >
                {arr_01_rmi.map((option: any, idx: number) => {
                  return (
                    <CalciteOption key={idx} value={option.id}>
                      {option.name}
                    </CalciteOption>
                  );
                })}
              </CalciteSelect>
            </CalciteLabel>
          </div>
          {/*************************  Nivel 2 **********************/}
          <div className="row pt-2">
            <CalciteLabel hidden={hideDiv_02_RMI}>
              {t(lblSelect02)}
              <CalciteSelect
                label={t(lblSelect02)}
                id="lstSelect_02_RMI"
                // onCalciteSelectChange={(e) => fnSelect02(e.target.value)}
              >
                {arr_02_rmi.map((option: any, idx: number) => {
                  return (
                    <CalciteOption key={idx} value={option.id}>
                      {option.name}
                    </CalciteOption>
                  );
                })}
              </CalciteSelect>
            </CalciteLabel>
          </div>
          {/*************************  Nivel 3 **********************/}
          <div className="row pt-2">
            <CalciteLabel hidden={hideDiv_03_RMI}>
              {t(lblSelect03)}
              <CalciteSelect
                label={t(lblSelect03)}
                id="lstSelect_03_RMI"
                // onCalciteSelectChange={(e) => fnSelect03(e.target.value)}
              >
                {arr_03_rmi.map((option: any, idx: number) => {
                  return (
                    <CalciteOption key={idx} value={option.id}>
                      {option.name}
                    </CalciteOption>
                  );
                })}
              </CalciteSelect>
            </CalciteLabel>
          </div>
          {/*************************  Nivel 4 **********************/}
          <div className="row pt-2">
            <CalciteLabel hidden={hideDiv_04_RMI}>
              {t(lblSelect04)}
              <CalciteSelect
                label={t(lblSelect04)}
                id="lstSelect_04_RMI"
                // onCalciteSelectChange={(e) => fnSelect04(e.target.value)}
              >
                {arr_04_rmi.map((option: any, idx: number) => {
                  return (
                    <CalciteOption key={idx} value={option.id}>
                      {option.name}
                    </CalciteOption>
                  );
                })}
              </CalciteSelect>
            </CalciteLabel>
          </div>
          {/*************************  Nivel 5 **********************/}
          <div className="row pt-2">
            <CalciteLabel hidden={hideDiv_05_RMI}>
              {t(lblSelect05)}
              <CalciteSelect
                label={t(lblSelect05)}
                id="lstSelect_05_RMI"
                // onCalciteSelectChange={(e) => fnSelect04(e.target.value)}
              >
                {arr_05_rmi.map((option: any, idx: number) => {
                  return (
                    <CalciteOption key={idx} value={option.id}>
                      {option.name}
                    </CalciteOption>
                  );
                })}
              </CalciteSelect>
            </CalciteLabel>
          </div>
          <div className="row pt-2">
            <CalciteButton
              iconStart="notebook"
              label={t("screens.widgets.ConsultaGeologica.ver_manual")}
              onClick={() => btnManualRMI()}
              //appearance="transparent"
            >
              {t("screens.widgets.ConsultaGeologica.ver_manual")}
            </CalciteButton>
          </div>
          <CustomPopupPDF
            title="Manual Rocas y Minerales Industriales"
            url={manualRMI}
            id="manualRMIModalPDF"
          ></CustomPopupPDF>
        </>
      </div>

      <Toaster />
    </>
  );
}
