import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  CalciteLabel,
  CalciteSelect,
  CalciteOption,
  CalciteColorPicker,
  CalcitePopover,
  CalciteInput,
  CalciteButton,
} from "@esri/calcite-components-react";
import configMapaTematico from "./ConfigMapaTematico.json";
import * as colorRendererCreator from "@arcgis/core/smartMapping/renderers/color";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer";

export function MapaTematico(props: any) {
  const { t } = useTranslation();

  let view = props.view;
  let activo = props.activo;
  let widget = props.widget;

  const [arrCapa, setCapa] = useState([]);
  const [urlCapa, setUrlCapa] = useState("");
  const [arrCampos, setArrCampos] = useState([]);

  //  const [clasificacion, setClasificacion] = useState("natural-breaks");
  const [numClases, setNumClases] = useState(3);

  // const [colorMax, setColorMax] = useState("#FF0000"); // Initial color value
  // const [colorMin, setColorMin] = useState("#FF6666");
  // const [transparencia, setTransparencia] = useState(50);
  // const [etiquetas, setEtiquetas] = useState(false);

  let arrCapasMT: any[];
  let arrCamposMT: any[];
  const [selectedCapaMT, setSelectedCapaMT] = useState("");
  const [selectedCamposMT, setSelectedCamposMT] = useState("");
  const [txtClasesMT, setTxtClasesMT] = useState("3");
  const [CapaActiva, setCapaActiva] = useState("");
  let layerMapaTematico = new FeatureLayer();
  let urlCapaMapaTematico = "";
  const featureLayerRef = useRef(null);

  const arrVacio = [{ valor: "0", label: "-- Seleccione --" }];

  useEffect(() => {
    function loadInit() {
      arrCapasMT = configMapaTematico.tematico;

      const arrDataTemp: never[] = [];
      arrDataTemp.push(arrVacio[0] as never);
      arrCapasMT.forEach((e) => {
        arrDataTemp.push({
          valor: e.label,
          label: e.label,
        } as never);
      });

      setCapa(arrDataTemp as never);
    }

    const cmbCapasMT = document.getElementById(
      "cmbCapasMT"
    ) as HTMLSelectElement;

    cmbCapasMT.addEventListener("calciteSelectChange", () => {
      console.log("cmbCapasMT");
      if (cmbCapasMT.value.length > 1) {
        const evenNumbers = arrCapasMT.filter(
          (capa) => capa.label === cmbCapasMT.value
        );

        // console.log(evenNumbers[0].url);

        // console.log(layerMapaTematico.url);
        //setUrlCapa(evenNumbers[0].url);
        urlCapaMapaTematico = evenNumbers[0].url;
        // layerMapaTematico = new FeatureLayer({
        //   url: urlCapaMapaTematico,
        // });

        arrCamposMT = evenNumbers[0].campos;
        setArrCampos(arrVacio as never);

        const arrDataTemp: never[] = [];
        arrDataTemp.push(arrVacio[0] as never);
        arrCamposMT.forEach((e) => {
          arrDataTemp.push({
            label: e.label,
            valor: e.valor,
          } as never);
        });
        //revisar No se inicializa adecuadamente, debe estar en el primer registro
        setArrCampos(arrDataTemp as never);

        setTxtClasesMT(evenNumbers[0].classificationBreaksDefault);
      } else {
        setArrCampos(arrVacio as never);
      }
    });
    const cmbCampo = document.getElementById("cmbCampo") as HTMLSelectElement;
    cmbCampo.addEventListener("calciteSelectChange", () => {
      setSelectedCamposMT(cmbCampo.value);
      if (cmbCampo.value.length > 1) {
        fnMapaTematico();
      } else {
        console.log("Seleccione un campo");
      }
    });

    const cmbClasificacion = document.getElementById(
      "cmbClasificacion"
    ) as HTMLSelectElement;
    cmbClasificacion.addEventListener("calciteSelectChange", () => {
      fnMapaTematico();
    });
    const txtClases = document.getElementById("txtClases") as HTMLSelectElement;
    txtClases.addEventListener("calciteInputChange", () => {
      fnMapaTematico();
    });
    function fnMapaTematico() {
      try {
        if (cmbCapasMT.value.length > 1 && cmbCampo.value.length > 1) {
          // console.log("fnMapaTematico");
          // console.log(urlCapaMapaTematico);
          // console.log(cmbCapasMT.value);
          // console.log(cmbCampo.value);
          // console.log(cmbClasificacion.value);
          // console.log(txtClases.value);
          // layerMapaTematico = new FeatureLayer({
          //   url: urlCapaMapaTematico,
          // });
          const layerMapaTematico = new FeatureLayer();
          layerMapaTematico.url = urlCapaMapaTematico;

          layerMapaTematico.title = cmbCapasMT.value + " - " + cmbCampo.value;

          const params = {
            layer: layerMapaTematico,
            field: cmbCampo.value,
            view: view,
            classificationMethod: cmbClasificacion.value as any,
            numClasses: parseInt(txtClases.value),
            theme: "extremes",
          };

          colorRendererCreator
            .createClassBreaksRenderer(params)
            .then((rendererResponse) => {
              layerMapaTematico.renderer = rendererResponse.renderer;
              if (featureLayerRef.current) {
                view.map.remove(featureLayerRef.current);
              }
              featureLayerRef.current = layerMapaTematico as any;
              view.map.add(layerMapaTematico);
            })
            .catch(function (error) {
              console.error("Error creating renderer:", error);
            });
        }
      } catch (e) {
        console.error(e);
      }
    }
    loadInit();
  }, []);

  return (
    <div id="divMapaTematico">
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.mapatematico.capas")}
          <CalciteSelect
            id="cmbCapasMT"
            label={t("screens.widgets.mapatematico.capas")}
          >
            {arrCapa.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.valor}>
                  {option.label}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.mapatematico.campo")}
          <CalciteSelect
            id="cmbCampo"
            label={t("screens.widgets.mapatematico.campo")}
            // value={selectedCamposMT}
            // onCalciteSelectChange={(e) => fnChangeCampoMT(e.target.value)}
          >
            {/* <CalciteOption value="--" selected>
              {t("screens.widgets.seleccione")}
            </CalciteOption> */}
            {arrCampos.map((option: any, idx: number) => {
              return (
                <CalciteOption key={idx} value={option.valor}>
                  {option.label}
                </CalciteOption>
              );
            })}
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.mapatematico.clasificacion")}
          <CalciteSelect
            id="cmbClasificacion"
            label={t("screens.widgets.mapatematico.clasificacion")}
            // onCalciteSelectChange={(e) => fnClasificacion(e.target.value)}
          >
            <CalciteOption value="quantile" selected>
              Quantile
            </CalciteOption>
            <CalciteOption value="equal-interval">Equal Interval</CalciteOption>
            <CalciteOption value="natural-breaks">Natural Breaks</CalciteOption>
          </CalciteSelect>
        </CalciteLabel>
      </div>
      <div className="row pt-2">
        <CalciteLabel>
          {t("screens.widgets.mapatematico.clases")}
          <CalciteInput
            id="txtClases"
            type="number"
            value={txtClasesMT}
            // onCalciteInputChange={(e) => setNumClases(Number(e.target.value))}
          ></CalciteInput>
        </CalciteLabel>
      </div>
    </div>
  );
}
