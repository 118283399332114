import { CalciteButton, CalciteLabel, CalciteSwitch } from "@esri/calcite-components-react";
import { useTranslation } from "react-i18next";

export function SwitchCssMode(view: any) {
  const { t } = useTranslation();

  const updateDarkMode = () => {
    // Calcite mode
    document.body.classList.toggle("calcite-mode-dark");
    // ArcGIS Maps SDK theme
    const dark = document.querySelector(
      "#arcgis-maps-sdk-theme-dark"
    ) as HTMLLinkElement;
    const light = document.querySelector(
      "#arcgis-maps-sdk-theme-light"
    ) as HTMLLinkElement;
    dark.disabled = !dark.disabled;
    light.disabled = !light.disabled;
    // ArcGIS Maps SDK basemap
    view?.view?.map.set({
      basemap: dark.disabled ? "gray-vector" : "dark-gray-vector",
    });
  };

  return (
    <>
      <CalciteButton
          icon-end="moon" kind="neutral"
          onClick={() => updateDarkMode()}
      ></CalciteButton>
    </>
  );
}
