import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SessionPayload } from "../types/session";

const intialState: SessionPayload = {
  isAuthenticated: false,
  accessToken: "",
};

const sessionSlice = createSlice({
  name: "session",
  initialState: intialState,
  reducers: {
    startSession: (state, { payload }: PayloadAction<string>) => {
      return { ...state, accessToken: payload, isAuthenticated: true };
    },
    endSession: (state) => {
      return (state = intialState);
    },
  },
});

export const { startSession, endSession } = sessionSlice.actions;

export { sessionSlice };

export default sessionSlice.reducer;
