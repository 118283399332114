import { useEffect, useState } from "react";
import LayerList from "@arcgis/core/widgets/LayerList";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../core/providers/AlertProvider";
import configCatastro from "./ConfigListadoCapas.json";
import Extent from "@arcgis/core/geometry/Extent";
import { CustomFunctions } from "../../utils/CustomFunctions";
import {
  CalciteIcon,
  CalciteInput,
  CalciteLabel,
} from "@esri/calcite-components-react";
import { Await } from "react-router-dom";

export const ListadoCapas = (props: any) => {
  //const layerList: LayerList = props.layerlist;
  const view = props.view;
  const is3D = props.is3D;
  const { t } = useTranslation();
  const showAlert = useAlert();
  const [counter, setCounter] = useState(0);
  const utils = new CustomFunctions();

  useEffect(() => {}, []);

  let bolExpand = false;

  const [txtCapa, setTxtCapa] = useState("");
  const [EstadoCapas, setEstadoCapas] = useState(false);
  //const [divTituloStyle, setdivTituloStyle] = useState("display: flex'");

  const containerlistadocapas = document.getElementById(
    "layers-container"
  ) as HTMLSelectElement;

  const [classTitulo, setClassTitulo] = useState("divFlex");
  const [classBuscar, setClassBuscar] = useState("divNone");
  const [classOpciones, setClassOpciones] = useState("divNone");

  useEffect(() => {
    let arrMetadata: any[];
    let arrDownload: any[];
    let arrKmz: any[];

    async function configListLayer() {
      await fetch(`${process.env.PUBLIC_URL}/config/ConfigListadoCapas.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          //console.log(response);
          return response.json();
        })
        .then((data) => {
          //console.log(data);
          // let arrConfigTempKMZ: any[];
          // let arrConfigTempDescarga: any[];
          // let arrConfigTempMetadato: any[];

          arrMetadata = data.ListaMetadato;
          arrDownload = data.ListaDescarga;
          arrKmz = data.ListaKMZ;

          layerList.view = view;
          layerList.container = "layers-container";
          layerList.listItemCreatedFunction = defineActions;
          // arrConfigTempKMZ = data.ListaKMZ;
          // setArrKmz(arrConfigTempKMZ as any);
          // console.log(arrKmz);

          // arrConfigTempDescarga = data.ListaDescarga;
          // setArrDownload(arrConfigTempDescarga as any);
          // console.log(arrDownload);

          // arrConfigTempMetadato = data.ListaMetadato;
          // setArrMetadata(arrConfigTempMetadato as any);
          // console.log(arrMetadata);
        })
        .catch((error) => console.error(error));
    }

    const layerList = new LayerList({
      selectionEnabled: true,
    });

    configListLayer();

    layerList.on("trigger-action", (event) => {
      //console.log(event, event.item.layer.fullExtent);
      // Capture the action id.

      const id = event.action.id;
      const visibleLayer = event.item.layer as any;
      //const extent = event.item.layer.fullExtent;
      //const url_dato = event.item.layer.url;
      try {
        switch (id) {
          case "full-extent":
            const extent = new Extent({
              xmin: -81.83199119546,
              ymin: -18.824816351589998,
              xmax: -67.96561295011001,
              ymax: 0.204784303349982,

              spatialReference: {
                wkid: 4326,
              },
            });

            view.goTo({ extent });
            //   view.goTo({
            //     center: [-118, 36],
            //     zoom: 5,
            //   });
            break;
          case "information":
            window.open(visibleLayer.url);
            break;
          case "increase-opacity":
            if (event.item.layer.opacity < 1) {
              event.item.layer.opacity += 0.25;
            }
            break;
          case "decrease-opacity":
            if (event.item.layer.opacity > 0) {
              event.item.layer.opacity -= 0.25;
            }
            break;
          case "metadata":
            const metadata = arrMetadata.filter(function (itm: any) {
              return (
                itm.title.toLowerCase() == visibleLayer.title.toLowerCase()
              );
            }) as any;
            window.open(metadata[0].url);
            break;
          case "download":
            const download = arrDownload.filter(function (itm: any) {
              return (
                itm.title.toLowerCase() == visibleLayer.title.toLowerCase()
              );
            }) as any;
            window.open(download[0].url);
            break;
          case "kml":
            const kmz = arrKmz.filter(function (itm: any) {
              return (
                itm.title.toLowerCase() == visibleLayer.title.toLowerCase()
              );
            }) as any;
            window.open(kmz[0].url);
            break;
        }
      } catch (e) {
        console.log(e);
      }
    });
    //}
    async function defineActions(event: any) {
      const item = event.item;
      try {
        await item.layer.when();
        //console.log(item.layer.title + "*********");
        if (item.layer.sublayers != undefined) {
          let arrDatosINGEMMET: never[] = [];

          try {
            const metadato = arrMetadata.filter(function (itm: any) {
              return itm.title.toLowerCase() == item.layer.title.toLowerCase();
            });
            const kmz = arrKmz.filter(function (itm: any) {
              return itm.title.toLowerCase() == item.layer.title.toLowerCase();
            });

            if (metadato.length > 0)
              arrDatosINGEMMET.push({
                title: "ver Metadata",
                className: "esri-icon-documentation",
                id: "metadata",
              } as never);

            if (kmz.length > 0)
              arrDatosINGEMMET.push({
                title: "ver KML",
                className: "esri-icon-feature-layer",
                id: "kml",
              } as never);
            // console.log("/**********************");
            // console.log(item.layer.title);
            // console.log(arrMetadata);
            // console.log("**********************/");
          } catch (e) {
            console.log(e);
            // console.log(item.layer.title);
            // console.log(arrMetadata);
          }

          if (arrDatosINGEMMET.length > 0) {
            item.actionsSections.push(arrDatosINGEMMET as never);
          }

          item.actionsSections.push([
            {
              title: "Layer information",
              className: "esri-icon-description",
              id: "information",
            },
          ]);
          item.actionsSections.push([
            {
              title: "Increase opacity",
              className: "esri-icon-up",
              id: "increase-opacity",
            },
            {
              title: "Decrease opacity",
              className: "esri-icon-down",
              id: "decrease-opacity",
            },
          ]);
        }
      } catch (e) {
        //console.log(item.layer.title + "*********");
        console.log(e);
      }
    }
  }, [props, is3D]);

  //let divTitulo1 = document.getElementById("divTitulo") as HTMLSelectElement;
  // let divBuscar = document.getElementById("divBuscar") as HTMLSelectElement;
  // let divMasOpciones = document.getElementById(
  //   "divMasOpciones"
  // ) as HTMLSelectElement;

  const fnBuscarCapas = () => {
    //console.log("BuscarCapas");
    setClassTitulo("divNone");
    //divTitulo1.style.display = "none";
    //divMasOpciones.style.display = "none";
    setClassOpciones("divNone");
    //divBuscar.style.display = "flex";
    setClassBuscar("divFlex");
  };
  const fnMasOpciones = () => {
    setClassOpciones(classOpciones == "divFlex" ? "divNone" : "divFlex");

    // divMasOpciones.style.display =
    //   divMasOpciones.style.display === "flex" ? "none" : "flex";
  };

  const fnLimpiarBusqueda = () => {
    //console.log("fnLimpiarBusqueda");
    setTxtCapa("");
  };

  const fnCerrarBusqueda = () => {
    //divTitulo1.style.display = "flex";
    setClassTitulo("divFlex");
    setClassBuscar("divNone");
    //divBuscar.style.display = "none";
    setTxtCapa("");
  };

  // Filtar y expande capas
  useEffect(() => {
    try {
      if (txtCapa.length > 0) {
        setEstadoCapas(true);
        let elementos = containerlistadocapas.querySelectorAll("ul li");
        elementos.forEach(function (elemento: any) {
          elemento.style.display = "none";
          if (
            elemento.innerText.toUpperCase().indexOf(txtCapa.toUpperCase()) > -1
          ) {
            elemento.style.display = "";
          }
        });
      } else sinFilterLayer();
    } catch (e) {
      console.log(e);
    }
    function sinFilterLayer() {
      setEstadoCapas(false);
      let elementos = containerlistadocapas.querySelectorAll("ul li");
      elementos.forEach(function (elemento: any) {
        elemento.style.display = "";
      });
    }
  }, [txtCapa]);
  useEffect(() => {
    // console.log(EstadoCapas);
    var ctSpans = document.getElementsByClassName(
      "esri-layer-list__child-toggle"
    );

    for (var i = 0; i < ctSpans.length; i++) {
      fnExpandOnOff(EstadoCapas, "data-item", ctSpans[i]);
    }
    //}
    function fnExpandOnOff(tipo: boolean, property: string, objeto: any) {
      objeto[property].open = tipo;
    }
  }, [EstadoCapas]);
  function fnActivar(tipo: boolean) {
    view.map.allLayers.forEach((e: any) => {
      try {
        //console.log(e.url + " --> " + e.title + " --> " + e.type);
        if (e.url != null) {
          if (
            (e.type == "map-image" ||
              e.type == "imagery" ||
              e.type == "feature" ||
              e.type == "tile" ||
              e.type == "web-tile") &&
            e.url.indexOf("arcgisonline") < 0 &&
            e.url.indexOf("arcgis.com") < 0
          ) {
            e.visible = tipo;
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  }
  return (
    <div>
      <div style={{ marginLeft: "10px", height: "35px" }}>
        <div id="divTitulo" className={classTitulo}>
          {/*style={{ display: "flex" }} */}
          <CalciteLabel style={{ marginTop: "5px" }}>
            {t("screens.menu.searching.listadocapas")}
          </CalciteLabel>
          <div style={{ position: "absolute", right: "0" }}>
            <CalciteIcon
              icon="search"
              scale="l"
              style={{ cursor: "pointer", padding: "5px" }}
              text-label={t(
                "screens.widgets.ListadoCapa.btnBuscarListadoCapas"
              )}
              onClick={() => fnBuscarCapas()}
            ></CalciteIcon>

            <CalciteIcon
              icon="hamburger"
              scale="l"
              style={{ cursor: "pointer", padding: "5px" }}
              text-label={t("screens.widgets.ListadoCapa.btnMasOpciones")}
              onClick={() => fnMasOpciones()}
            ></CalciteIcon>
            {/* 
            <CalciteButton
              iconStart="search"
              appearance="transparent"
              id="btnBuscarListadoCapas"
              label={t("screens.widgets.searchdm.btnsearch")}
              title={t("screens.widgets.ListadoCapa.btnBuscarListadoCapas")}
              className="ms-2"
            ></CalciteButton>
            <CalciteButton
              iconStart="hamburger"
              appearance="transparent"
              label={t("screens.widgets.searchdm.btnsearch")}
              id="btnMasOpciones"
              title={t("screens.widgets.ListadoCapas.btnMasOpciones")}
              className="ms-2"
            ></CalciteButton>
             */}
          </div>
        </div>
        {/* Controles de Busqueda */}
        <div
          id="divBuscar"
          className={classBuscar}
          // style={{ marginLeft: "10px", display: "none" }}
          // className="oculto"
        >
          <CalciteInput
            id="txtCapaBuscar"
            placeholder={t("screens.widgets.ListadoCapa.texto")}
            type="text"
            value={txtCapa}
            style={{ position: "absolute", left: "10px", right: "80px" }}
            //onCalciteInputChange={(e) => setTxtCapa(e.target.value)}
            onKeyUp={(e) => setTxtCapa(e.currentTarget.value)}
          ></CalciteInput>

          <div style={{ position: "absolute", right: "0" }}>
            <CalciteIcon
              icon="trash"
              scale="l"
              style={{ cursor: "pointer", padding: "5px" }}
              text-label={t(
                "screens.widgets.ListadoCapa.btnBuscarListadoCapas"
              )}
              onClick={() => fnLimpiarBusqueda()}
            ></CalciteIcon>

            <CalciteIcon
              icon="x-circle"
              scale="l"
              style={{ cursor: "pointer", padding: "5px" }}
              text-label={t(
                "screens.widgets.ListadoCapa.btnBuscarListadoCapas"
              )}
              onClick={() => fnCerrarBusqueda()}
            ></CalciteIcon>

            {/* <CalciteButton
              iconStart="trash"
              appearance="transparent"
              id="btnLimpiarLC"
              label={t("screens.widgets.searchdm.btnsearch")}
              //   onClick={() => searchTenements()}
              className="ms-2"
              title={t("screens.widgets.ListadoCapas.btnLimpiarLC")}
              // hidden={hideBusqueda}
            ></CalciteButton>
            <CalciteButton
              iconStart="x-circle"
              appearance="transparent"
              id="btnCerrarListadoCapas"
              title={t("screens.widgets.ListadoCapas.btnCerrarListadoCapas")}
              label={t("screens.widgets.searchdm.btnsearch")}
              className="ms-2"
            ></CalciteButton> */}
          </div>
        </div>
      </div>
      {/* Controles de Operacion con las Capas */}
      <div
        role="group"
        aria-expanded="true"
        id="divMasOpciones"
        //className="esri-layer-list__item-actions"
        className={classOpciones}
      >
        <ul className="esri-layer-list__item-actions-list">
          <li
            className="esri-layer-list__item-action"
            role="button"
            id="activarCapa"
            title={t("screens.widgets.ListadoCapa.activar")}
            aria-label={t("screens.widgets.ListadoCapas.activar")}
            onClick={() => fnActivar(true)}
          >
            <span
              aria-hidden="true"
              className="esri-layer-list__item-action-icon esri-icon-checkbox-checked"
            ></span>
            <span className="esri-layer-list__item-action-title">
              {t("screens.widgets.ListadoCapa.activar")}
            </span>
          </li>
          <li
            className="esri-layer-list__item-action"
            role="button"
            id="desactivarCapa"
            title={t("screens.widgets.ListadoCapa.desactivar")}
            aria-label={t("screens.widgets.ListadoCapa.desactivar")}
            onClick={() => fnActivar(false)}
          >
            <span
              aria-hidden="true"
              className="esri-layer-list__item-action-icon esri-icon-checkbox-unchecked"
            ></span>
            <span className="esri-layer-list__item-action-title">
              {t("screens.widgets.ListadoCapa.desactivar")}
            </span>
          </li>
        </ul>
        <ul className="esri-layer-list__item-actions-list">
          <li
            className="esri-layer-list__item-action"
            role="button"
            id="expandirCapa"
            title={t("screens.widgets.ListadoCapa.expandir")}
            aria-label={t("screens.widgets.ListadoCapa.expandir")}
            onClick={() => setEstadoCapas(true)}
          >
            <span
              aria-hidden="true"
              className="esri-layer-list__item-action-icon esri-icon-plus"
            ></span>
            <span className="esri-layer-list__item-action-title">
              {" "}
              {t("screens.widgets.ListadoCapa.expandir")}{" "}
            </span>
          </li>
          <li
            className="esri-layer-list__item-action"
            role="button"
            id="contraerCapa"
            title={t("screens.widgets.ListadoCapa.contraer")}
            aria-label={t("screens.widgets.ListadoCapa.contraer")}
            onClick={() => setEstadoCapas(false)}
          >
            <span
              aria-hidden="true"
              className="esri-layer-list__item-action-icon esri-icon-minus"
            ></span>
            <span className="esri-layer-list__item-action-title">
              {t("screens.widgets.ListadoCapa.contraer")}
            </span>
          </li>
        </ul>
      </div>
      {/* Controles para el cuerpo de Listado de Capas */}
      <div
        id="layers-container"
        style={{
          maxHeight: "400px",
          maxWidth: "350px",
          minWidth: "350px",
          textAlign: "left",
        }}
      ></div>
    </div>
  );
};
