import React, { useEffect, useState, useRef } from "react";
import {
  CalciteButton,
  CalciteInput,
  CalciteLabel,
  CalciteRadioButtonGroup,
  CalciteRadioButton,
  CalciteSelect,
  CalciteOption,
  CalciteCheckbox,
} from "@esri/calcite-components-react";
import { CustomPopupPDF } from "../../components/CustomPopupPDF";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import { CatastroMinero } from "./CatastroMinero";
import { useAppDispatch } from "../../redux/hooks";
import { setRecords } from "../../redux/slices/userActionsSlice";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import * as geoprocessor from "@arcgis/core/rest/geoprocessor";
import JobInfo from "@arcgis/core/rest/support/JobInfo";
import ParameterValue from "@arcgis/core/rest/support/ParameterValue";

export function MapaPeligrosGeologicos(props: any) {
  const { t } = useTranslation();
  let view = props.view;
  const [txtTitulo, setTxtTitulo] = useState("");
  const [txtNumeroMapa, setTxtNumeroMapa] = useState("");
  const [txtTipoMapa, setTxtTipoMapa] = useState("1");
  const [txtSolicitante, setTxtSolicitante] = useState("1");

  const [contenidoMapaPeligrosHTML, setContenidoMapaPeligrosHTML] =
    useState("");

  const [disableProcesarMapaPeligros, setDisableProcesarMapaPeligros] =
    useState(true);
  const [disableCancelMapaPeligros, setDisableCancelMapaPeligros] =
    useState(true);

  const [chkExportarMapaPeligroGeologico, setChkExportarMapaPeligroGeologico] =
    useState(true);
  const [
    chkExportarDatosMapaPeligroGeologico,
    setChkExportarDatosMapaPeligroGeologico,
  ] = useState(false);

  let zip_graphic: null;

  const symbolPoint = {
    type: "simple-marker",
    color: "red",
    style: "cross",
    size: "12px",
    outline: {
      color: [255, 0, 0],
      width: 1,
    },
  };
  const symbolLine = {
    color: [255, 0, 0, 11, 0.75],
    width: 1,
  };
  const symbolPoly = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.5],
    style: "solid",
    outline: symbolLine,
  };
  const symbolLineBufffer = {
    color: [255, 0, 0, 0],
    width: 1,
  };
  const symbolPolyBuffer = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: symbolLineBufffer,
  };
  let txtSolicitanteDato = "";
  let txtTipoMapaDato = "";
  useEffect(() => {
    let json_graphic: any;
    let geomQuery = new Polygon();

    function loadInitMapaPeligro() {
      const txtTemp =
        "screens.widgets.MapaPeligros.SusceptibilidadMovimientosMasa";
      setTxtTitulo(txtTemp);
      setTxtNumeroMapa("1");

      /***************************** */
      const graphicsLayerMapaPeligroGeologico = new GraphicsLayer();
      const sketchMapaPeligroGeologico = new Sketch({
        layer: graphicsLayerMapaPeligroGeologico,
        creationMode: "single",
        defaultUpdateOptions: {
          tool: "move",
        },
      });
      sketchMapaPeligroGeologico.visibleElements = {
        // createTools: {
        //   point: false,
        //   circle: false,
        // },
        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
        settingsMenu: false,
        undoRedoMenu: false,
      };
      sketchMapaPeligroGeologico.view = view;
      sketchMapaPeligroGeologico.container =
        "sketch-container-MapaPeligroGeologico";
      sketchMapaPeligroGeologico.on("create", function (event) {
        if (event.state === "complete") {
          graphicsLayerMapaPeligroGeologico.remove(event.graphic);

          //selectFeatures(event.graphic.geometry);

          view.graphics.add(event.graphic);
          sketchMapaPeligroGeologico.complete();

          fnGraficarBufferMapaPeligroGeologico(event.graphic.geometry);
        }
      });
    }

    const btnProcesarMapaPeligros = document.getElementById(
      "btnProcesarMapaPeligros"
    ) as HTMLSelectElement;
    btnProcesarMapaPeligros.addEventListener("click", () => {
      // console.log("fnDescargarConsultaGeologica");
      fnDescargarMapaPeligroGeologico(geomQuery);
      setDisableCancelMapaPeligros(false);
    });

    function fnGraficarBufferMapaPeligroGeologico(geometry: any) {
      setDisableProcesarMapaPeligros(false);
      switch (geometry.type) {
        case "point":
          fnGraficarMapaPeligroGeologico(geometry as Point, symbolPoint);
          break;
        case "polyline":
          fnGraficarMapaPeligroGeologico(geometry as Polyline, symbolLine);

          break;
        case "polygon":
          fnGraficarMapaPeligroGeologico(geometry as Polygon, symbolPoly);

          break;
      }
    }
    /******************************************** */
    function fnGraficarMapaPeligroGeologico(geomQuery: any, symbol: any) {
      view.graphics.removeAll();
      json_graphic = geomQuery.toJSON();

      const geomQueryIn = new Graphic({
        geometry: geomQuery,
        symbol: symbol,
      });

      view.graphics.add(geomQueryIn);

      geomQuery = geomQueryIn.geometry as Polygon;
      //fnDescargarMapaPeligroGeologico(geomQueryIn.geometry);
    }
    function fnDescargarMapaPeligroGeologico(geomQuery: any) {
      //  console.log("fnDescargarMapaPeligroGeologico");

      // var dataFile = new DataFile();
      // dataFile.itemID = self_gh.zip_graphic;

      //txtTituloMapa
      mapaPeligro();

      // var params = {
      //   "shapefile": dataFile,
      //   "json": JSON.stringify(self_gh.json_graphic),
      //   "maptype": self_gh.map_type_selected,
      //   "maptitle": self_gh.itTitleMapGhAp.value,
      //   "mapautor": self_gh.stakeHolder,
      //   "mapnumber": dom.byId("itNumberMapGhId").value,
      //   "scale": exportMapState + ',' + exportDataState

      // };
      // self_gh.gp = new Geoprocessor(self_gh.config.mapaPeligrosGeologicosGPService);
      // self_gh.gp.submitJob(params, self_gh._completeCallback, self_gh._statusCallback);
    }
    const mapaPeligro = () => {
      if (json_graphic == undefined) {
        console.log("Dibujar poligono");
        return;
      }
      const txtTituloMapa = document.getElementById(
        "txtTituloMapa"
      ) as HTMLSelectElement;
      const lstSolicitante = document.getElementById(
        "lstSolicitante"
      ) as HTMLSelectElement;
      const rbgOptions = document.getElementById(
        "rbgOptions"
      ) as HTMLSelectElement;

      //console.log(txtSolicitanteDato);
      //console.log(txtTipoMapaDato);
      const itTitleMapGhAp = txtTituloMapa.value;
      const map_type_selected = txtTipoMapa;
      const stakeHolder = txtSolicitanteDato + "_autor"; //lstSolicitante.selectedOption.textContent;
      const itNumberMapGhId = txtNumeroMapa;

      //fnMensaje("Procesando....", "info");
      //props.mensaje = true;

      async function execute(): Promise<void> {
        try {
          const gpUrl =
            "https://geocatmin.ingemmet.gob.pe/arcgis/rest/services/GEOPROCESO/mapaPeligrosGeologicos/GPServer/generarmapasdepeligrosgeologicos";
          const params = {
            shapefile: null, //dataFile,
            json: JSON.stringify(json_graphic),
            maptype: map_type_selected,
            maptitle: itTitleMapGhAp,
            mapautor: stakeHolder,
            mapnumber: itNumberMapGhId,
            scale:
              (chkExportarMapaPeligroGeologico ? "1" : "0") +
              "," +
              (chkExportarDatosMapaPeligroGeologico ? "1" : "0"),
          };

          console.log("Geoprocessor");
          const jobInfo: JobInfo = await geoprocessor.submitJob(gpUrl, params);
          console.log("waitForJobCompletion");
          await jobInfo.waitForJobCompletion({
            statusCallback: checkJobStatus,
          });

          console.log("fetchResultData");

          const result: ParameterValue = await jobInfo.fetchResultData(
            "response"
          );
          const arrDataTemp: never[] = [];
          arrDataTemp.push(result.value as never);
          console.log(arrDataTemp[0]["response"]);

          //const resultado = result['value']['response']['zip_url'];

          //const valor = resultado["response"]
          // console.log(resultado);

          let archPDF = arrDataTemp[0]["response"]["zip_url"] + "#view=Fit";

          console.log(archPDF);
          setContenidoMapaPeligrosHTML(archPDF);

          const modal: any = document.getElementById("mapaPeligrosHTML");
          modal.open = true;
        } catch (e) {
          console.log(e);
          // fnMensaje("Error....", "errro");
        }
      }

      function checkJobStatus(jobInfo: JobInfo): void {
        //console.log(jobInfo.jobStatus);
        console.log(
          jobInfo.messages.map((message) => message.description).join("<br>")
        );
      }
      execute();
    };
    loadInitMapaPeligro();
  }, [txtSolicitanteDato, txtTipoMapaDato]);

  function txtSolicitanteValue(event: any) {
    setTxtSolicitante(event.selectedOption.textContent);
    txtSolicitanteDato = event.selectedOption.textContent;
  }
  const rbgOptionsChange = (event: any) => {
    //setSelectedValue(event.target.value); // Update the selected value state when the radio button changes
    // console.log(event.target.value);
    const txtTemp =
      event.target.value === "1"
        ? "screens.widgets.MapaPeligros.SusceptibilidadMovimientosMasa"
        : "screens.widgets.MapaPeligros.SusceptibilidadInundacionErosion";

    setTxtTipoMapa(event.target.value);
    setTxtTitulo(txtTemp);
    txtTipoMapaDato = event.target.value;
  };

  function fnChkExportarMapaPeligroGeologico(value: any) {
    setChkExportarMapaPeligroGeologico(!value);
  }
  function fnChkExportarDatosMapaPeligroGeologico(value: any) {
    setChkExportarDatosMapaPeligroGeologico(!value);
  }
  function fnManualMapa() {
    setContenidoMapaPeligrosHTML(
      "https://geocatminapp.ingemmet.gob.pe/complementos/Descargas/Manuales/manual_mapa_susceptibilidad.pdf"
    );

    const modal: any = document.getElementById("mapaPeligrosHTML");
    modal.open = true;
  }

  return (
    <>
      <div id="divMapaPeligrosGeologicos">
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.MapaPeligros.Mensaje")}
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.MapaPeligros.dibujar")}
            <div id="sketch-container-MapaPeligroGeologico"></div>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.MapaPeligros.sel_map_exportar")}

            <CalciteRadioButtonGroup
              id="rbgOptions"
              name="rbgOptions"
              layout="vertical"
              //onCalciteRadioButtonGroupChange={rbgOptionsChange}
            >
              <CalciteLabel layout="inline">
                <CalciteRadioButton
                  name="rbMovimientosMasa"
                  value="1"
                  // onClick={rbgOptionsChange}
                  checked
                ></CalciteRadioButton>
                {t(
                  "screens.widgets.MapaPeligros.SusceptibilidadMovimientosMasa"
                )}
              </CalciteLabel>
              <CalciteLabel layout="inline">
                <CalciteRadioButton
                  value="2"
                  name="rbInundacionErosion"
                  // onClick={rbgOptionsChange}
                ></CalciteRadioButton>
                {t(
                  "screens.widgets.MapaPeligros.SusceptibilidadInundacionErosion"
                )}
              </CalciteLabel>
            </CalciteRadioButtonGroup>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.MapaPeligros.Solicitante")}
            <CalciteSelect
              id="lstSolicitante"
              label="Solicitante"
              onCalciteSelectChange={(e) => txtSolicitanteValue(e.target)}
            >
              <CalciteOption value="1" selected>
                {t("screens.widgets.MapaPeligros.GobiernoNacional")}
              </CalciteOption>
              <CalciteOption value="2">
                {t("screens.widgets.MapaPeligros.EntidadesGestorasRiesgo")}
              </CalciteOption>
              <CalciteOption value="3">
                {t("screens.widgets.MapaPeligros.GobiernoRegional")}
              </CalciteOption>
              <CalciteOption value="4">
                {t("screens.widgets.MapaPeligros.GobiernoLocal")}
              </CalciteOption>
              <CalciteOption value="5">
                {t("screens.widgets.MapaPeligros.Entidadesinvestigacion")}{" "}
              </CalciteOption>
              <CalciteOption value="6">
                {t("screens.widgets.MapaPeligros.EntidadesSectoriales")}
              </CalciteOption>
              <CalciteOption value="7">
                {t("screens.widgets.MapaPeligros.EntidadesPrivadas")}
              </CalciteOption>
              <CalciteOption value="8">
                {t("screens.widgets.MapaPeligros.SociedadCivil")}
              </CalciteOption>
              <CalciteOption value="9">
                {t("screens.widgets.MapaPeligros.PersonaNatural")}
              </CalciteOption>
            </CalciteSelect>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.MapaPeligros.AgregarTituloMapa")}
            <CalciteInput
              id="txtTituloMapa"
              value={t(txtTitulo)}
              onCalciteInputChange={(e) => setTxtTitulo(e.target.value)}
            ></CalciteInput>
          </CalciteLabel>
        </div>
        <div className="row pt-2">
          <CalciteLabel>
            {t("screens.widgets.MapaPeligros.NumeroMapa")}
            <CalciteInput
              id="txtNumeroMapa"
              value={txtNumeroMapa}
              onCalciteInputChange={(e) => setTxtNumeroMapa(e.target.value)}
            ></CalciteInput>
          </CalciteLabel>
        </div>
        <div className="row py-2">
          <div className="row pt-2">
            <CalciteLabel>
              {t("screens.widgets.MapaPeligros.ElementosExportar")}
              <CalciteLabel layout="inline">
                <CalciteCheckbox
                  id="chkExportarMapaPeligroGeologico"
                  scale="m"
                  checked
                  onCalciteCheckboxChange={(e) =>
                    fnChkExportarMapaPeligroGeologico(e.target.checked)
                  }
                ></CalciteCheckbox>
                {t("screens.widgets.MapaPeligros.ExportarMapa")}
              </CalciteLabel>
              <CalciteLabel layout="inline">
                <CalciteCheckbox
                  id="chkExportarDatosMapaPeligroGeologico"
                  scale="m"
                  onCalciteCheckboxChange={(e) =>
                    fnChkExportarDatosMapaPeligroGeologico(e.target.checked)
                  }
                ></CalciteCheckbox>
                {t("screens.widgets.MapaPeligros.ExportarDatos")}
              </CalciteLabel>
            </CalciteLabel>
          </div>
          <div className="row pt-2">
            <div className="col-12 col-md d-flex">
              <CalciteButton
                iconStart="open-book"
                id="btnVerManual"
                onClick={() => fnManualMapa()}
                label={t("screens.widgets.MapaPeligros.manual")}
              >
                {t("screens.widgets.MapaPeligros.manual")}
              </CalciteButton>
            </div>
            <div className="col-12 col-md d-flex">
              <CalciteButton
                iconStart="file-pdf"
                id="btnProcesarMapaPeligros"
                label={t("screens.widgets.MapaPeligros.Procesar")}
                // disabled={disableProcesarMapaPeligros}
              >
                {t("screens.widgets.MapaPeligros.Procesar")}
              </CalciteButton>
            </div>
          </div>
        </div>
      </div>

      <CustomPopupPDF
        title={t("screens.widgets.MapaPeligros.titulo")}
        url={contenidoMapaPeligrosHTML}
        id="mapaPeligrosHTML"
      ></CustomPopupPDF>

      <Toaster />
    </>
  );
}
